import { observer } from 'mobx-react-lite'
import React from 'react'
import { ModuleHeader } from '../../models/Module'
import { Link } from 'react-router-dom';
interface Props{
    headerItems: ModuleHeader;
}
const WidgetHeaderItems = ({headerItems}:Props) => {
  return (
    <div className='widget-extra-heading'>
        {headerItems.items.map((item) => {
            let stack = [];
            switch(item.type){
                case 1:
                   stack.push(<Link className='btn btn-small btn-default' key={item.action} to={item.action}>{item.title}</Link>);
                    break;
            }
            return stack;
        })}
    </div>
  )
}

export default observer(WidgetHeaderItems)