import { observer } from "mobx-react-lite";
import React from "react";
import { LinenStockReportEntity } from "../../../models/LinenStockReportSummary";
import LinenDetailRow from "./LinenDetailRow";

interface Props{
    qtyLaundryLabel: string;
    qtyHotelLabel: string;
    dataTable: LinenStockReportEntity[];
}
export default observer(function LinenDetailsReport({qtyLaundryLabel,qtyHotelLabel,dataTable}:Props){
    return(
        <div className='data-grid'>
            <div className='data-grid-row header-row'>
                <div className='data-grid-col-code'><span>Stock Code</span></div>
                <div className='data-grid-col-name'><span>Stock Name</span></div>
                <div className='data-grid-col-code'><span>Date</span></div>
                <div className='data-grid-col-qty'><span>{qtyLaundryLabel}</span></div>
                <div className='data-grid-col-qty'><span>{qtyHotelLabel}</span></div>
            </div>
            { dataTable && dataTable.map(row => (
                    <LinenDetailRow dataRow={row} />
                ))
            }
        </div>
    )
});