import React from 'react'
import { WearTearReplacedTableRow } from '../../../models/WearTearReplaced'
import { observer } from 'mobx-react-lite'
interface Props{
    rows: WearTearReplacedTableRow[]
}
const WearTearReplacedGrid = ({rows}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row item-header-with-2-col heading-row">
              <div>Item Name</div>
              <div>Stock Injected</div>
              <div>Items Replaced</div>
          </div>
          {rows.map((row) => (
            <div key={row.itemName} className='data-grid-two-col-row item-header-with-2-col'>
              <div>{row.itemName}</div>
              <div>{row.injectedStock}</div>
              <div>{row.replacedStock}</div>
            </div>
          ))}
    </div>
  )
}

export default observer(WearTearReplacedGrid)