import React, { ReactNode } from 'react'
interface Props{
    children: ReactNode,
    showDialog: boolean,
}
const Popup = ({children, showDialog}:Props) => {
  return (
    <div className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
        {children}
    </div>
  )
}

export default Popup