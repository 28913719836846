import React from 'react'
import { MdOutlineLiveHelp } from 'react-icons/md';
import { TooltipRefProps } from 'react-tooltip';
interface Props{
    toolTipId: string;
    toolTipRef: React.MutableRefObject<TooltipRefProps | undefined>
    helpText: string;
    showDialog: (show:boolean) => void;
}
const HelpTextIcon = ({toolTipId,toolTipRef, helpText,showDialog}:Props) => {
    const handleClick = (e:React.MouseEvent<SVGElement, MouseEvent>) => {
        toolTipRef.current?.close();
        showDialog(true);
    }
  return (
    <>
        <a 
           className='help-text-anchor'
           data-tooltip-id={`${toolTipId}`} 
           data-tooltip-html={helpText} 
           data-tooltip-place="right"
        >
            <MdOutlineLiveHelp size={22} className="help-text-icon" onClick={handleClick} />
        </a>
    </>
  )
}

export default HelpTextIcon