import React from 'react'
import Popup from './Popup'
import { useStore } from '../../stores/store'
import Widget from './Widget';
import { UserShop } from '../../models/User';
import { useHistory } from 'react-router-dom';

const ChangeAccountPopup = () => {
    const {userStore:{showChangeAccount,user,setCurrentShop,setShowChangeAccount},hotelPortalStore,reportsStore} = useStore();
    const history = useHistory()
    const handleChangeAccount = (shop:UserShop) => {
        setCurrentShop(shop);
        hotelPortalStore.resetAll();
        reportsStore.resetAll();
        setShowChangeAccount(false);
        if(history.location.pathname !== '/hotelportal')
            history.push('/hotelportal')
    }
    return (
        <Popup showDialog={showChangeAccount}>
            <Widget width={1} noBorder={true} heading={'Change Account'}>
            <ul className="list-box"> 
                {user?.locations.map( (loc) => (
                    <>
                        <li className='header' key={loc.location}>{loc.location}</li>
                        {loc.userShops.map((shop) => (
                            <li key={shop.shopNo} onClick={()=>handleChangeAccount(shop)}>{`${shop.shopName} (${shop.shopNo})`}</li>
                        ))}
                    </>
                ))}
            </ul>
        </Widget>
        </Popup>
  )
}

export default ChangeAccountPopup