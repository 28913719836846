import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { StockInjectEntity } from '../../../models/StockInjectionDto';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { TableWidgetProps } from '../../../models/WidgetProps';
import CollapsableContainer from '../../common/CollapsableContainer';
import { format } from 'date-fns';
import StockInjectGrid from './StockInjectGrid';
import { observer } from 'mobx-react-lite';

const StockInjectTable = ({title,collapsable,helpText}:TableWidgetProps) => {
  const {commonStore:{isDesktop,isMobile},hotelPortalStore:{sortStockInjectData,sortedStockInjectData,loadingStockInjectData}} = useStore();
    const [evenStock, setEvenStock] = useState<StockInjectEntity[]>([]);
    const [oddStock, setOddStock]  = useState<StockInjectEntity[]>([]);
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();

    useEffect(() => {
        if(sortedStockInjectData){
            setEvenStock([]);
            setOddStock([])
            if(sortedStockInjectData.length >= 2){
                let total = (sortedStockInjectData.length / 2);
                setEvenStock([...sortedStockInjectData.slice(0,total)])
                setOddStock([...sortedStockInjectData.slice(total)])
            } else {
                setEvenStock([...sortedStockInjectData])
                setOddStock([])
            }
            setExportProps({
              data: sortedStockInjectData,
              headers: [
                {label: 'Date', key: 'stockDate'},
                {label: 'Item Name', key: 'itemName'},
                {label: 'Injected', key: 'injectedQty'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[sortedStockInjectData])
  return (
      <>
    {!loadingStockInjectData && sortedStockInjectData &&  evenStock && oddStock && (
        <CollapsableContainer  
            title={title} 
            collapsable={collapsable} 
            helpText={helpText}
            extraHeading={
                <>
                    <label>Order By</label>
                    <select className='custom-dropdown' onChange={(e)=>sortStockInjectData(parseInt(e.target.value) === 0)}>
                        <option value={0}>Date</option>
                        <option value={1}>Item Name</option>
                    </select>
                </>
            }
            showExport={true} 
            exportOptions={exportProps}>
        {sortedStockInjectData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <StockInjectGrid stock={evenStock} />
                <StockInjectGrid stock={oddStock} />
            </div>
        )}
        {sortedStockInjectData && isMobile && (
            <StockInjectGrid stock={sortedStockInjectData} />
        )}        
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(StockInjectTable);