import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../stores/store';
interface IProps{
  filename: string;
}
const LaundryManualViewer : React.FC<IProps> = ({filename}) => {
  const {commonStore:{currentLaundry}} = useStore();
  return (
    <div className='pdf-viewer'>
      <embed
        src={`${currentLaundry?.appDocUrl}/Files/${filename}`}
        // src={`/docs/${filename}`}
        type="application/pdf"
        width="100%"
        height="100%"
      />    
    </div>
  )
}

export default observer(LaundryManualViewer);