import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { Item } from "../../models/Module";
import { useStore } from "../../stores/store";
import Widget from "../common/Widget";
interface Props{
    title: string;
    menuItems: Item[];
    width: number;
}
export default observer(function ReportWidget({title,menuItems,width}:Props){
    const {commonStore:{userSubModules}} = useStore()

    if(userSubModules.length > 0 && menuItems.length === 0)
        return <></>

    if(userSubModules.length === 0 || menuItems.length === 0)
        return <h2>Loading...</h2>
    return(
        <Widget width={width} noBorder={false} heading={title}>
            <ul className='list-box'> 
                {menuItems.map((m) => (<li key={m.action}><Link to={m.action}>{m.name}</Link></li>))}
            </ul>
        </Widget>
    );
    })