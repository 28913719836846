import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Colors } from '../../../constants/Colors';
import {observer} from 'mobx-react-lite';
import { Bar } from 'react-chartjs-2';
import { barOptions } from '../../../constants/ChartConstants';

const SoiledLinenCageComparisionChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{soiledLinenCageComparisionData,loadingSoiledLinenCageComparisionData}} = useStore();
    const [sumBarData, setSumBarData] = useState<ChartData<"bar">>();
    const [barData, setBarData] = useState<ChartData<"bar">>();

    useEffect(()=>{
        if(soiledLinenCageComparisionData){
            setSumBarData({
                labels: ['Soiled Linen Cage'],
                datasets: [
                    {
                        barThickness: 45, categoryPercentage: 0.5,
                        label: soiledLinenCageComparisionData.summary.labels[0],
                        data: [soiledLinenCageComparisionData.summary.data[0]],
                        backgroundColor: `${Colors.PALE_GREEN}`,
                    },
                    {
                        barThickness: 45, categoryPercentage: 0.5,
                        label: soiledLinenCageComparisionData.summary.labels[1],
                        data: [soiledLinenCageComparisionData.summary.data[1]],
                        backgroundColor: `${Colors.PALE_BLUE}`,
                    }
                ]
            })
            setBarData({
                labels: soiledLinenCageComparisionData.details.labels,
                datasets:[
                    {
                        barThickness: 25,categoryPercentage: 0.5,
                        label: 'Scanned @Hotel',
                        data: soiledLinenCageComparisionData.details.scannedAtHotel,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                        barThickness: 25,categoryPercentage: 0.5,
                        label: 'Scanned @Laundry',
                        data: soiledLinenCageComparisionData.details.scannedAtLaundry,
                        backgroundColor: `${Colors.PALE_BLUE}`
                    },
                ]

            })
        }
    },[soiledLinenCageComparisionData])

  return (
    <>
            {!loadingSoiledLinenCageComparisionData && !!soiledLinenCageComparisionData 
                && (soiledLinenCageComparisionData.details.scannedAtHotel.length > 0 || soiledLinenCageComparisionData.details.scannedAtLaundry.length > 0) && !!barData && !!sumBarData && title.length > 0 && (
                <h2 className='collapsable-container-title'>{title}</h2>)}
            {!loadingSoiledLinenCageComparisionData && !!soiledLinenCageComparisionData 
                && (soiledLinenCageComparisionData.details.scannedAtHotel.length > 0 || soiledLinenCageComparisionData.details.scannedAtLaundry.length > 0) && !!barData && sumBarData && (
              <div className='graph-container'>
                        <div className="width-75">
                            <Bar options={barOptions} data={barData!} />
                        </div>
                        <div className="width-25 ml-1">
                            <Bar options={{...barOptions,aspectRatio: 1}} data={sumBarData!} />
                        </div>
              </div>
            ) 
            }
    </>
  )
}

export default observer(SoiledLinenCageComparisionChart)