import React, { useEffect } from 'react'
import { WidgetProps } from '../../../models/WidgetProps'
import Widget from '../../common/Widget';
import { useStore } from '../../../stores/store';
import LoadingComponent from '../../common/LoadingComponent';
import StockInjectChart from './StockInjectChart';
import StockInjectTable from './StockInjectTable';
import { observer } from 'mobx-react-lite';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';

const StockInjectionWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{stockInjectData,loadStockInjectData,loadingStockInjectData},userStore:{currentShop}} = useStore();
    useEffect(()=>{
        if(currentShop)
        if(!stockInjectData)
            loadStockInjectData();
    },[stockInjectData,loadStockInjectData,currentShop])
  return (
    <Widget 
        width={width}
        noBorder={false}
        heading={heading}
        helpText={helpText}
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
    >
        {loadingStockInjectData && <LoadingComponent message='Loading stock... please wait!' />}
        {menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'stockinject_chrt':
                        stack.push(<StockInjectChart key={item.action} title={item.name} />)
                        break;
                    case 'stockinject_tab':
                        stack.push(<StockInjectTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />)
                        break;
                }
                return stack;
            })}
    </Widget>
  )
}

export default observer(StockInjectionWidget);