import React, { forwardRef, MutableRefObject } from 'react'
import { DeliveryOrder } from '../../models/DeliveryOrder'
import { useStore } from '../../stores/store'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

interface Props{
    dateString: string,
    data: DeliveryOrder[]
}
const RejectStockPrintableView = forwardRef<HTMLDivElement,Props>(({data, dateString},printRef) => {
    const {userStore:{currentShop}} = useStore()
  return (
            <div style={{fontSize:'12px', width: '90%'}} ref={printRef}>
                <div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <h1 style={{fontSize: '14px', fontWeight: 'bold', marginBottom: '10px'}}>Reject Stock Report</h1>
                    </div>
                    <div style={{display: 'flex', alignItems:'center', justifyContent:'space-between', marginBottom: '10px'}}>
                        <div>
                            <strong>Shop No:</strong>{` ${currentShop?.shopName} (${currentShop?.shopNo})`}
                        </div>
                        <div>
                            <strong>Date:</strong>{` ${dateString}`}
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontWeight: 'bold', borderBottom: '1px solid black', marginBottom: '10px'}}>
                        <div>Item Name</div>
                        <div>Quantity Rejected</div>
                    </div>
                    {data.map(reject =>(
                        <div key={reject.code} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div>{reject.name}</div>
                            <div>{reject.quantity}</div>
                        </div>
                    ))}
                </div>
            </div>
  )
})

export default observer(RejectStockPrintableView)