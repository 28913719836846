import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {Colors} from '../../../constants/Colors';
import { barOptions } from '../../../constants/ChartConstants';

const StockSoilChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{stockSoiledGraphData, loadingStockSoiledGraphData}} = useStore();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(stockSoiledGraphData)
        {
            setBarData({
                labels: stockSoiledGraphData.labels,
                datasets:[
                    {
                        label: 'soiled linen in',
                        data: stockSoiledGraphData.data,
                        backgroundColor: `${Colors.PALE_BLUE}`
                    },
                ]

            })
        }
    },[stockSoiledGraphData])
  return (
        <>
            {!loadingStockSoiledGraphData && !!stockSoiledGraphData && stockSoiledGraphData.data.length > 0 && !!barData && title.length > 0 && (
                <h2 className='collapsable-container-title'>{title}</h2>)}        
            {!loadingStockSoiledGraphData && !!stockSoiledGraphData && stockSoiledGraphData.data.length > 0 && !!barData && (
                    <Bar options={barOptions} data={barData!} />)}
        </>
  )
}

export default observer(StockSoilChart);