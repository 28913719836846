import React from "react";
import { useHistory } from "react-router-dom";
interface Props{
    message: string;
    showClose: boolean;
}
export default function DisabledOverlay({message,showClose}: Props){
    const history = useHistory();
    return (
        <div className='info-box-wrapper'>
        <div className='info-box-error'>
            <div className='info-box-message small-text'>{message}</div>
            {showClose && <button type='button' className='btn btn-default' onClick={() => history.push("/hotelportal")}>CLOSE</button>}
        </div>
        </div>
    )
}