import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores/store';
import MisusedStockRow from './MisusedStockRow';

const MisusedStockReport = () => {
 const {hotelPortalStore: { misusedStockReport}} = useStore();
    return(
        <div className='data-grid'>
            <div className='data-grid-row header-row'>
                <div className='data-grid-col-sm'>&nbsp;Stock Code</div>
                <div className='data-grid-col-lg'>Stock Name</div>
                <div className='data-grid-col-sm'>Item Abused</div>
                <div className='data-grid-col-md'>Type</div>
                <div className='data-grid-col-sm'>&nbsp;</div>
            </div>
            {
                misusedStockReport && misusedStockReport.length > 0 ? misusedStockReport.map((s,i) => (
                    <MisusedStockRow key={i} row={s} />
                )) : (<div className="data-grid-row data-grid-no-records">No Record(s)</div>)
            }
        </div>
    )
};

export default observer(MisusedStockReport);