import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import LoadingComponent from "../components/common/LoadingComponent";
import ResetPasswordBox from "../components/common/ResetPasswordBox";
import { ResetToken } from "../models/User";
import { useStore } from "../stores/store";
import { decryptMessage } from "../utils/axcessCipher";
import {expandResetToken} from "../utils/utils";

export default observer(function ResetPasswordPage({location}:RouteComponentProps){
    const { commonStore:{laundryUID, checkLaundryUID, invalidLaundryCode, setInvalidLaundryCode, loadLaundry, currentLaundry}} = useStore()
    const [loading, setLoading] = useState(true);
    const [resetKey, setResetKey] = useState("");
    useEffect(()=>{
        var searchParams = new URLSearchParams(location.search);
        if(searchParams.has("key")){
            const resetKey = decodeURIComponent(searchParams.get("key") || "").replace(' ','+');
            setResetKey(resetKey);
            if(resetKey.length > 0){
                var raw_token = decryptMessage(resetKey);
                const token: ResetToken = expandResetToken(raw_token);
                if(token && token.laundryUID){
                    if(laundryUID){
                        if(laundryUID !== token.laundryUID){
                            checkLaundryUID(token.laundryUID);
                        } else {
                            if(!currentLaundry)
                                loadLaundry();
                            else
                                setInvalidLaundryCode();
                        }
                    } else {
                        checkLaundryUID(token.laundryUID);
                    }
                }
            } else {
                setInvalidLaundryCode();
            }
        }
            
    },[location.search])
    
    useEffect(()=>{
        if(currentLaundry){
            setLoading(false);
            let colourScheme = JSON.parse(currentLaundry.colorScheme);
                Object.keys(colourScheme).forEach((key) =>{
                if (key.endsWith('image'))
                document.documentElement.style.setProperty(`--${key}`, `url(${colourScheme[key]})`)  
                else
                document.documentElement.style.setProperty(`--${key}`, colourScheme[key])  
        }
        );
        }
    },[currentLaundry])
 
    if(loading)
        return <LoadingComponent message='Loading... please wait!' />
    if(invalidLaundryCode)
        return <Redirect to="/" />;
    return (
        <ResetPasswordBox resetKey={resetKey} />
  );
});