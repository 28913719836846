import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import LoadingComponent from "../components/common/LoadingComponent";
import CustomerStockReport from "../components/CustomerStockReport/CustomerStockReport";
import CustomerStockReportFilterbox from "../components/CustomerStockReport/CustomerStockReportFilterbox";

import { useStore } from "../stores/store";

export default observer(function CustomerStockReportPage(){
    const {commonStore,hotelPortalStore: { loadCustomerStockSummary, loadingReport}} = useStore();
    const handleClick = (id: number, reconciled: boolean) => {
        loadCustomerStockSummary(id,reconciled)
    }

    useEffect(() => {
        commonStore.setPreviousRoute(window.location.pathname);
        loadCustomerStockSummary(0,false);
    }, [])
    return (
        <>
            <h2 className='page-header'>Customer Stock Status</h2>
                <CustomerStockReportFilterbox captions={['ALL','Not seen for more than 1 month','Not seen for more than 2 month','Not seen for more than 3 month']} onClick={handleClick} />
                {loadingReport ? (<LoadingComponent message='Loading report... please wait!' />) : (<CustomerStockReport />)}
        </>
    )
})