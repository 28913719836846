export interface ApiResponse{
    status: ApiResponseStatus;
    error: ApiResponseError;
};

export interface ApiResponseT<T> extends ApiResponse{
    result: T;
}

export interface ApiResponseError{
    code: ApiResponseErrorCode;
    description: string;
}

export enum ApiResponseStatus{
    responseError,
    responseOK
}

export enum ApiResponseErrorCode
{
    errorNone = 100,
    errorNotFound = 101,
    errorFailed = 102,
    errorBadRequest = 103,
    errorInvalidKey = 201,
    errorExpiredKey = 202,
    errorAuthenticationFailed = 300,
    errorUnAuthorized = 301,
    errorServerError = 500
}