import React, { useState } from 'react'
import { StockCheckMaster } from '../../../models/StockCheckDto'
import { observer } from 'mobx-react-lite';
import StockCheckGrid from './StockCheckGrid';
import { format } from 'date-fns';
import StockCheckNotSeenTable from './StockCheckNotSeenTable';
interface Props{
    detail: StockCheckMaster;
}
const StockCheckMasterGridRow = ({detail}:Props) => {
    const [showDetails,setShowDetails] = useState(false);
    const [showNotSeen, setShowNotSeen] = useState(false);
    const handleShowDetails = () => {
        if(!showDetails)
            setShowNotSeen(false);
        setShowDetails(prev => !prev);
    }
    const handleShowNotSeen = () => {
        if(!showNotSeen)
            setShowDetails(false);
        setShowNotSeen(prev => !prev);
    }
  return (
    <>
        <div key={detail.uniqueRecId} className={`data-grid-two-col-row stock-check-master-grid-row ${showDetails || showNotSeen ? 'data-grid-row-selected' : ''}`}>
            <div>{detail.dateStarted ? format(new Date(detail.dateStarted),"dd-MM-yyyy HH:mm:ss") : 'No Stock Check Done'}</div>
            <div>{detail.dateFinished ? format(new Date(detail.dateFinished),"dd-MM-yyyy HH:mm:ss") : ''}</div>
            <div>{detail.storageLocation}</div>
            <div>{detail.user}</div>
            {detail.details.length > 0 ? (
                <div><span className={`data-toggle ${showDetails ? 'data-toggle-selected' : ''}`} onClick={handleShowDetails}>{`${!showDetails ? 'Show' : 'Hide'} Details`}</span></div>
            ) : (
                <div><span></span>N/A</div>
            )}
            {detail.notSeenDetails.length > 0 ? (
                <div><span className={`data-toggle ${showNotSeen ? 'data-toggle-selected' : ''}`} onClick={handleShowNotSeen}>{`${!showNotSeen ? 'Show' : 'Hide'} Missing Stock`}</span></div> 
            ) : (
                <div><span></span>N/A</div>
            )}
        </div>
        {showDetails && (
            <div className="data-grid-row data-sub-grid">
                {detail.details.length > 0 ? (
                    <StockCheckGrid details={detail.details} lastSeenHeading='On the Shelf for(days)' />
                ) : (
                    <div className="data-grid-row data-grid-no-records">No Records</div>
                )}
            </div>
        )}
        {showNotSeen && (
            <div className='data-grid-row data-sub-grid'>
                {detail.notSeenDetails.length > 0 ? (
                    <StockCheckNotSeenTable details={detail.notSeenDetails} />
                ) : (
                    <div className="data-grid-row data-grid-no-records">No Records</div>
                )}
            </div>
        )}
    </>
  )
}

export default observer(StockCheckMasterGridRow)