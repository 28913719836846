import React from 'react'
import { StockCirculationNotSeenTableItem } from '../../../models/StockCirculationData'
import { observer } from 'mobx-react-lite';
interface Props{
    stock: StockCirculationNotSeenTableItem[];
}
const StockCirculationNotSeenGrid = ({stock}:Props) => {
  return (
   <div className="data-grid-two-col">
        <div className="data-grid-two-col-row stock-circulation-notseen-grid-row heading-row">
            <div className='grid-span-r2'>Name</div>
            <div className='grid-span-c10'>Last Seen (days)</div>
            <div className='text-right pr-25'>91 - 120</div>
            <div className='text-right pr-25'>121 - 150</div>
            <div className='text-right pr-25'>151 - 180</div>
            <div className='text-right pr-25'>181 - 210</div>
            <div className='text-right pr-25'>211 - 240</div>
            <div className='text-right pr-25'>241 - 270</div>
            <div className='text-right pr-25'>271 - 300</div>
            <div className='text-right pr-25'>301 - 330</div>
            <div className='text-right pr-25'>331 - 360</div>
            <div className='text-right pr-25'>&gt;360</div>
        </div>
        {stock.map(data =>(
            <div key={data.itemName} className="data-grid-two-col-row stock-circulation-notseen-grid-row">
                <div>{data.itemName}</div>
                <div className='text-right pr-25'>{data.b91N120}</div>
                <div className='text-right pr-25'>{data.b121N150}</div>
                <div className='text-right pr-25'>{data.b151N180}</div>
                <div className='text-right pr-25'>{data.b181N210}</div>
                <div className='text-right pr-25'>{data.b211N240}</div>
                <div className='text-right pr-25'>{data.b241N270}</div>
                <div className='text-right pr-25'>{data.b271N300}</div>
                <div className='text-right pr-25'>{data.b301N330}</div>
                <div className='text-right pr-25'>{data.b331N360}</div>
                <div className='text-right pr-25'>{data.g360}</div>
            </div>
        ))}
    </div>
  )
}

export default observer(StockCirculationNotSeenGrid)