import React from 'react'
import { StockCheckDetail } from '../../../models/StockCheckDto'
import { observer } from 'mobx-react-lite';
interface IProps{
    details: StockCheckDetail[];
    lastSeenHeading: string;
}
const StockCheckGrid = ({details,lastSeenHeading}:IProps) => {
    return (
        <div className="data-grid">
        <div className="data-grid-two-col-row stock-check-grid-row heading-row">
            <div className='grid-span-r2'>Name</div>
            <div className='grid-span-r2 justify-right'>Scanned</div>
            <div className='grid-span-c4'>{lastSeenHeading}</div>
            <div className='text-right pr-25'>&lt;30</div>
            <div className='text-right pr-25'>30 to 60</div>
            <div className='text-right pr-25'>60 to 90</div>
            <div className='text-right pr-25'>&gt;90</div>
        </div>
        {details.map(data =>(
            <div key={data.itemName} className="data-grid-two-col-row stock-check-grid-row">
                <div>{data.itemName}</div>
                <div className='text-right pr-25'>{data.scanned}</div>
                <div className='bg-pale-green text-right pr-25'>{data.lessThan30}</div>
                <div className='bg-pale-amber text-right pr-25'>{data.between30and60}</div>
                <div className='bg-pale-orange text-right pr-25'>{data.between60and90}</div>
                <div className='bg-pale-red text-right pr-25'>{data.greaterThan90}</div>
            </div>
        ))}
    </div>
    )
}

export default observer(StockCheckGrid)