import axios, { AxiosResponse } from "axios";
import { ApiResponse, ApiResponseT } from "../models/ApiResponse";
import { CageHamperRequest } from "../models/CageHamperRequest";
import { DeliveryOrder, DeliveryStats } from "../models/DeliveryOrder";
import { EmailMessage } from "../models/EmailMessage";
import { Laundry } from "../models/Laundry";
import { LinenStockReportSummary } from "../models/LinenStockReportSummary";
import { MisusedStockAnalysisReport, MisusedStockReportSummary } from "../models/MisusedStockReportSummary";
import { MenuModule } from "../models/Module";
import { StockEntity, StockReportSummary, StockSoiledGraphData } from "../models/StockReportSummary";
import { StockRequest } from "../models/StockRequest";
import { StockSummary } from "../models/StockStatus";
import { TechSupportMessage } from "../models/techSupportMessage";
import { UserCredentials, User, ForgotPasswordValues, ResetPasswordValues, LoginActivityDto } from "../models/User";
import { store } from "../stores/store";
import { Manual } from "../models/Manual";
import { ContactDetail } from "../models/ContactDetails";
import { VisitSchedule } from "../models/VisitSchedule";
import { ErrorLogDto } from "../models/ErrorLogDto";
import { ItemLaundryLocationDto, ItemLocation } from "../models/ItemLocation";
import { StockCirculationData, StockCirculationNotSeenDto } from "../models/StockCirculationData";
import { MisusedStockDto } from "../models/MisusedStock";
import { RejectStockAnalysisData } from "../models/RejectStockAnalysisData";
import { StockCheckDto, StockCheckTrendsDto } from "../models/StockCheckDto";
import { MisusedStockTypeAnalysisDto } from "../models/MisusedStockTypeAnalysis";
import { WearTearReplacedDto } from "../models/WearTearReplaced";
import { StockToReplaceDto } from "../models/StockToReplaceDto";
import { StockInjectionDto } from "../models/StockInjectionDto";
import { StockCheckFrequencyDto } from "../models/StockCheckFrequencyDto";
import { StockReconcilationDto } from "../models/StockReconcilation";
import { CleanLinenCageComparisionDto } from "../models/CleanLinenCageComparisionDto";
import { LinenCageComparisionDto } from "../models/LinenCageComparisionDto";

const shopApi = axios.create({baseURL: process.env.REACT_APP_SHOP_URL});
const appApi = axios.create({baseURL: process.env.REACT_APP_APP_URL});
const logApi = axios.create({baseURL: process.env.REACT_APP_LOG_URL});

const responseBody = <T> (response:AxiosResponse<T>) => response.data;

const setShopApi = (url: string) => {
   // shopApi.defaults.baseURL = url;
}

const shopRequest = {
    get: <T> (url: string) => shopApi.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => shopApi.post<T>(url,body).then(responseBody),
    put: <T>(url: string, body: {}) => shopApi.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => shopApi.delete<T>(url).then(responseBody)
};

const appRequest = {
    get: <T> (url: string) => appApi.get<T>(url).then(responseBody)
};

const logRequest = {
    post: <T>(url: string, body: {}) => logApi.post<T>(url,body).then(responseBody),
};

shopApi.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if(token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

const AppWS = {
    getLaundry: (id: string) => appRequest.get<ApiResponseT<Laundry>>(`/laundry/1.0.0/00000000-0000-0000-0000-000000000000/${id}`),
    checkLaundryUID: (id: string) => appRequest.get<ApiResponseT<boolean>>(`/laundry/check/1.0.0/00000000-0000-0000-0000-000000000000/${id}`)
};

const ErrorLogWS = {
    addLog: (log: ErrorLogDto) => logRequest.post<ApiResponseT<boolean>>('/errorlog/new',log),
};

const Account = {
    authenticate: (login: UserCredentials) => shopRequest.post<ApiResponseT<User>>('/account/login', login),
    logout: () => shopRequest.post<ApiResponse>('/account/logoff',{}),
    getmodules: () => shopRequest.get<ApiResponseT<MenuModule[]>>('/account/modules'),
    currentUser: () => shopRequest.get<ApiResponseT<User>>('/account/user/current'),
    forgotPassword: (forgot: ForgotPasswordValues) => shopRequest.post<ApiResponseT<boolean>>('/account/password/forgot', forgot),
    resetPassword: (reset: ResetPasswordValues) => shopRequest.post<ApiResponseT<boolean>>('/account/password/reset', reset),
    changePassword: (password: ResetPasswordValues) => shopRequest.post<ApiResponseT<boolean>>('/account/password/change',password),
    technicalSupport: (message: TechSupportMessage) => shopRequest.post<ApiResponseT<boolean>>(`/account/support/technical`,message)
};

const HotelPortal = {
    lastDelivery: (shopNo: string) => shopRequest.get<ApiResponseT<DeliveryStats>>(`/hotelportal/delivery/last/${shopNo}`),
    nextDelivery: (shopNo: string) => shopRequest.get<ApiResponseT<DeliveryStats>>(`/hotelportal/delivery/next/${shopNo}`),
    deliverySchedule: (accountCode: string) => shopRequest.get<ApiResponseT<VisitSchedule[]>>(`/hotelportal/delivery/schedule/${accountCode}`),
    requestStockList: (shopNo: string) => shopRequest.get<ApiResponseT<DeliveryOrder[]>>(`/hotelportal/stock/request/${shopNo}`),
    requestStock: (stock: StockRequest) => shopRequest.post<ApiResponseT<boolean>>(`/hotelportal/stock/request`,stock),
    rejectStockList: (shopNo: string) => shopRequest.get<ApiResponseT<DeliveryOrder[]>>(`/hotelportal/stock/reject/${shopNo}`),
    rejectStock: (stock: StockRequest) => shopRequest.post<ApiResponseT<boolean>>(`/hotelportal/stock/reject`,stock),
    rejectStockReport: (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<DeliveryOrder[]>>(`/hotelportal/stock/reject/report/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
    stocksInCirculation: (shopNo: string) => shopRequest.get<ApiResponseT<StockEntity[]>>(`hotelportal/stock/circulation/${shopNo}`),
    customerStockSummary: (shopNo: string, filter: number, reconciled: boolean) => shopRequest.get<ApiResponseT<StockSummary[]>>(`/hotelportal/customer/${shopNo}?filter=${filter}&showReconciled=${reconciled}`),
    laundryStockSummary: (shopNo: string) => shopRequest.get<ApiResponseT<StockSummary[]>>(`/hotelportal/laundry/${shopNo}`),
    misusedStockSummary: (shopNo: string, filter: number) => shopRequest.get<ApiResponseT<MisusedStockReportSummary[]>>(`/hotelportal/stock/misused/${shopNo}?filter=${filter}`),
    misusedStockAnalysis: (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<MisusedStockAnalysisReport>>(`/hotelportal/stock/misused/analysis/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
    misusedStockTypeAnalysis: (shopNo: string, category: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<MisusedStockTypeAnalysisDto>>(`/hotelportal/stock/misused/type/${shopNo}/${category}?fromDate=${fromDate}&toDate=${toDate}`),
    misusedStockData: (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<MisusedStockDto>>(`/hotelportal/stock/misused/data/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
    misusedTrends: (shopNo: string, misuseType: number, analysisBy: number, fromDate:string, toDate:string) => shopRequest.get<ApiResponseT<StockCheckTrendsDto>>(`/hotelportal/stock/misused/trends/${shopNo}/${misuseType}/${analysisBy}?fromDate=${fromDate}&toDate=${toDate}`), 
    sendMessage: (message: EmailMessage) => shopRequest.post<ApiResponseT<boolean>>(`/hotelportal/message/send`, message),
    sendCHRequest: (cageHamper: CageHamperRequest) => shopRequest.post<ApiResponseT<boolean>>(`/hotelportal/request/cagehampers`, cageHamper),
    getStockLocation: (shopNo: string) => shopRequest.get<ApiResponseT<ItemLocation>>(`hotelportal/stock/location/${shopNo}`),
    getStockLaundryLocation: (shopNo: string) => shopRequest.get<ApiResponseT<ItemLaundryLocationDto>>(`hotelportal/stock/laundry/location/${shopNo}`),
    stocksCirculationData: (shopNo: string) => shopRequest.get<ApiResponseT<StockCirculationData>>(`hotelportal/stock/circulation/data/${shopNo}`),
    stocksCirculationNotSeenData: (shopNo: string) => shopRequest.get<ApiResponseT<StockCirculationNotSeenDto>>(`hotelportal/stock/circulation/notseen/${shopNo}`),
    stockSoiledGraphData: (shopNo: string, forDate: string) => shopRequest.get<ApiResponseT<StockSoiledGraphData>>(`/hotelportal/stock/soiled/${shopNo}?ForDate=${forDate}`),
    rejectstockGraphData: (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<RejectStockAnalysisData>>(`/hotelportal/stock/reject/data/${shopNo}?FromDate=${fromDate}&ToDate=${toDate}`),
    stockCheckDetails: (shopNo: string, startDate: string, endDate: string) => shopRequest.get<ApiResponseT<StockCheckDto>>(`/hotelportal/stock/check/${shopNo}?startDate=${startDate}&endDate=${endDate}`),
    stockCheckFrequency: (shopNo: string, startDate: string, endDate: string) => shopRequest.get<ApiResponseT<StockCheckFrequencyDto>>(`/hotelportal/stock/check/freq/${shopNo}?startDate=${startDate}&endDate=${endDate}`),
    stockCheckTrendsGraph: (shopNo: string, notSeen: number, startDate: string, endDate: string) => shopRequest.get<ApiResponseT<StockCheckTrendsDto>>(`/hotelportal/stock/check/trends/${shopNo}/${notSeen}?startDate=${startDate}&endDate=${endDate}`),
    wearTearReplaced: (shopNo: string) => shopRequest.get<ApiResponseT<WearTearReplacedDto>>(`/hotelportal/stock/wear-tear/${shopNo}`),
    stockToReplace: (shopNo: string, includeNotSeen: boolean) => shopRequest.get<ApiResponseT<StockToReplaceDto>>(`/hotelportal/stock/to-replace/${shopNo}/${includeNotSeen}`),
    stockReplaced: (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<StockToReplaceDto>>(`/hotelportal/stock/replaced/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
    stockInjection: (shopNo: string) => shopRequest.get<ApiResponseT<StockInjectionDto>>(`/hotelportal/stock/inject/${shopNo}`),
    cleanLinenComparision: (shopNo: string, forDate: string) => shopRequest.get<ApiResponseT<StockReconcilationDto>>(`/hotelportal/stock/clean/compare/${shopNo}?forDate=${forDate}`),
    soiledLinenComparision: (shopNo: string, forDate: string) => shopRequest.get<ApiResponseT<StockReconcilationDto>>(`/hotelportal/stock/soil/compare/${shopNo}?forDate=${forDate}`),
    cleanLinenCageComparision: (shopNo: string, fromDate: string) => shopRequest.get<ApiResponseT<CleanLinenCageComparisionDto>>(`/hotelportal/cage/clean/compare/${shopNo}?fromDate=${fromDate}`),
    soiledLinenCageComparision: (shopNo: string, fromDate: string) => shopRequest.get<ApiResponseT<LinenCageComparisionDto>>(`/hotelportal/cage/soil/compare/${shopNo}?fromDate=${fromDate}`),
};

const Reports = {
    stockTake: (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<StockReportSummary[]>>(`/reports/stock/take/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
    linenIn:  (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<LinenStockReportSummary[]>>(`/reports/linen/in/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
    linenOut:  (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<LinenStockReportSummary[]>>(`/reports/linen/out/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
    stockPassCustomer: (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<StockReportSummary[]>>(`/reports/stock/pass/customer/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
    stockPassStaff: (shopNo: string, fromDate: string, toDate: string) => shopRequest.get<ApiResponseT<StockReportSummary[]>>(`/reports/stock/pass/staff/${shopNo}?fromDate=${fromDate}&toDate=${toDate}`),
};

const Documents = {
    getManuals: () => shopRequest.get<ApiResponseT<Manual[]>>('/documents/manuals')
};

const Contacts = {
    getContacts: () => shopRequest.get<ApiResponseT<ContactDetail[]>>('/contacts/details')
};

const ActivityLogs = {
    addLoginLog: (activity: LoginActivityDto) => shopRequest.post<ApiResponse>('/activitylog/login',activity),
    getRequestCount: (locationUID: string, reqType: number) => shopRequest.get<ApiResponseT<number>>(`/activitylog/request/count?locationUID=${locationUID}&requestType=${reqType}`),
    updateRequestCount: (locationUID: string, reqType: number) => shopRequest.post<ApiResponseT<number>>('/activitylog/request/count/update',{ 'LocationUID': locationUID, 'RequestType': reqType}),
}

const agent = {
    setShopApi,
    AppWS,
    ErrorLogWS,
    Account,
    HotelPortal,
    Reports,
    Documents,
    Contacts,
    ActivityLogs
};

export default agent;