import React from 'react'
import { StockInjectEntity } from '../../../models/StockInjectionDto';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
interface Props{
    stock: StockInjectEntity[];
}
const StockInjectGrid = ({stock}:Props) => {
 return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row stock-inject-grid-row heading-row">
            <div>Date</div>
            <div>Name</div>
            <div className='text-right pr-25'>Injected</div>
        </div>
        {stock.map(data =>(
            <div key={`${data.stockDate}_${data.itemName}`} className="data-grid-two-col-row stock-inject-grid-row">
                <div>{format(new Date(data.stockDate),'dd/MM/yyyy')}</div>
                <div>{data.itemName}</div>
                <div className='text-right pr-25'>{data.injectedQty}</div>
            </div>
        ))}
    </div>)
}

export default observer(StockInjectGrid);