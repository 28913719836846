import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../stores/store';
import Navbar from './Navbar';
import ChangeAccountPopup from '../common/ChangeAccountPopup';
export default observer(function Header(){
    const {commonStore:{currentLaundry},userStore:{showChangeAccount}} = useStore();
    return(
        <header>
            <div className="navbar">
        <div className="navbar-brand">
            <img className="navbar-logo" src={`${currentLaundry?.appDocUrl}/Logo/${currentLaundry?.logo}`} alt={currentLaundry?.laundryName} />
            {/* <img className="navbar-logo" src={logo} alt={currentLaundry?.laundryName} /> */}
            <h1 className="logo-title">{currentLaundry?.showLaundryName &&  (currentLaundry?.laundryName || 'My Laundry')}</h1>
        </div>
        <Navbar />
        </div>
        {showChangeAccount && <ChangeAccountPopup />}
        </header>
    );
})