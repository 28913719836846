import CryptoJS from "crypto-js";

export const decryptMessage = (message: string) => {
    var iv = CryptoJS.enc.Hex.parse('e84ad660c4721ae0e84ad660c4721ae0');
    var password = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHER_PASS!);
    var salt = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CIPHER_SALT!);
    var key128Bits1000Iterations = CryptoJS.PBKDF2(password.toString(CryptoJS.enc.Utf8), salt, { keySize: 128 / 32, iterations: 1000 });
    // @ts-ignore
    var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(message)});
    var decrypted = CryptoJS.AES.decrypt(cipherParams, key128Bits1000Iterations, { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });
    return decrypted.toString(CryptoJS.enc.Utf8);
}