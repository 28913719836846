import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Colors } from '../../../constants/Colors';
import { Bar } from 'react-chartjs-2';
import { barOptions } from '../../../constants/ChartConstants';
import { observer } from 'mobx-react-lite';

const StockInjectChart = ({title}:{title:string}) => {
  const {hotelPortalStore:{stockInjectData,loadingStockInjectData}} = useStore();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(stockInjectData){
            setBarData({
                labels: stockInjectData.chartData.labels,
                datasets:[
                    {
                        label: 'stock injected',
                        data: stockInjectData.chartData.data,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                ]

            })
        }
    },[stockInjectData])
  return (
       <>
            {!loadingStockInjectData && !!stockInjectData && !!barData && title.length > 0 && (
                <h2 className='collapsable-container-title'>{title}</h2>)}
            {!loadingStockInjectData && !!stockInjectData && !!barData && (
                <Bar options={barOptions} data={barData!} />)}
        </>
    
  )
}

export default observer(StockInjectChart)