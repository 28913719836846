import React from 'react'
import { StockNotSeenDetail } from '../../../models/StockCheckDto'
interface Props{
    details: StockNotSeenDetail[];
}
const StockCheckNotSeenGrid = ({details}:Props) => {
  return (
     <div className="data-grid">
        <div className="data-grid-two-col-row item-header-with-1-col heading-row">
            <div>Item Name</div>
            <div>Quantity</div>
        </div>
        {details.map(data =>(
            <div key={data.stockName} className="data-grid-two-col-row item-header-with-1-col">
                <div>{data.stockName}</div>
                <div>{data.stockQty}</div>
            </div>
        ))}
    </div>
  )
}

export default StockCheckNotSeenGrid