import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import Widget from '../../common/Widget';
import { useStore } from '../../../stores/store';
import LoadingComponent from '../../common/LoadingComponent';
import { WidgetProps } from '../../../models/WidgetProps';
import StockLocationChart from './StockLocationChart';
import StockLocationTable from './StockLocationTable';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';
import StockLaundryLocationTable from './StockLaundryLocationTable';
import StockLaundryLocationChart from './StockLaundryLocationChart';


  
function StockLocationWidget({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) {
    const {hotelPortalStore:{stockLocation,loadStockLocation,loadingStockLocation,stockLaundryLocation,loadStockLaundryLocation,loadingStockLaundryLocation},userStore:{currentShop}} = useStore()
    
    useEffect(()=>{
        if(currentShop)
        if(!stockLocation)
            loadStockLocation();
    },[stockLocation,loadStockLocation,currentShop])
    useEffect(()=>{
        if(currentShop)
        if(!stockLaundryLocation)
            loadStockLaundryLocation();
    },[stockLaundryLocation,loadStockLaundryLocation,currentShop])

  return (
    <Widget 
        width={width} 
        noBorder={false} 
        heading={heading} 
        helpText={helpText}
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
    >
        <>
            { (loadingStockLocation || loadingStockLaundryLocation) && <LoadingComponent message='Loading data... please wait!' />} 
            { menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'item_loc_loc':
                        stack.push(<StockLocationChart key={item.action} title={item.name} />);
                        break;
                    case 'item_loc_tab':
                        stack.push(<StockLocationTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                        break;
                    case 'item_loc_lau':
                        stack.push(<StockLaundryLocationTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />)
                        break;
                    case 'item_loc_lau_chrt':
                        stack.push(<StockLaundryLocationChart key={item.action} title={item.name} />);
                        break;
                }
                return stack;
            })}
        </>
    </Widget>
  )
}

export default observer(StockLocationWidget);