import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store'
import CollapsableContainer from '../../common/CollapsableContainer';
import { TableWidgetProps } from '../../../models/WidgetProps';
import { observer } from 'mobx-react-lite';
import { StockGraphEntity } from '../../../models/StockReportSummary';
import StockSoilGrid from './StockSoilGrid';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
const StockSoilTable = ({title,collapsable,helpText}:TableWidgetProps) => {
  const {commonStore:{isDesktop,isMobile},hotelPortalStore:{stockSoiledGraphData,loadingStockSoiledGraphData}} = useStore();
  const [evenSoil, setEvenSoil] = useState<StockGraphEntity[]>([]);
  const [oddSoil, setOddSoil]  = useState<StockGraphEntity[]>([]);
  const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();
  useEffect(() => {
        if(stockSoiledGraphData){
            var arr = [...stockSoiledGraphData.tableData];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenSoil(even);
            setOddSoil(odd);
            setExportProps({
              data: stockSoiledGraphData.tableData,
              headers: [
                  {label: 'Item Name', key: 'stockName'},
                  {label: 'Soiled Received In', key: 'stockQty'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[stockSoiledGraphData])
  return (
    <>
      {!loadingStockSoiledGraphData && stockSoiledGraphData && stockSoiledGraphData.tableData.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
          {stockSoiledGraphData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <StockSoilGrid details={evenSoil} />
                <StockSoilGrid details={oddSoil} />
            </div>
        )}
        {stockSoiledGraphData && isMobile && (
            <StockSoilGrid details={stockSoiledGraphData.tableData} />
        )}   
        </CollapsableContainer>
      )}
    </>
  )
}

export default observer(StockSoilTable)