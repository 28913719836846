import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from 'react-to-print'
import StockForm from "../components/common/Form/StockForm";
import LoadingComponent from "../components/common/LoadingComponent";
import { DeliveryOrder } from "../models/DeliveryOrder";
import { useStore } from "../stores/store";
import RejectStockReport from "../components/RejectStock/RejectStockReport";

export default observer(function RejectStockPage(){
    const {commonStore: {setPreviousRoute,rejectStockRequestCount}, userStore:{user}, hotelPortalStore: { loadRejectStockList, loadingStockList, sendStockReject, responseReceived, responseSuccess }} = useStore();
    const [showReport, setShowReport] = useState(false);
    useEffect(() => {
        setPreviousRoute(window.location.pathname);
        loadRejectStockList();        
    }, [])
   
    const handleSubmit = async (values: {stocks: DeliveryOrder[]}) => {
        await sendStockReject(values.stocks);
    }

    return (
            <>
                <h2 className='page-header'>
                    Reject Stock
                    <button type="button" className="btn btn-default" onClick={()=>setShowReport(!showReport)} >{showReport ? 'Record Reject Stock' : 'View Reject Report'}</button>
                </h2>
                {loadingStockList && <LoadingComponent message='Loading stock list... please wait!' />}
                {!loadingStockList && !showReport && <StockForm handleSubmit={handleSubmit} printReport={true} disabled={!!user && rejectStockRequestCount >= user.maxRequestsAllowed} />}
                {!loadingStockList && showReport && <RejectStockReport />}
            </>
        )
})