import { observer } from 'mobx-react-lite'
import { ContactDetail } from '../../models/ContactDetails';
import ContactDetailsCard from './ContactDetailsCard';
interface Props{
    details: ContactDetail[];
    sendMessage: (detail: ContactDetail) => void;
}
const ContactDetailsGrid = ({details,sendMessage}:Props) => {
  return (
    <div className='contact-details-wrapper'>
                {details.length > 0 ? 
                (
                    details.map((detail,index) =>(
                        <ContactDetailsCard key={index} contact={detail} sendMessage={sendMessage} />
                ))
                ) : (
                    <div className='data-grid-row'>
                    No records found.
                </div>
                )}
    </div>
  )
}

export default observer(ContactDetailsGrid);