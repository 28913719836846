import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { Line } from 'react-chartjs-2';
import CollapsableContainer from '../../common/CollapsableContainer';
import { TableWidgetProps } from '../../../models/WidgetProps';
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from '../../common/DatePickerCustomInput';
import { addMonths } from 'date-fns';
import LoadingComponent from '../../common/LoadingComponent';

const StockCheckTrendChart = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {hotelPortalStore:{stockCheckTrendsData,loadingStockCheckTrendsData,loadStockCheckTrendsData}} = useStore();
    const [fromDate, setFromDate] = useState<Date>(addMonths(new Date(),-1));
    const [toDate, setToDate] = useState<Date>(new Date());
    const [notSeen, setNotSeen] = useState(1);
    const handleChangeFromDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date)
            setFromDate(date);
    }
    const handleChangeToDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date)
            setToDate(date);
    }
    const applyFilter = () => {
        loadStockCheckTrendsData(notSeen,fromDate,toDate);
    }
    useEffect(()=>{
        if(!stockCheckTrendsData)
            loadStockCheckTrendsData(notSeen,fromDate,toDate);
    },[stockCheckTrendsData])
  return (
    
        <CollapsableContainer
            title={title}
            collapsable={collapsable}
            helpText={helpText}
            extraHeading={
                    <>
                        <label>From</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={fromDate}
                            onChange={handleChangeFromDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <label>To</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={toDate}
                            onChange={handleChangeToDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <label>Not Seen</label>
                        <select className='custom-dropdown' value={notSeen} onChange={(e)=>setNotSeen(parseInt(e.target.value))}>
                            <option value={1}>Less than 30 days</option>
                            <option value={2}>Between 30 and 60 days</option>
                            <option value={3}>Between 60 and 90 days</option>
                            <option value={4}>Greater than 90 days</option>
                        </select>
                    </>
            }
        showApplyNow={true}
        onApplyNow={applyFilter}
        >
            {loadingStockCheckTrendsData && <LoadingComponent message='Loading data... please wait!' />}
            {!loadingStockCheckTrendsData && stockCheckTrendsData && stockCheckTrendsData?.datasets[0]?.data?.length > 0 &&  (
                            <Line options={{
                                responsive: true,
                                plugins: {
                                            legend: {
                                                position: 'right',
                                            },
                                            title: {
                                                display: false
                                            }
                                        }
                                    }} data={stockCheckTrendsData} />) }
        </CollapsableContainer>
   
  )
}

export default observer(StockCheckTrendChart);