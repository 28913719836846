
import { MisusedStockAnalysisEntity } from '../../models/MisusedStockReportSummary';
interface Props{
  dataTable: MisusedStockAnalysisEntity[];
}
const MisusedStockAnalysisMobile = ({dataTable}:Props) => {
  return (
    <div className='data-grid'>
        <div className='misusedstock-row header-row'>
          <div>Item</div>
          <div>
            <div className='misusedstock-sub-row'>
              <div>Damage Type</div>
              <div>Qty</div>
              <div>Cost</div>
            </div>
          </div>
        </div>
        {dataTable.map((row,index) => (
          <div key={index} className='misusedstock-row'>
            <div>
              {row.itemName}
            </div>
            <div>
                {row.details.map((cols,index) => (
                  <div key={index} className='misusedstock-sub-row'>
                    <div>{cols.type}</div>
                    <div>{cols.qty > 0 ? cols.qty : ''}</div>
                    <div>{cols.cost > 0 ? cols.cost.toFixed(2) : ''}</div>
                  </div>
                ))}
            </div>
            </div>
        ))}
    </div>
  )
}

export default MisusedStockAnalysisMobile