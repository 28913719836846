import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import {ChartData} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { pieOptions, stackedBarOptions } from '../../../constants/ChartConstants';
import {Colors} from '../../../constants/Colors';



const MisusedStockChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{misusedStockData}} = useStore();
    const [pieData, setPieData] = useState<ChartData<"pie">>();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(misusedStockData){
            setPieData({
                labels: misusedStockData.summary.labels,
                datasets: [
                    {
                        label: 'qty',
                        data: misusedStockData.summary.data,
                        backgroundColor: [
                            `${Colors.PALE_AMBER}`,
                            `${Colors.PALE_ORANGE}`,
                            `${Colors.PALE_RED}`,
                        ],
                        borderColor: [
                            `${Colors.PALE_AMBER}`,
                            `${Colors.PALE_ORANGE}`,
                            `${Colors.PALE_RED}`,
                        ],
                        borderWidth: 1
                    }
                ]
            })
            setBarData({
                labels: misusedStockData.details.labels,
                datasets:[
                    {
                        label: 'Stained',
                        data: misusedStockData.details.stained,
                        backgroundColor: `${Colors.PALE_AMBER}`
                    },
                    {
                        label: 'Damaged',
                        data: misusedStockData.details.damage,
                        backgroundColor: `${Colors.PALE_ORANGE}`
                    },
                    {
                        label: 'Special Clean',
                        data: misusedStockData.details.specialClean,
                        backgroundColor: `${Colors.PALE_RED}`
                    },
                ]

            })
        }
    },[misusedStockData])
  return (
    
        <>
            {!!misusedStockData && misusedStockData?.data?.length > 0 && !!barData && !!pieData && title.length > 0 && <h2 className='collapsable-container-title'>{title}</h2>}
            { !!misusedStockData && misusedStockData?.data?.length > 0 && !!barData && !!pieData  && (
              <div className='graph-container'>
                <div className="width-75">
                    <Bar options={stackedBarOptions} data={barData!} />
                </div>
                <div className="width-25 ml-1">
                    <Pie options={pieOptions} data={pieData!} />
                </div>
              </div>
            ) 
            }
        </>
    
  )
}

export default observer(MisusedStockChart);