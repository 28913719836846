import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { LinenStockReportSummary } from "../../../models/LinenStockReportSummary";
import LinenDetailsReport from "./LinenDetailsReport";

interface Props{
    qtyLaundryLabel: string;
    qtyHotelLabel: string;
    dataRow: LinenStockReportSummary;
}
export default observer(function LinenSummaryReportRow({qtyLaundryLabel, qtyHotelLabel, dataRow}:Props){
    const [showDetails,setShowDetails] = useState(false);
    return (
        <>
        <div className='data-grid-row'>
            <div className='data-grid-col-code'><span>{dataRow.stockCode}</span></div>
            <div className='data-grid-col-name'><span>{dataRow.stockName}</span></div>
            <div className='data-grid-col-qty'><span>{dataRow.stockLaundry}</span></div>
            <div className='data-grid-col-qty'><span>{dataRow.stockHotel}</span></div>
            <div className='data-grid-col-code'><span className='data-toggle' onClick={() => setShowDetails(!showDetails)} >Details</span></div>
        </div>
        {showDetails && (<div className="data-grid-drilldown"><LinenDetailsReport qtyLaundryLabel={qtyLaundryLabel} qtyHotelLabel={qtyHotelLabel} dataTable={dataRow.details} /></div>)}
        </>
    );
})
