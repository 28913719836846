import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import LoadingComponent from "../components/common/LoadingComponent";
import { useStore } from "../stores/store";
import ReportWidget from "../components/Reports/ReportWidget";
import Message from "../components/common/Message";

export default observer(function ReportsPage(){
    const {commonStore,userStore:{user, currentShop}} = useStore();
    const { userModules, userSubModules, loadSubModules ,setPreviousRoute, currentLaundry, clearSubModules} = commonStore;
    useEffect(() => {
        setPreviousRoute(window.location.pathname);
    return(() => clearSubModules())
    }, [])
    useEffect(() => {
        if(userSubModules.length === 0)
            loadSubModules('/reports');
    }, [userModules.length, userSubModules, loadSubModules])

    if(!currentLaundry || !user || !currentShop)
        return <LoadingComponent message='Loading report... please wait!' />
    if(!currentShop.isLicenseValid)
        return <Message title="Access Denied" message="Oops…it seems like your account does not have access to the hotel linen web portal. Please contact sales@axcess-it.co.uk to get you up and running again." />
    if(!userModules.find((mod) => mod.action === window.location.pathname))
        return <Message title="Access Denied" message="Oops…it seems like your account does not have access to the hotel linen web portal. Please contact sales@axcess-it.co.uk to get you up and running again." />

    return (
            <>
                {userSubModules.map((usm) => <ReportWidget  key={usm.action} title={usm.name} menuItems={usm.items || []} width={usm.widgetWidth}/>)}
            </>
    )
})