import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import LoadingComponent from "../components/common/LoadingComponent";
import CHRequestForm from "../components/HotelPortal/CHRequestForm";
import CustomerServiceForm from "../components/HotelPortal/CustomerServiceForm";
import StockRequestRejectReportWidget from "../components/HotelPortal/StockRequestRejectReportWidget";
import { useStore } from "../stores/store";
import DeliveryScheduleWidget from "../components/HotelPortal/DeliverySchedule/DeliveryScheduleWidget";
import StockCirculationWidget from "../components/HotelPortal/StockCirculation/StockCirculationWidget";
import StockLocationWidget from "../components/HotelPortal/StockLocation/StockLocationWidget";
import NextDeliveryWidget from "../components/HotelPortal/NextDelivery/NextDeliveryWidget";
import PreviousDeliveryWidget from "../components/HotelPortal/PreviousDelivery/PreviousDeliveryWidget";
import MisusedStockManagementWidget from "../components/HotelPortal/MisusedStockManagement/MisusedStockManagementWidget";
import RejectStockAnalysisWidget from "../components/HotelPortal/RejectStockAnalysis/RejectStockAnalysisWidget";
import StockCheckWidget from "../components/HotelPortal/StockCheck/StockCheckWidget";
import StockSoilWidget from "../components/HotelPortal/StockSoilIn/StockSoilWidget";
import WearTearReplacedWidget from "../components/HotelPortal/WearTearReplaced/WearTearReplacedWidget";
import StockToReplaceWidget from "../components/HotelPortal/StockToReplace/StockToReplaceWidget";
import StockInjectionWidget from "../components/HotelPortal/StockInjection/StockInjectionWidget";
import CleanLinenComparisionWidget from "../components/HotelPortal/CleanLinenComparision/CleanLinenComparisionWidget";
import SoiledLinenComparisionWidget from "../components/HotelPortal/SoiledLinenComparision/SoiledLinenComparisionWidget";
import CleanLinenCageComparisionWidget from "../components/HotelPortal/CleanLinenCageComparision/CleanLinenCageComparisionWidget";
import SoiledLinenCageComparisionWidget from "../components/HotelPortal/SoiledLinenCageComparision/SoiledLinenCageComparisionWidget";
import StockReplacedWidget from "../components/HotelPortal/StockReplaced/StockReplacedWidget";
import Message from "../components/common/Message";

export default observer(function HotelPortalPage(){
    const {commonStore:{ userModules, userSubModules, loadSubModules ,setPreviousRoute, currentLaundry, clearSubModules}, userStore:{user, currentShop}} = useStore();
    useEffect(() => {
        setPreviousRoute(window.location.pathname);
    return(() => clearSubModules())
    }, [])
    useEffect(() => {
        if(userSubModules.length === 0)
            loadSubModules('/hotelportal');
    }, [userModules,userSubModules.length, loadSubModules])
   if(!currentLaundry || !user || !currentShop)
        return <h2>Loading...</h2>
    if(userModules.length === 0 || userSubModules.length === 0) return (<LoadingComponent message='Loading page... please wait!' />)
    if(!currentShop.isLicenseValid)
        return <Message title="Access Denied" message="Oops…it seems like your account does not have access to the hotel linen web portal. Please contact sales@axcess-it.co.uk to get you up and running again." />
    if(!userModules.find((mod) => mod.action === window.location.pathname))
        return <Message title="Access Denied" message="Oops…it seems like your account does not have access to the hotel linen web portal. Please contact sales@axcess-it.co.uk to get you up and running again." />
    return(
        <>
            {userSubModules.map((usm) => {
                let stack = [];
                switch(usm.action){
                    case 'item_loc'://Item Location 
                        stack.push(<StockLocationWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />);
                    break;
                    case 'stk_circ_chrt'://Stock Circulation 
                        stack.push(<StockCirculationWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'soil_in_chrt'://Soiled Linen Received in Laundry 
                        stack.push(<StockSoilWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'next_delivery'://Next/Pending Delivery 
                        stack.push(<NextDeliveryWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />);
                    break;
                    case 'previous_delivery'://Previous Delivery
                        stack.push(<PreviousDeliveryWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'misused': // Misused Stock Analysis
                        stack.push(<MisusedStockManagementWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'hp_del_sched'://Delivery Schedule 
                        stack.push(<DeliveryScheduleWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'hp_rh'://Request Hampers/Cages 
                        stack.push(<CHRequestForm key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />);
                    break;
                    case 'hp_rr'://Request & Rejects
                        stack.push(<StockRequestRejectReportWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />);
                    break;
                    case 'hp_ccs'://Contact Customer Service
                        stack.push(<CustomerServiceForm key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />);
                    break;
                    case 'reject':
                        stack.push(<RejectStockAnalysisWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'stockcheck':
                        stack.push(<StockCheckWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader}/>)
                    break;
                    case 'weartear':
                        !currentShop.isOwnStock && stack.push(<WearTearReplacedWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'ownstk':
                        currentShop.isOwnStock && stack.push(<StockToReplaceWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'repdstock':
                        stack.push(<StockReplacedWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'stockinject':
                        stack.push(<StockInjectionWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'cleanin':
                        (currentShop.hasHandHeldScanner || currentShop.hasScanCabinet) && stack.push(<CleanLinenComparisionWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'soilout':
                        (currentShop.hasHandHeldScanner || currentShop.hasScanCabinet) && stack.push(<SoiledLinenComparisionWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                    break;
                    case 'soiloutcage':
                        (currentShop.hasHandHeldScanner || currentShop.hasScanCabinet) && stack.push(<SoiledLinenCageComparisionWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />);
                    break;
                    case 'cleanincage':
                        (currentShop.hasHandHeldScanner || currentShop.hasScanCabinet) && stack.push(<CleanLinenCageComparisionWidget key={usm.action} width={usm.widgetWidth} heading={usm.name} menuItems={usm.items} helpText={usm.helpText} moduleHeader={usm.moduleHeader} />)
                }
                return stack;
            })}
        </>
    )
})