import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import HotelPortalStore from "./hotelPortalStore";
import ReportsStore from "./reportsStore";
import UserStore from "./userStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    hotelPortalStore: HotelPortalStore;
    reportsStore: ReportsStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    hotelPortalStore: new HotelPortalStore(),
    reportsStore: new ReportsStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}