import React from 'react'
import { StockCheckFrequencyDetail } from '../../../models/StockCheckFrequencyDto'
import { format } from 'date-fns'
import { CgRadioCheck,CgRadioChecked } from "react-icons/cg";
import { observer } from 'mobx-react-lite';
interface Props{
    stock: StockCheckFrequencyDetail[];
    selectedDate: Date | null;
    setSelectedDate: (date: Date | null, prev: Date | null) => void;
}
const StockCheckFrequencyGrid = ({stock,selectedDate,setSelectedDate}:Props) => {
    const handleClick = (date: Date, prev: Date | null) => {
        if(date !== selectedDate)
            setSelectedDate(date,prev);
    }
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row stock-check-freq-grid-row  heading-row">
            <div className='grid-span-r2'>Select</div>
            <div className='grid-span-r2'>Date</div>
            <div className='grid-span-r2'>Scanned</div>
            <div className='grid-span-c4'>On the shelf for (days)</div>
            <div className='grid-span-r2'>Missing Stock</div>
            <div className='text-right pr-25'>&lt;30</div>
            <div className='text-right pr-25'>30 to 60</div>
            <div className='text-right pr-25'>60 to 90</div>
            <div className='text-right pr-25'>&gt;90</div>
        </div>
        {stock.map((data) =>(
            <div 
                key={format(new Date(data.stockDate),'dd/MM/yyyy')} 
                className={`data-grid-two-col-row stock-check-freq-grid-row ${selectedDate === data.stockDate ? 'data-grid-row-selected' : ''}`} 
                onClick={()=>handleClick(data.stockDate,data.prevStockDate ?? null)}
            >
                <div className='data-grid-icon-col'>{selectedDate === data.stockDate? <CgRadioChecked /> : <CgRadioCheck  />}</div>
                <div>{format(new Date(data.stockDate),'dd/MM/yyyy')}</div>
                <div className='text-right pr-25'>{data.scanned}</div>
                <div className='bg-pale-green text-right pr-25'>{data.lessThan30}</div>
                <div className='bg-pale-amber text-right pr-25'>{data.between30and60}</div>
                <div className='bg-pale-orange text-right pr-25'>{data.between60and90}</div>
                <div className='bg-pale-red text-right pr-25'>{data.greaterThan90}</div>
                <div className='text-right pr-25'>{data.missingStock}</div>
            </div>
        ))}
    </div>
  )
}

export default observer(StockCheckFrequencyGrid)