import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import LoadingComponent from '../components/common/LoadingComponent';
import LinenSummaryReport from '../components/common/Reports/LinenSummaryReport';
import ReportDateFilterBox from '../components/common/Reports/ReportDateFilterBox';

import { useStore } from '../stores/store';

export default observer(function StockInReportPage(){
    const {commonStore, reportsStore:{linenReport, loadingReport, loadCleanLinenInReport}} = useStore();
    const [fromDate, setFromDate] = useState<Date>(new Date());
    const [toDate, setToDate] = useState<Date>(new Date());
    const applyFilter = () =>{
        loadCleanLinenInReport(fromDate,toDate);
    }
    useEffect(() => {
        commonStore.setPreviousRoute(window.location.pathname);
        applyFilter();
    }, [])

    return (
        <>
            <h2 className='page-header'>Clean Linen In Comparison Report</h2>
            {loadingReport ? (
                    <LoadingComponent message='Loading report... please wait!' />
            ) : (
                <>
                    <ReportDateFilterBox fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} applyFilter={applyFilter} />
                    <LinenSummaryReport dataTable={linenReport!} qtyHotelLabel='Scanned in at Hotel' qtyLaundryLabel='Sent By Laundry' />
                </>
            )}
        </>
    )
})