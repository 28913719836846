import { observer } from 'mobx-react-lite'
import React from 'react'
import { LinenCageDetails } from '../../../models/CleanLinenCageComparisionDto';
interface Props{
    data: LinenCageDetails[];
}
const CleanLinenCageComparisionDetailGrid = ({data}:Props) => {
    if(data.length > 0)
        {
            return (
                <div>
                    <div className="data-grid-two-col-row item-header-with-1-col heading-row">
                        <div>Item Name</div>
                        <div>Quantity</div>
                    </div>
                    {data.map((cage) => (
                        <div key={cage.id} className='data-grid-two-col-row item-header-with-1-col'>
                        <div>{cage.stockName}</div>
                        <div>{cage.stockQty}</div>
                        </div>
                    ))}
                </div>
            )
        } else
        return <div />
}

export default observer(CleanLinenCageComparisionDetailGrid);