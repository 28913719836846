import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
import CollapsableContainer from '../../common/CollapsableContainer';
import StockCheckFrequencyGrid from './StockCheckFrequencyGrid';
import { observer } from 'mobx-react-lite';
interface Props extends TableWidgetProps{
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null, prev: Date | null) => void;
}
const StockCheckFrequencyTable = ({title,collapsable,helpText,selectedDate,setSelectedDate}:Props) => {
    const {hotelPortalStore:{stockCheckFrequencyData, loadingStockCheckFrequency}} = useStore();
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>(); 
 
    useEffect(() => {
        if(stockCheckFrequencyData){
            setExportProps({
              data: stockCheckFrequencyData.tableData,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Usage', key: 'usageQty'},
                  {label: 'Rejected', key: 'rejectedQty'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        } 
    },[stockCheckFrequencyData])
  return (
    <>
      {!loadingStockCheckFrequency && stockCheckFrequencyData && stockCheckFrequencyData?.tableData?.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps}>
            <StockCheckFrequencyGrid stock={stockCheckFrequencyData.tableData} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
        </CollapsableContainer>
      )}
    </>
  )
}

export default observer(StockCheckFrequencyTable)