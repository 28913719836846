import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../stores/store";
import Widget from "../common/Widget";
import { WidgetProps } from "../../models/WidgetProps";

export default observer(function StockRequestRejectReportWidget({width,heading,menuItems,helpText}:WidgetProps){
    const {commonStore:{userSubModules}} = useStore()

    if(userSubModules.length > 0 && menuItems.length === 0)
        return <></>

    if(userSubModules.length === 0 || menuItems.length === 0)
        return <h2>Loading...</h2>
    return(
        <Widget width={width} noBorder={false} heading={heading} helpText={helpText}>
            <ul className='list-box'>
                {menuItems.map((m,i) => (<li key={`${m.action}_${i}`}><Link to={m.action}>{m.name}</Link></li>))} 
            </ul>
        </Widget>
    )
})