import React from 'react'
import { observer } from 'mobx-react-lite';
import { StockGraphEntity } from '../../../models/StockReportSummary';
interface Props{
    details: StockGraphEntity[];
}
const StockSoilGrid = ({details}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row item-header-with-1-col heading-row">
              <div>Item Name</div>
              <div>Soiled Received In</div>
          </div>
          {details.map((detail) => (
            <div key={detail.stockName} className='data-grid-two-col-row item-header-with-1-col'>
              <div>{detail.stockName}</div>
              <div>{detail.stockQty}</div>
            </div>
          ))}
    </div>
  )
}

export default observer(StockSoilGrid)