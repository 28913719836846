import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { StockReportEntity } from "../../../models/StockReportSummary";
import StockAllocationDetailsReport from "./StockAllocationDetailsReport";

interface Props{
    dataRow: StockReportEntity;
}
export default observer(function StockDetailRow({dataRow}:Props){
    const [showDetails,setShowDetails] = useState(false);
    return (
        <>
        <div className='data-grid-row'>
            <div className='data-grid-col-code'><span>{dataRow.stockCode}</span></div>
            <div className='data-grid-col-name'><span>{dataRow.stockName}</span></div>
            <div className='data-grid-col-code'><span>{format(new Date(dataRow.stockDate),'dd/MM/yyyy')}</span></div>
            <div className='data-grid-col-qty'><span>{dataRow.stockQty}</span></div>
            <div className='data-grid-col-code'><span className='data-toggle' onClick={() => setShowDetails(!showDetails)} >Details</span></div>
        </div>
        {showDetails && (<div className="data-grid-drilldown"><StockAllocationDetailsReport dataTable={dataRow.details} /></div>)}
        </>
    );
})
