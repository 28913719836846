import React from "react";
import { MdOutlineHelp } from "react-icons/md";

interface Props{
    message: string;
    handleClose: () => void;
}
export default function HelpTextDialog({ message, handleClose}: Props){
    return (
        <div className="message-box-wrapper">
      <div className="message-box">
        <div className="message-box-title">
          <MdOutlineHelp  size={24}/><h2 data-alter-title>Help</h2>
        </div>
        <div className="message-box-body">{
            message.split('<br />').map(line => {
                      return <>{line}<br /></>
                  })
        }
        </div>
        <button type="button" className="btn btn-info" onClick={()=>handleClose()} >Close</button>
      </div>
    </div>
    )
}