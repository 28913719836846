import { makeAutoObservable, runInAction, toJS } from "mobx";
import agent from "../api/agent";
import { ApiResponseStatus } from "../models/ApiResponse";
import { CageHamperRequest } from "../models/CageHamperRequest";
import { DeliveryOrder, DeliveryStats } from "../models/DeliveryOrder";
import { EmailMessage } from "../models/EmailMessage";
import { MisusedStockAnalysisReport, MisusedStockReportSummary } from "../models/MisusedStockReportSummary";
import { StockRequest } from "../models/StockRequest";
import { StockSummary } from "../models/StockStatus";
import { store } from "./store";
import { VisitSchedule } from "../models/VisitSchedule";
import { StockEntity, StockSoiledGraphData } from "../models/StockReportSummary";
import { format } from "date-fns";
import { ItemLaundryLocationDto, ItemLocation } from "../models/ItemLocation";
import { StockCirculationData, StockCirculationNotSeenDto } from "../models/StockCirculationData";
import { MisusedStockDto } from "../models/MisusedStock";
import { RejectStockAnalysisData } from "../models/RejectStockAnalysisData";
import { StockCheckDto, StockCheckTrendsDto } from "../models/StockCheckDto";
import { MisusedStockTypeAnalysisDto } from "../models/MisusedStockTypeAnalysis";
import { WearTearReplacedDto } from "../models/WearTearReplaced";
import { StockToReplaceDto } from "../models/StockToReplaceDto";
import { StockInjectEntity, StockInjectionDto } from "../models/StockInjectionDto";
import { StockCheckFrequencyDto } from "../models/StockCheckFrequencyDto";
import { StockReconcilationDto } from "../models/StockReconcilation";
import { CleanLinenCageComparisionDto } from "../models/CleanLinenCageComparisionDto";
import { LinenCageComparisionDto } from "../models/LinenCageComparisionDto";
import { RequestType } from "../models/RequestTypeEnum";

export default class HotelPortalStore{
    loadingLastDeliveries = false;
    loadingNextDeliveries = false;
    lastDeliveries: DeliveryStats | undefined;
    nextDeliveries: DeliveryStats | undefined;
    deliverySchedule: VisitSchedule[] | undefined;
    stockInCirculation: StockEntity[] | undefined;
    stockSoiledGraphData: StockSoiledGraphData | undefined;
    loadingDeliverySchedule = false;
    stockList: DeliveryOrder[] | undefined;
    rejectStockReport: DeliveryOrder[] = [];
    rejectStockData: RejectStockAnalysisData | undefined;
    loadingRejectStockData = false;
    loadingStockInCirculation = false;
    loadingStockList = false;
    loadingReport = false;
    loadingStockSoiledGraphData = false;
    stockSummaryReport: StockSummary[] | undefined;
    misusedStockReport: MisusedStockReportSummary[] | undefined;
    misusedStockAnalysis: MisusedStockAnalysisReport | undefined;
    misusedStockData: MisusedStockDto | undefined;
    loadingMisusedStockData = false;
    misusedTrendsData: StockCheckTrendsDto | undefined;
    loadingMisusedTrends = false;
    misusedStockTypeAnalysis: MisusedStockTypeAnalysisDto | undefined;
    loadingMisusedStockTypeAnalysis = false;
    responseReceived = false;
    responseSuccess = false;
    messageSent = false;
    messageSuccess = false;
    chRequestSent = false;
    chRequestSuccess = false;
    stockLocation: ItemLocation | undefined;
    loadingStockLocation = false;
    stockLaundryLocation: ItemLaundryLocationDto | undefined;
    loadingStockLaundryLocation = false;
    stockCirculationData: StockCirculationData | undefined;
    loadingStockCirculationData = false;
    stockCirculationNotSeenData: StockCirculationNotSeenDto | undefined;
    loadingStockCirculationNotSeenData = false;
    loadingstockCheckData = false;
    stockCheckData: StockCheckDto | undefined;
    stockCheckTrendsData: StockCheckTrendsDto | undefined;
    loadingStockCheckTrendsData = false;
    wearTearReplacedData: WearTearReplacedDto | undefined;
    loadingWearTearReplaced = false;
    stockToReplaceData: StockToReplaceDto | undefined;
    loadingStockToReplace = false;
    stockReplacedData: StockToReplaceDto | undefined;
    loadingStockReplaced = false;
    stockInjectData: StockInjectionDto | undefined;
    loadingStockInjectData = false;
    sortedStockInjectData: StockInjectEntity[] = [];
    stockCheckFrequencyData: StockCheckFrequencyDto | undefined;
    loadingStockCheckFrequency = false;
    cleanLinenComparisionData: StockReconcilationDto | undefined;
    loadingCleanLinenComparisionData = false;
    soiledLinenComparisionData: StockReconcilationDto | undefined;
    loadingSoiledLinenComparisionData = false;
    cleanLinenCageComparisionData: CleanLinenCageComparisionDto | undefined;
    loadingCleanLinenCageComparisionData = false;
    soiledLinenCageComparisionData: LinenCageComparisionDto | undefined;
    loadingSoiledLinenCageComparisionData = false;

    constructor(){
        makeAutoObservable(this);
    }
    setResponseReceived(value: boolean = true){
        this.responseReceived = value;
    }
    setResponseSuccess(value: boolean = true){
        this.responseSuccess = value;
    }

    setMessageSent(value: boolean = true){
        this.messageSent = value;
    }
    setMessageSuccess(value: boolean = true){
        this.messageSuccess = value;
    }
    setCHRequestSent(value: boolean = true){
        this.chRequestSent = value;
    }
    setCHrequestSuccess(value: boolean = true){
        this.chRequestSuccess = value;
    }
    setLoadingRejecStockData(loading: boolean = true){
        this.loadingRejectStockData = loading;
    }
    setLoadingStockInCirculation(loading: boolean = true){
        this.loadingStockInCirculation = loading;
    }
    setLoadingStockCirculationData(loading: boolean = true){
        this.loadingStockCirculationData = loading;
    }
    setLoadingStockCirculationNotSeenData(loading: boolean = true){
        this.loadingStockCirculationNotSeenData = loading;
    }
    setLoadingStockGraphData(loading: boolean = true){
        this.loadingStockSoiledGraphData = loading;
    }
    setLoadingMisusedStockData(loading: boolean = true){
        this.loadingMisusedStockData = loading;
    }
    setLoadingMisusedStockTypeAnalysis(loading: boolean = true){
        this.loadingMisusedStockTypeAnalysis = loading;
    }
    setLoadingReport(loading: boolean = true){
        this.loadingReport = loading;
    }
    setLoadingStockList(loading: boolean = true){
        this.loadingStockList = loading;
    }
    setLoadingStockLocation(loading: boolean = true){
        this.loadingStockLocation = loading;
    }
    setLoadingStockLaundryLocation(loading: boolean = true){
        this.loadingStockLaundryLocation = loading;
    }
    clearStockList(){
        this.stockList = undefined;
    }
    setLoadingLastDeliveries(value: boolean = true){
        this.loadingLastDeliveries = value;
    }
    setLoadingNextDeliveries(value: boolean = true){
        this.loadingNextDeliveries = value;
    }
    setLoadingDeliverySchedule(value: boolean = true){
        this.loadingDeliverySchedule = value;
    }
    setLoadingStockCheckData(loading: boolean = true){
        this.loadingstockCheckData = loading;
    }
    setLoadingStockCheckTrendsData(loading: boolean = true){
        this.loadingStockCheckTrendsData = loading;
    }
    setLoadingWearTearReplaced(loading: boolean = true){
        this.loadingWearTearReplaced = loading;
    }
    setLoadingStockToReplace(loading: boolean = true){
        this.loadingStockToReplace = loading;
    }
    setLoadingStockReplaced(loading: boolean = true){
        this.loadingStockReplaced = loading;
    }
    setLoadingMisusedTrends(loading: boolean = true){
        this.loadingMisusedTrends = loading;
    }
    setLoadingStockInjectData(loading: boolean = true){
        this.loadingStockInjectData = loading;
    }
    setLoadingStockCheckFrequency(loading: boolean = true){
        this.loadingStockCheckFrequency = loading;
    }
    setLoadingCleanLinenComparisionData(loading: boolean = true){
        this.loadingCleanLinenComparisionData = loading;
    }
    setLoadingSoiledLinenComparisionData(loading: boolean = true){
        this.loadingSoiledLinenComparisionData = loading;
    }
    setLoadingCleanLinenCageComparisionData(loading: boolean = true){
        this.loadingCleanLinenCageComparisionData = loading;
    }
    setLoadingSoiledLinenCageComparisionData(loading: boolean = true){
        this.loadingSoiledLinenCageComparisionData = loading;
    }

    resetDeliveries(){
        if(this.lastDeliveries) this.lastDeliveries = undefined;
        if(this.nextDeliveries) this.nextDeliveries = undefined;
    }
    resetAll(){
        runInAction(() => {
            this.loadingLastDeliveries = false;
            this.loadingNextDeliveries = false;
            this.lastDeliveries = undefined;
            this.nextDeliveries = undefined;
            this.deliverySchedule = undefined;
            this.stockInCirculation = undefined;
            this.stockSoiledGraphData = undefined;
            this.loadingDeliverySchedule = false;
            this.stockList = undefined;
            this.rejectStockReport = [];
            this.rejectStockData = undefined;
            this.loadingRejectStockData = false;
            this.loadingStockInCirculation = false;
            this.loadingStockList = false;
            this.loadingReport = false;
            this.loadingStockSoiledGraphData = false;
            this.stockSummaryReport = undefined;
            this.misusedStockReport = undefined;
            this.misusedStockAnalysis = undefined;
            this.misusedStockData = undefined;
            this.loadingMisusedStockData = false;
            this.misusedStockTypeAnalysis = undefined;
            this.loadingMisusedStockTypeAnalysis = false;
            this.responseReceived = false;
            this.responseSuccess = false;
            this.messageSent = false;
            this.messageSuccess = false;
            this.chRequestSent = false;
            this.chRequestSuccess = false;
            this.stockLocation = undefined;
            this.loadingStockLocation = false;
            this.stockLaundryLocation = undefined;
            this.loadingStockLaundryLocation = false;
            this.stockCirculationData = undefined;
            this.loadingStockCirculationData = false;
            this.stockCirculationNotSeenData = undefined;
            this.loadingStockCirculationNotSeenData = false;
            this.loadingstockCheckData = false;
            this.stockCheckData = undefined;
            this.stockCheckTrendsData = undefined;
            this.loadingStockCheckTrendsData = false;
            this.wearTearReplacedData = undefined;
            this.loadingWearTearReplaced = false;
            this.stockToReplaceData= undefined;
            this.loadingStockToReplace = false;
            this.stockReplacedData= undefined;
            this.loadingStockReplaced = false;
            this.misusedTrendsData = undefined;
            this.loadingMisusedTrends = false;
            this.stockInjectData = undefined;
            this.loadingStockInjectData = false;
            this.sortedStockInjectData = [];
            this.stockCheckFrequencyData = undefined;
            this.loadingStockCheckFrequency = false;
            this.cleanLinenComparisionData = undefined;
            this.loadingCleanLinenComparisionData = false;
            this.soiledLinenComparisionData = undefined;
            this.loadingSoiledLinenComparisionData = false;
            this.cleanLinenCageComparisionData = undefined;
            this.soiledLinenCageComparisionData = undefined;
            this.loadingCleanLinenCageComparisionData = false;
            this.loadingSoiledLinenCageComparisionData = false;
        });
    }
    
    loadLastDeliveries = async () =>{
        this.setLoadingLastDeliveries();
        try{
            const response = await agent.HotelPortal.lastDelivery(store.userStore.currentShop!.shopNo);
            runInAction(() => (this.lastDeliveries = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadLastDeliveries",error);
        }
        finally{
            this.setLoadingLastDeliveries(false);
        }
    }

    loadNextDeliveries = async () =>{
        this.setLoadingNextDeliveries();
        try{
            const response = await agent.HotelPortal.nextDelivery(store.userStore.currentShop!.shopNo);
            runInAction(() => (this.nextDeliveries = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadNextDeliveries",error);
        } 
        finally{
            this.setLoadingNextDeliveries(false)}

    }
    loadDeliverySchedule = async () => {
        this.setLoadingDeliverySchedule();
        try{
            const response = await agent.HotelPortal.deliverySchedule(store.userStore.currentShop!.accountCode);
            runInAction(() => (this.deliverySchedule = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined ))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadDeliverySchedule",error);
        } finally {
            this.setLoadingDeliverySchedule(false);
        }
    }

    loadStockInCirculation = async () => {
        this.setLoadingStockInCirculation();
        try{
            const response = await agent.HotelPortal.stocksInCirculation(store.userStore.currentShop!.shopNo);
            runInAction( ()=> (this.stockInCirculation = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockInCirculation",error);
        } finally {
            this.setLoadingStockInCirculation(false);
        }
    }
    loadStockCirculationData = async () => {
        this.setLoadingStockCirculationData();
        try{
            const response = await agent.HotelPortal.stocksCirculationData(store.userStore.currentShop!.shopNo);
            runInAction(()=>(this.stockCirculationData = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockCirculationData",error);
        } finally {
            this.setLoadingStockCirculationData(false);
        }
    }
    loadStockCirculationNotSeenData = async () => {
        this.setLoadingStockCirculationNotSeenData();
        try{
            const response = await agent.HotelPortal.stocksCirculationNotSeenData(store.userStore.currentShop!.shopNo);
            runInAction(()=>(this.stockCirculationNotSeenData = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockCirculationNotSeenData",error);
        } finally {
            this.setLoadingStockCirculationNotSeenData(false);
        }
    }
    loadStockSoiledGraphData = async (forDate: Date) => {
        this.setLoadingStockGraphData();
        try{
            const response = await agent.HotelPortal.stockSoiledGraphData(store.userStore.currentShop!.shopNo,format(new Date(forDate),"yyyy/MM/dd"));
            runInAction(() => this.stockSoiledGraphData =  response.status === ApiResponseStatus.responseOK ? response.result : undefined);
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockSoiledGraphData",error);
        } finally {
            this.setLoadingStockGraphData(false);
        }
    }

    loadStockLocation = async () => {
        this.setLoadingStockLocation();
        try{
            const response = await agent.HotelPortal.getStockLocation(store.userStore.currentShop!.shopNo);
            runInAction(()=>(this.stockLocation = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockLocation",error);
        } finally {
            this.setLoadingStockLocation(false);
        }
    }

    loadStockLaundryLocation = async () => {
        this.setLoadingStockLaundryLocation();
        try{
            const response = await agent.HotelPortal.getStockLaundryLocation(store.userStore.currentShop!.shopNo);
            runInAction(()=>(this.stockLaundryLocation = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockLaundryLocation",error);
        } finally {
            this.setLoadingStockLaundryLocation(false);
        }
    }

    loadMisusedStockData = async (fromDate: Date,toDate: Date) => {
        this.setLoadingMisusedStockData();
        try{
            const response = await agent.HotelPortal.misusedStockData(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.misusedStockData = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadMisusedStockData",error);
        } finally {
            this.setLoadingMisusedStockData(false);
        }
    }

    loadMisusedStockTypeAnalysis = async (category:string, fromDate: Date,toDate: Date) => {
        this.setLoadingMisusedStockTypeAnalysis();
        try{
            const response = await agent.HotelPortal.misusedStockTypeAnalysis(store.userStore.currentShop!.shopNo,category,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.misusedStockTypeAnalysis = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadMisusedStockTypeAnalysis",error);
        } finally {
            this.setLoadingMisusedStockTypeAnalysis(false);
        }
    }

    clearStockSummaryReport = () => {
        this.stockSummaryReport = undefined;
    }

    loadCustomerStockSummary = async (filter: number, reconciled: boolean) =>{
        this.setLoadingReport();
        try{
            const response = await agent.HotelPortal.customerStockSummary(store.userStore.currentShop!.shopNo, filter, reconciled);
            runInAction(() => (this.stockSummaryReport = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadCustomerStockSummary",error);
            runInAction(() => (this.stockSummaryReport = undefined))
        } finally {
            this.setLoadingReport(false);
        }
    }

    loadLaundryStockSummary = async () =>{
        this.setLoadingReport();
        try{
            const response = await agent.HotelPortal.laundryStockSummary(store.userStore.currentShop!.shopNo);
            runInAction(() => (this.stockSummaryReport = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadLaundryStockSummary",error);
            runInAction(() => (this.stockSummaryReport = undefined))
        } finally {
            this.setLoadingReport(false);
        }
    }

    loadMisusedStockSummary = async (filter: number) => {
        this.setLoadingReport();
        try{
            const response = await agent.HotelPortal.misusedStockSummary(store.userStore.currentShop!.shopNo,filter);
            runInAction(() => (this.misusedStockReport = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadMisusedStockSummary",error);
            runInAction(() => this.misusedStockReport = undefined);
        } finally {
            this.setLoadingReport(false);
        }
    }
    loadMisusedStockAnalysis = async (fromDate: Date, toDate: Date) => {
        this.setLoadingReport();
        try{
            const response = await agent.HotelPortal.misusedStockAnalysis(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.misusedStockAnalysis = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadMisusedStockAnalysis",error);
            runInAction(() => this.misusedStockAnalysis = undefined);
        } finally {
            this.setLoadingReport(false);
        }
    }
    loadRejectStockAnalysis = async (fromDate: Date, toDate: Date) => {
        this.setLoadingReport();
        try{
            const response = await agent.HotelPortal.rejectstockGraphData(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.rejectStockData = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadRejectStockAnalysis",error);
            runInAction(() => this.rejectStockData = undefined);
        } finally {
            this.setLoadingReport(false);
        }
    }
    loadRequestStockList = async () =>{
        this.setLoadingStockList();
        try{
            const response = await agent.HotelPortal.requestStockList(store.userStore.currentShop!.shopNo);
            runInAction(() => (this.stockList = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadRequestStockList",error);
            runInAction(()=>(this.stockList = undefined))
        } finally {
            this.setLoadingStockList(false);
        }
    }

    loadRejectStockList = async () =>{
        this.setLoadingStockList();
        try{
            const response = await agent.HotelPortal.rejectStockList(store.userStore.currentShop!.shopNo);
            runInAction(() => (this.stockList = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined))
        } catch (error){
            store.commonStore.addErrorLog("HotelPortalStore.loadRejectStockList",error);
            runInAction(()=>(this.stockList = undefined))
        } finally {
            this.setLoadingStockList(false);
        }
    }

    sendStockRequest = async (stockRequests: DeliveryOrder[]) => {
        this.setResponseReceived(false)
        this.setResponseSuccess(false);
        try{
            const {currentShop} = store.userStore;
            const stockRequest: StockRequest = {
                locationUID: currentShop!.locationUID,
                accountCode: currentShop!.accountCode,
                items: [...stockRequests]
            }
            const response = await agent.HotelPortal.requestStock(stockRequest);
            this.setResponseSuccess(response.status === ApiResponseStatus.responseOK ? response.result : false);
            if(response.status === ApiResponseStatus.responseOK)
                await store.commonStore.updateRequestCount(RequestType.RequestType_Additional_Stock);
        } catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.sendStockRequest",error);
            this.setResponseSuccess(false);
        } finally {
            this.setResponseReceived();
        }
    }
    
    sendStockReject = async (stockRejects: DeliveryOrder[]) => {
        this.setResponseReceived(false);
        this.setResponseSuccess(false);
        try{
            const {currentShop} = store.userStore;
            const rejectStock: StockRequest = {
                locationUID: currentShop!.locationUID,
                accountCode: currentShop!.accountCode,
                items: [...stockRejects]
            } 
            const response = await agent.HotelPortal.rejectStock(rejectStock);
            this.setResponseSuccess(response.status === ApiResponseStatus.responseOK ? response.result : false);
            if(response.status === ApiResponseStatus.responseOK)
                await store.commonStore.updateRequestCount(RequestType.RequestType_Reject_Stock);
        } catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.sendStockReject",error);
            this.setResponseSuccess(false);
        } finally {
            this.setResponseReceived();
        }
    }

    loadRejectStockReport = async (fromDate: Date, toDate: Date) =>{
        this.setLoadingReport();
        try{
            const response = await agent.HotelPortal.rejectStockReport(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.rejectStockReport = (response.status === ApiResponseStatus.responseOK) ? response.result : []));
        } catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadRejectStockReport",error);
            runInAction(() => this.rejectStockReport = []);
        } finally {
            this.setLoadingReport(false);
        }
    }

    sendMessage = async (message: EmailMessage) => {
        this.setMessageSent(false);
        this.setMessageSuccess(false);
        try{
            message.locationUID = store.userStore.currentShop!.locationUID;
            const response = await agent.HotelPortal.sendMessage(message);
            this.setMessageSuccess(response.status === ApiResponseStatus.responseOK);
             if(response.status === ApiResponseStatus.responseOK)
                await store.commonStore.updateRequestCount(RequestType.RequestType_Contacts_Message);
        } catch(error) {
            store.commonStore.addErrorLog("HotelPortalStore.sendMessage",error);
            this.setMessageSuccess(false);
        } finally {
            this.setMessageSent();
        }
    }

    sendCageHamperRequest = async (request: CageHamperRequest) => {
        this.setCHRequestSent(false);
        this.setCHrequestSuccess(false);
        try{
            const response = await agent.HotelPortal.sendCHRequest(request);
            this.setCHrequestSuccess(response.status === ApiResponseStatus.responseOK);
            if(response.status === ApiResponseStatus.responseOK)
                await store.commonStore.updateRequestCount(RequestType.RequestType_Cages);
        } catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.sendCageHamperRequest",error);
            this.setCHrequestSuccess(false);
        } finally {
            this.setCHRequestSent();
        }
    }
    loadStockCheckData = async (fromDate: Date, toDate: Date | null) => {
        this.setLoadingStockCheckData();
        try
        {
            const response = await agent.HotelPortal.stockCheckDetails(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),toDate ? format(new Date(toDate),"yyyy/MM/dd") : '')
            runInAction(()=> this.stockCheckData = response.status === ApiResponseStatus.responseOK ? response.result : undefined)
        } catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockCheckData",error);
            runInAction(() => this.stockCheckData = undefined);
        } finally {
            this.setLoadingStockCheckData(false);
        }
    }
    unloadStockCheckData = () =>{
        runInAction(() => this.stockCheckData = undefined);
    }
    loadStockCheckTrendsData = async (notSeen:number, fromDate: Date, toDate: Date) => {
        this.setLoadingStockCheckTrendsData();
        try
        {
            const response = await agent.HotelPortal.stockCheckTrendsGraph(store.userStore.currentShop!.shopNo,notSeen,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"))
            runInAction(()=> this.stockCheckTrendsData = response.status === ApiResponseStatus.responseOK ? response.result : undefined)
        } catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockCheckData",error);
            runInAction(() => this.stockCheckTrendsData = undefined);
        } finally {
            this.setLoadingStockCheckTrendsData(false);
        }
    }
    loadWearTearReplaced = async () => {
        this.setLoadingWearTearReplaced();
        try
        {
            const response = await agent.HotelPortal.wearTearReplaced(store.userStore.currentShop!.shopNo);
            runInAction(() => this.wearTearReplacedData = response.status === ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadWearTearReplaced",error);
            runInAction(() => this.wearTearReplacedData = undefined);
        } finally {
            this.setLoadingWearTearReplaced(false);
        }
    }
    loadStockToReplace = async (inlcudeNotSeen: boolean) => {
        this.setLoadingStockToReplace();
        try
        {
            const response = await agent.HotelPortal.stockToReplace(store.userStore.currentShop!.shopNo,inlcudeNotSeen);
            runInAction(() => this.stockToReplaceData = response.status === ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockToReplace",error);
            runInAction(() => this.stockToReplaceData = undefined);
        } finally {
            this.setLoadingStockToReplace(false);
        }
    }
    loadStockReplaced = async (fromDate: Date, toDate: Date) => {
        this.setLoadingStockReplaced();
        try
        {
            const response = await agent.HotelPortal.stockReplaced(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => this.stockReplacedData = response.status === ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockReplaced",error);
            runInAction(() => this.stockReplacedData = undefined);
        } finally {
            this.setLoadingStockReplaced(false);
        }
    }
    loadMisusedTrends = async (misuseType:number, analysisBy: number, fromDate: Date, toDate: Date) => {
        this.setLoadingMisusedTrends();
        try
        {
            const response = await agent.HotelPortal.misusedTrends(store.userStore.currentShop!.shopNo,misuseType,analysisBy,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => this.misusedTrendsData = response.status === ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadMisusedTrends",error);
            runInAction(() => this.misusedTrendsData = undefined);
        } finally {
            this.setLoadingMisusedTrends(false);
        }
    }
    loadStockInjectData = async () => {
        this.setLoadingStockInjectData();
        try
        {
            const response = await agent.HotelPortal.stockInjection(store.userStore.currentShop!.shopNo);
            runInAction(() => this.stockInjectData = response.status === ApiResponseStatus.responseOK ? response.result : undefined);
            this.sortStockInjectData(true);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockInjectData",error);
            runInAction(() => this.stockInjectData = undefined);
        } finally {
            this.setLoadingStockInjectData(false);
        }
    }

    sortStockInjectData = (byDate: boolean) => {
        if(this.stockInjectData){
            runInAction(() => {
                if(byDate)
                    this.sortedStockInjectData = [...this.stockInjectData!.tableData.sort((a,b) => a.stockDate > b.stockDate ? 1: -1)];
                else
                this.sortedStockInjectData = [...this.stockInjectData!.tableData.sort((a,b) => a.itemName > b.itemName ? 1: -1)];
            })
        }
    }
    loadStockCheckFrequency = async (fromDate: Date, toDate: Date) => {
        this.setLoadingStockCheckFrequency();
        try{
            const response = await agent.HotelPortal.stockCheckFrequency(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => this.stockCheckFrequencyData = response.status ===  ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadStockCheckFrequency",error);
            runInAction(() => this.stockCheckFrequencyData = undefined);
        } finally {
            this.setLoadingStockCheckFrequency(false);
        }
    }
    loadCleanLinenComparisionData = async (forDate: Date) => {
        this.setLoadingCleanLinenComparisionData();
        try{
            const response = await agent.HotelPortal.cleanLinenComparision(store.userStore.currentShop!.shopNo,format(new Date(forDate),"yyyy/MM/dd"));
            runInAction(() => this.cleanLinenComparisionData = response.status ===  ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadCleanLinenComparisionData",error);
            runInAction(() => this.cleanLinenComparisionData = undefined);
        } finally {
            this.setLoadingCleanLinenComparisionData(false);
        }
    }
    loadSoiledLinenComparisionData = async (forDate: Date) => {
        this.setLoadingSoiledLinenComparisionData();
        try{
            const response = await agent.HotelPortal.soiledLinenComparision(store.userStore.currentShop!.shopNo,format(new Date(forDate),"yyyy/MM/dd"));
            runInAction(() => this.soiledLinenComparisionData = response.status ===  ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadSoiledLinenComparisionData",error);
            runInAction(() => this.soiledLinenComparisionData = undefined);
        } finally {
            this.setLoadingSoiledLinenComparisionData(false);
        }
    }
     loadCleanLinenCageComparisionData = async (fromDate: Date) => {
        this.setLoadingCleanLinenCageComparisionData();
        try{
            const response = await agent.HotelPortal.cleanLinenCageComparision(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"));
            runInAction(() => this.cleanLinenCageComparisionData = response.status ===  ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadCleanLinenCageComparisionData",error);
            runInAction(() => this.cleanLinenCageComparisionData = undefined);
        } finally {
            this.setLoadingCleanLinenCageComparisionData(false);
        }
    }
    loadSoiledLinenCageComparisionData = async (fromDate: Date) => {
        this.setLoadingSoiledLinenCageComparisionData();
        try{
            const response = await agent.HotelPortal.soiledLinenCageComparision(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"));
            runInAction(() => this.soiledLinenCageComparisionData = response.status ===  ApiResponseStatus.responseOK ? response.result : undefined);
        }catch(error){
            store.commonStore.addErrorLog("HotelPortalStore.loadSoiledLinenComparisionData",error);
            runInAction(() => this.soiledLinenCageComparisionData = undefined);
        } finally {
            this.setLoadingSoiledLinenCageComparisionData(false);
        }
    }

}