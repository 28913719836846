import { observer } from "mobx-react-lite";
import React from "react";
import ResetPasswordBox from "../components/common/ResetPasswordBox";

export default observer(function ChangePasswordPage(){
    
    return (
        <ResetPasswordBox resetKey='' />
    );

})