import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import { addMonths } from 'date-fns';
import CollapsableContainer from '../../common/CollapsableContainer';
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from '../../common/DatePickerCustomInput';
import LoadingComponent from '../../common/LoadingComponent';
import { Line } from 'react-chartjs-2';
import { observer } from 'mobx-react-lite';

const MisusedStockTrendsChart = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {hotelPortalStore:{misusedTrendsData,loadingMisusedTrends,loadMisusedTrends}} = useStore();
    const [fromDate, setFromDate] = useState<Date>(addMonths(new Date(),-1));
    const [toDate, setToDate] = useState<Date>(new Date());
    const [misuseType, setMisuseType] = useState(0);
    const [analysisBy, setAnalysisBy] = useState(1);
    const handleChangeFromDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date)
            setFromDate(date);
    }
    const handleChangeToDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date)
            setToDate(date);
    }
    const applyFilter = () => {
        loadMisusedTrends(misuseType,analysisBy,fromDate,toDate);
    }
    useEffect(()=>{
        if(!misusedTrendsData)
            loadMisusedTrends(misuseType,analysisBy,fromDate,toDate);
    },[])
  return (
    <CollapsableContainer
            title={title}
            collapsable={collapsable}
            helpText={helpText}
            extraHeading={
                    <>
                        <label>From</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={fromDate}
                            onChange={handleChangeFromDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <label>To</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={toDate}
                            onChange={handleChangeToDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <label>Misused Type</label>
                        <select className='custom-dropdown' value={misuseType} onChange={(e)=>setMisuseType(parseInt(e.target.value))}>
                            <option value={0}>STAIN</option>
                            <option value={1}>DAMAGE</option>
                        </select>
                        <label>Analyse by</label>
                        <select className='custom-dropdown' value={analysisBy} onChange={(e)=>setAnalysisBy(parseInt(e.target.value))}>
                            <option value={0}>% of usage</option>
                            <option value={1}>Quantity</option>
                        </select>
                    </>
            }
        showApplyNow={true}
        onApplyNow={applyFilter}
        >
            {loadingMisusedTrends && <LoadingComponent message='Loading data... please wait!' />}
            {!loadingMisusedTrends && misusedTrendsData && misusedTrendsData?.datasets[0]?.data?.length > 0 &&  (
                            <Line options={{
                                responsive: true,
                                plugins: {
                                            legend: {
                                                position: 'right',
                                            },
                                            title: {
                                                display: false
                                            }
                                        }
                                    }} data={misusedTrendsData} />) }
        </CollapsableContainer>
   
  )
}

export default observer(MisusedStockTrendsChart)