import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { DeliveryOrder} from '../../../models/DeliveryOrder';
import CollapsableContainer from '../../common/CollapsableContainer';
import { TableWidgetProps } from '../../../models/WidgetProps';
import DeliveryGrid from '../DeliveryGrid';
import { observer } from 'mobx-react-lite';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
const NextDeliveryTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {commonStore:{isDesktop,isMobile},hotelPortalStore:{nextDeliveries,loadingNextDeliveries}} = useStore();
        const [evenDel, setEvenDel] = useState<DeliveryOrder[]>([]);
        const [oddDel, setOddDel]  = useState<DeliveryOrder[]>([]);
        const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();  
        useEffect(() => {
        if(nextDeliveries?.orders){
            var arr = [...nextDeliveries.orders];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenDel(even);
            setOddDel(odd);
            setExportProps({
              data: nextDeliveries.orders,
              headers: [
                  {label: 'Item Name', key: 'name'},
                  {label: 'Quantity', key: 'quantity'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[nextDeliveries])
  return (
    <>
    {!loadingNextDeliveries && nextDeliveries && nextDeliveries?.orders?.length > 0 && (evenDel.length > 0 || oddDel.length > 0) && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
        {nextDeliveries &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <DeliveryGrid orders={evenDel} />
                <DeliveryGrid orders={oddDel} />
            </div>
        )}
        {nextDeliveries && isMobile && (
            <DeliveryGrid orders={nextDeliveries.orders} />
        )}        
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(NextDeliveryTable)