import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import { LinenStockReportEntity } from "../../../models/LinenStockReportSummary";

interface Props{
    dataRow: LinenStockReportEntity;
}
export default observer(function LinenDetailRow({dataRow}:Props){
    return (
        <div className='data-grid-row'>
            <div className='data-grid-col-code'><span>{dataRow.stockCode}</span></div>
            <div className='data-grid-col-name'><span>{dataRow.stockName}</span></div>
            <div className='data-grid-col-code'><span>{format(new Date(dataRow.stockDate),'dd/MM/yyyy')}</span></div>
            <div className='data-grid-col-qty'><span>{dataRow.stockLaundry}</span></div>
            <div className='data-grid-col-qty'><span>{dataRow.stockHotel}</span></div>
        </div>
    );
})
