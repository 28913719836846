import React, { useEffect } from 'react'
import { useStore } from '../stores/store';
import LoadingComponent from '../components/common/LoadingComponent';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import LaundryManualList from '../components/LaundryManuals/LaundryManualList';
import LaundryManualViewer from '../components/LaundryManuals/LaundryManualViewer';

const LaundryManualsPage = () => {
    const {commonStore:{setPreviousRoute,docLoaded,docManuals}} = useStore();
    const [selectedDoc, setSelectedDoc] = useState('');

    useEffect(() => {
        setPreviousRoute(window.location.pathname);
    }, [])
    useEffect(()=>{
        if(docLoaded){
            if(docManuals.length > 0)
                setSelectedDoc(docManuals[0].filename);
        }
    },[docLoaded,docManuals])
    return (
        <>
        <h2 className='page-header'>Laundry Manuals</h2> 
        {!docLoaded ? (
            <LoadingComponent message='Loading manuals... please wait!' />
        ) : (
            <div className='pdf-manager'>
                <LaundryManualList manual={selectedDoc} selectedManual={setSelectedDoc} />
                <LaundryManualViewer filename={selectedDoc} />
            </div>
        )}
        </>
  )
}

export default observer(LaundryManualsPage);