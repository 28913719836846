import React from 'react'
import { observer } from 'mobx-react-lite';
import { StockCheckMaster } from '../../../models/StockCheckDto';
import StockCheckMasterGridRow from './StockCheckMasterGridRow';
interface Props{
  details: StockCheckMaster[];
}
const StockCheckMasterGrid = ({details}:Props) => {
   
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row stock-check-master-grid-row heading-row">
              <div>Date Started</div>
              <div>Date Finished</div>
              <div>Storage Location</div>
              <div>User</div>
              <div>Details</div>
              <div>Missing Stock</div>
          </div>
          {details.map((detail) => (<StockCheckMasterGridRow key={detail.uniqueRecId} detail={detail} />))}
    </div>
  )
}

export default observer(StockCheckMasterGrid)