import React from 'react'
import { observer } from 'mobx-react-lite';
import { ItemLocationRow } from '../../../models/ItemLocation';
interface Props{
    details: ItemLocationRow[];
}
const StockLocationGrid = ({details}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row item-header-with-3-col heading-row">
              <div>Item Name</div>
              <div>@Hotel</div>
              <div>@Laundry</div>
              <div>Total</div>
          </div>
          {details.map((detail) => (
            <div key={detail.itemCode} className='data-grid-two-col-row item-header-with-3-col'>
              <div>{detail.itemName}</div>
              <div>{detail.hotel}</div>
              <div>{detail.laundry}</div>
              <div>{detail.hotel + detail.laundry}</div>
            </div>
          ))}
    </div>
  )
}

export default observer(StockLocationGrid)