import React, { useEffect, useRef, useState } from 'react'
import ReportDateFilterBox from '../common/Reports/ReportDateFilterBox'
import { addDays, addMonths, format } from 'date-fns';
import { useStore } from '../../stores/store';
import LoadingComponent from '../common/LoadingComponent';
import { observer } from 'mobx-react-lite';
import RejectStockPrintableView from './RejectStockPrintableView';
import { useReactToPrint } from 'react-to-print';

const RejectStockReport = () => {
    const {hotelPortalStore:{loadingReport,rejectStockReport,loadRejectStockReport}} = useStore();
    const [fromDate, setFromDate] = useState<Date>(addMonths(new Date(),-1));
    const [toDate, setToDate] = useState<Date>(new Date());
    const componentRef = useRef<HTMLDivElement>(null)
    
    
    const applyFilter = () =>{
        loadRejectStockReport(fromDate,addDays(toDate,1));
    }
    useEffect(()=>{
        applyFilter();
    },[])
    
    const handlePrint = useReactToPrint({
            documentTitle: 'Reject Stock Report'
    })

  return (
    <>      
        {loadingReport ? (
            <LoadingComponent message='Loading report... please wait!' />
        ) : (
            <>
                <ReportDateFilterBox 
                    fromDate={fromDate} 
                    setFromDate={setFromDate} 
                    toDate={toDate} 
                    setToDate={setToDate} 
                    applyFilter={applyFilter} 
                    onPrint={() => handlePrint(null,()=>componentRef.current)}
                    printDisabled={rejectStockReport.length <= 0}
                />
                <div className="data-grid">
                    <div className="data-grid-row header-row">
                        <div className="data-grid-col-sm">Item Code</div>
                        <div className="data-grid-col-lg">Item Name</div>
                        <div className="data-grid-col-sm">Quantity Rejected</div>
                    </div>
                    {rejectStockReport.length > 0 && rejectStockReport.map((stock,index) => (
                        <div key={index} className='data-grid-row'>
                            <div className='data-grid-col-sm'>{stock.code}</div>
                            <div className='data-grid-col-lg'>{stock.name}</div>
                            <div className='data-grid-col-sm'>{stock.quantity}</div>
                        </div>
                    ))}
                    {rejectStockReport.length === 0 && <div className='data-grid-row'>No records found</div>}
                </div>
                <div style={{width: '100%', height: '100%', marginLeft:'10px',display: 'none'}}>
                    <RejectStockPrintableView ref={componentRef} data={rejectStockReport} dateString={`${format(new Date(fromDate), 'dd/MM/yyyy')} to ${format(new Date(toDate), 'dd/MM/yyyy')}`} />
                </div>
            </>
        )
    }
    </>
  )
}

export default observer(RejectStockReport);