import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Colors } from '../../../constants/Colors';
import { Bar } from 'react-chartjs-2';
import { barOptions } from '../../../constants/ChartConstants';
import { observer } from 'mobx-react-lite';

const SoiledLinenComparisionChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{soiledLinenComparisionData,loadingSoiledLinenComparisionData}} = useStore();
    const [sumBarData, setSumBarData] = useState<ChartData<"bar">>();
    const [barData, setBarData] = useState<ChartData<"bar">>();

    useEffect(()=>{
        if(soiledLinenComparisionData){
            setSumBarData({
                labels: ['Soiled Linen'],
                datasets: [
                    {
                        barThickness: 45, categoryPercentage: 0.5,
                        label: soiledLinenComparisionData.summary.labels[0],
                        data: [soiledLinenComparisionData.summary.data[0]],
                        backgroundColor: `${Colors.PALE_BLUE}`,
                    },
                    {
                        barThickness: 45, categoryPercentage: 0.5,
                        label: soiledLinenComparisionData.summary.labels[1],
                        data: [soiledLinenComparisionData.summary.data[1]],
                        backgroundColor: `${Colors.PALE_GREEN}`,
                    }
                ]
            })
            setBarData({
                labels: soiledLinenComparisionData.details.labels,
                datasets:[
                    {
                        barThickness: 25,categoryPercentage: 0.5,
                        label: 'Items sent by Hotel',
                        data: soiledLinenComparisionData.details.stockDelivered,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                        barThickness: 25,categoryPercentage: 0.5,
                        label: 'Scanned at the Laundry as received',
                        data: soiledLinenComparisionData.details.stockScanned,
                        backgroundColor: `${Colors.PALE_BLUE}`
                    },
                ]

            })
        }
    },[soiledLinenComparisionData])
  return (
    <>
        {!loadingSoiledLinenComparisionData && !!soiledLinenComparisionData 
                && (soiledLinenComparisionData.details.stockDelivered.length > 0 || soiledLinenComparisionData.details.stockScanned.length > 0) && !!barData && !!sumBarData && title.length > 0 && (
                <h2 className='collapsable-container-title'>{title}</h2>)}
            {!loadingSoiledLinenComparisionData && !!soiledLinenComparisionData 
                && (soiledLinenComparisionData.details.stockDelivered.length > 0 || soiledLinenComparisionData.details.stockScanned.length > 0) && !!barData && sumBarData && (
              <div className='graph-container'>
                        <div className="width-75">
                            <Bar options={barOptions} data={barData!} />
                        </div>
                        <div className="width-25 ml-1">
                            <Bar options={{...barOptions,aspectRatio: 1}} data={sumBarData!} />
                        </div>
              </div>
            ) 
            }
        </>
  )
}

export default observer(SoiledLinenComparisionChart);