import React from "react";
import { useField } from "formik";

interface Props{
    name: string;
    fieldLabel: string;
    fieldRows: number;
    placeholder: string;
    disabled: boolean;

}
export default function TextAreaMessageField(props: Props){
    const [field, meta] = useField(props.name);

    return (
        <div className="input-label-group">
            <label className="input-label">{props.fieldLabel}</label>
            <textarea rows={props.fieldRows} className={`input-textarea-field ${(meta.touched && !!meta.error) ? 'input-invalid' : ''}`} 
                    {...field}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
            />
            {meta.touched && meta.error ? (
                 <span className="input-error">{meta.error}</span>
             ) : null}
        </div>
    )
}