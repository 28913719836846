import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { StockNotSeenDetail } from '../../../models/StockCheckDto';
import StockCheckNotSeenGrid from './StockCheckNotSeenGrid';
interface Props{
    details: StockNotSeenDetail[];
}
const StockCheckNotSeenTable = ({details}:Props) => {
    const {commonStore:{isDesktop,isMobile}} = useStore();
    const [evenStock, setEvenStock] = useState<StockNotSeenDetail[]>([]);
    const [oddStock, setOddStock]  = useState<StockNotSeenDetail[]>([]);
    useEffect(() => {
        if(details){
            var arr = [...details];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenStock(even);
            setOddStock(odd);
        }
    },[details])
  return (
    <>
    {details &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <StockCheckNotSeenGrid details={evenStock} />
                <StockCheckNotSeenGrid details={oddStock} />
            </div>
        )}
        {details && isMobile && (
            <StockCheckNotSeenGrid details={details} />
        )}     
    </>
  )
}

export default StockCheckNotSeenTable