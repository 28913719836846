import { FaTimesCircle } from "react-icons/fa";

interface Props {
  showDialog: boolean;
  handleLogOff: () => void;
  handleStay: () => void;
}
const IdleTimeoutDialog = ({showDialog,handleLogOff,handleStay}:Props) => {
     return (
    <div id='dialog' className={`dialog-wrapper ${showDialog ? "dialog-show" : ""}`}>
      <div className="dialog-box">
        <div className="dialog-title">
          <h2 data-alter-title>Your session is about to expire</h2>
          <span className="dialog-close">
            <FaTimesCircle onClick={() => handleStay()} />
          </span>
        </div>
        <div className='dialog-body'>
                <p>
                    Your session is about to expire. You'll be automatically signed out.
                </p>
                <p>
                    Do you want to stay signed in?
                </p>
        </div>
        <div className="dialog-footer">
          <button className="btn btn-cancel" onClick={handleStay}>
            Stay signed in
          </button>
          <button className="btn btn-default" onClick={handleLogOff}>
            Sign out now
          </button>
        </div>
      </div>
    </div>
  );
}
export default IdleTimeoutDialog