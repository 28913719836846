import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores/store';
import { StockReconcilationEntity } from '../../../models/StockReconcilation';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
import CollapsableContainer from '../../common/CollapsableContainer';
import SoiledLinenComparisionGrid from './SoiledLinenComparisionGrid';

const SoiledLinenComparisionTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {commonStore:{isDesktop,isMobile},hotelPortalStore:{soiledLinenComparisionData,loadingSoiledLinenComparisionData}} = useStore();
    const [evenStock, setEvenStock] = useState<StockReconcilationEntity[]>([]);
    const [oddStock, setOddStock]  = useState<StockReconcilationEntity[]>([]);
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();

    useEffect(() => {
        if(soiledLinenComparisionData){
            var arr = [...soiledLinenComparisionData.tableData];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenStock(even);
            setOddStock(odd);
            setExportProps({
              data: soiledLinenComparisionData.tableData,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Stock Sent by Hotel', key: 'stockDelivered'},
                  {label: 'Scanned @Laundry', key: 'stockScanned'},
                  {label: '% Scanned @Hotel', key: 'scannedPercent'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[soiledLinenComparisionData])
    
  return (
    <>
    {!loadingSoiledLinenComparisionData && soiledLinenComparisionData && soiledLinenComparisionData.tableData.length > 0 && evenStock && oddStock && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
        {soiledLinenComparisionData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <SoiledLinenComparisionGrid stocks={evenStock} />
                <SoiledLinenComparisionGrid stocks={oddStock} />
            </div>
        )}
        {soiledLinenComparisionData && isMobile && (
            <SoiledLinenComparisionGrid stocks={soiledLinenComparisionData.tableData} />
        )}        
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(SoiledLinenComparisionTable);