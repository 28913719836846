import React, { useEffect, useState } from 'react'
import { useStore } from '../stores/store';
import LoadingComponent from '../components/common/LoadingComponent';
import ReportDateFilterBox from '../components/common/Reports/ReportDateFilterBox';
import { observer } from 'mobx-react-lite';
import { addMonths } from 'date-fns';
import MisusedStockAnalysisDesktop from '../components/DEPRECATED_MisusedStockAnalysisReport/MisusedStockAnalysisDesktop';
import MisusedStockAnalysisMobile from '../components/DEPRECATED_MisusedStockAnalysisReport/MisusedStockAnalysisMobile';

const MisusedStockAnalysisPage = () => {
 const {commonStore, hotelPortalStore:{loadingReport,loadMisusedStockAnalysis,misusedStockAnalysis}} = useStore();
    const [fromDate, setFromDate] = useState<Date>(addMonths(new Date(),-3));
    const [toDate, setToDate] = useState<Date>(new Date());
    const applyFilter = () =>{
        loadMisusedStockAnalysis(fromDate,toDate);
    }
    useEffect(() => {
        commonStore.setPreviousRoute(window.location.pathname);
        applyFilter();
    }, [])

    return (
        <>
            <h2 className='page-header'>Misused Stock Analysis Report</h2>
            {loadingReport ? (
                    <LoadingComponent message='Loading report... please wait!' />
            ) : (
                <>
                    <ReportDateFilterBox fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} applyFilter={applyFilter} />
                    {misusedStockAnalysis && commonStore.isDesktop && <MisusedStockAnalysisDesktop dataTable={misusedStockAnalysis.data} headers={misusedStockAnalysis.headers} />}
                    {misusedStockAnalysis && commonStore.isMobile && <MisusedStockAnalysisMobile dataTable={misusedStockAnalysis.data} />}
                </>
            )}
        </>
    )
}

export default observer(MisusedStockAnalysisPage)