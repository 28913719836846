import { observer } from 'mobx-react-lite'
import React from 'react'
import { ItemLaundryLocationRow } from '../../../models/ItemLocation'

interface Props{
  details: ItemLaundryLocationRow[]
}
const StockLaundryLocationGrid = ({details}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row item-header-with-3-col heading-row">
              <div>Item Name</div>
              <div>In Processing</div>
              <div>@Rewash</div>
              <div>@Special Clean</div>
          </div>
          {details.map((detail) => (
            <div key={detail.itemCode} className='data-grid-two-col-row item-header-with-3-col'>
              <div>{detail.itemName}</div>
              <div>{detail.inProcessing}</div>
              <div>{detail.reWash}</div>
              <div>{detail.specialClean}</div>
            </div>
          ))}
    </div>
  )
}

export default observer(StockLaundryLocationGrid)