import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import { WearTearReplacedTableRow } from '../../../models/WearTearReplaced';
import { observer } from 'mobx-react-lite';
import CollapsableContainer from '../../common/CollapsableContainer';
import WearTearReplacedGrid from './WearTearReplacedGrid';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';

const WearTearReplacedTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {commonStore:{isDesktop,isMobile},hotelPortalStore:{wearTearReplacedData,loadingWearTearReplaced}} = useStore();
    const [evenData, setEvenData] = useState<WearTearReplacedTableRow[]>([]);
    const [oddData, setOddData]  = useState<WearTearReplacedTableRow[]>([]);
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();
    useEffect(() => {
        if(wearTearReplacedData){
            var arr = [...wearTearReplacedData.tableData];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenData(even);
            setOddData(odd);
            setExportProps({
              data: wearTearReplacedData.tableData,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Stock Injected', key: 'injectedStock'},
                  {label: 'Items Replaced', key: 'replacedStock'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[wearTearReplacedData])
  return (
    <>
      {!loadingWearTearReplaced && wearTearReplacedData && wearTearReplacedData.tableData.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
          {wearTearReplacedData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <WearTearReplacedGrid rows={evenData} />
                <WearTearReplacedGrid rows={oddData} />
            </div>
        )}
        {wearTearReplacedData && isMobile && (
            <WearTearReplacedGrid rows={wearTearReplacedData.tableData} />
        )}   
        </CollapsableContainer>
      )}
    </>
  )
}

export default observer(WearTearReplacedTable)