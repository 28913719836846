import React from 'react'
import { observer } from 'mobx-react-lite';
import { StockCirutlationTableItem } from '../../../models/StockCirculationData';
interface Props{
    stock: StockCirutlationTableItem[];
}
const StockCirculationGrid = ({stock}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row stock-circulation-grid-row heading-row">
            <div className='grid-span-r2'>Name</div>
            <div className='grid-span-c4'>Last Seen (days)</div>
            <div className='text-right pr-25'>&lt;30</div>
            <div className='text-right pr-25'>30 to 60</div>
            <div className='text-right pr-25'>60 to 90</div>
            <div className='text-right pr-25'>&gt;90</div>
        </div>
        {stock.map(data =>(
            <div key={data.itemName} className="data-grid-two-col-row stock-circulation-grid-row">
                <div>{data.itemName}</div>
                <div className='bg-pale-green text-right pr-25'>{data.lessThan30}</div>
                <div className='bg-pale-amber text-right pr-25'>{data.between30and60}</div>
                <div className='bg-pale-orange text-right pr-25'>{data.between60and90}</div>
                <div className='bg-pale-red text-right pr-25'>{data.greaterThan90}</div>
            </div>
        ))}
    </div>)
}

export default observer(StockCirculationGrid);