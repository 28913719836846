import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { FaFileCsv } from "react-icons/fa6";
import { MdOutlineLiveHelp } from 'react-icons/md';
import HelpTextIcon from './HelpTextIcon';
import HelpTextDialog from './HelpTextDialog';
import { Tooltip, TooltipRefProps } from 'react-tooltip';
interface Props{
    title: string;
    collapsable: boolean;
    extraHeading?: ReactNode;
    children?: ReactNode
    showApplyNow?: boolean;
    onApplyNow?: ()=>void;
    showExport?: boolean;
    exportOptions?: CommonPropTypes;
    helpText?: string;
}
const CollapsableContainer = ({title,collapsable,extraHeading,children,showApplyNow,onApplyNow,showExport,exportOptions,helpText}:Props) => {
    const [expanded, setExpanded] = useState(false)
    const [showHelpDialog, setShowHelpDialog] = useState(false);
    const toolTipRef = useRef<TooltipRefProps>();
    useEffect(()=>{
        if(!collapsable)
            setExpanded(true);
    },[collapsable]);
    const handleApplyNow = () => {
        if(onApplyNow){
            onApplyNow();
            setExpanded(true);
        }
    }
    if(helpText)
            while(helpText.includes('//'))
                helpText = helpText.replace('//','<br />')
  return (
    <>
        <div className={`collapsable-container ${!collapsable && 'collapsable-container-no-border'}`}>
        {title?.length > 0 && (
            <div className='collapsable-container-header'>
                    <h2 className='collapsable-container-title'>{title}
                    {helpText && helpText.length > 0 &&
                        <HelpTextIcon toolTipId='collapsableHelpTextTip' toolTipRef={toolTipRef} helpText={helpText} showDialog={setShowHelpDialog} />}
                        {collapsable &&
                        <div className='collapsable-container-toggle'>
                            {expanded ? <IoIosArrowDropup title='Collapse' size={24} className='collapsable-container-header-icon' onClick={()=>setExpanded(false)} /> : <IoIosArrowDropdown title='Expand' size={24} className='collapsable-container-header-icon' onClick={() => setExpanded(true)} />}
                        </div>
                        }
                        </h2>
                        <div className='widget-extra-heading'>
                            {extraHeading && extraHeading}
                            {showApplyNow && <button type='button' className='btn btn-small btn-default' onClick={()=>handleApplyNow()}>Apply</button>}
                            {showExport && exportOptions && <CSVLink {...exportOptions}><FaFileCsv title='Export to CSV' style={{marginTop: '0.5rem'}} size={22} /></CSVLink> }
                        </div>
            
            </div>
        )}
        {expanded && (
            <div className='collapsable-container-body'>
                {children}
            </div>
        )}
        </div>
        {showHelpDialog && helpText && <HelpTextDialog message={helpText} handleClose={()=>setShowHelpDialog(false)} />}
        <Tooltip id='collapsableHelpTextTip' style={{backgroundColor: '#d4e2f5', color: 'black'}}/>
    </>
  )
}

export default CollapsableContainer