import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import CollapsableContainer from '../../common/CollapsableContainer'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores/store';
import LoadingComponent from '../../common/LoadingComponent';
import MisusedTypeGridRow from './MisusedTypeGridRow';
interface IProps extends TableWidgetProps{
  fromDate: Date;
  toDate: Date;
}
const MisusedTypeAnalysis = ({title,collapsable,helpText,fromDate,toDate}:IProps) => {
  const {hotelPortalStore:{loadingMisusedStockTypeAnalysis,loadMisusedStockTypeAnalysis,misusedStockTypeAnalysis}} = useStore();
  const [misuseCategory, setMisuseCategory] = useState('0');
  useEffect(()=>{
    if(!misusedStockTypeAnalysis)
      loadMisusedStockTypeAnalysis(misuseCategory,fromDate,toDate);
  },[])
  const handleCategoryChange = (category: string) => {
      setMisuseCategory(category);
      loadMisusedStockTypeAnalysis(category,fromDate,toDate);
  }
  return (
    <>
        <CollapsableContainer 
          title={title} 
          collapsable={collapsable}
          helpText={helpText}
          extraHeading={
            <div className='widget-extra-heading'>
              <label>Misuse Category</label>
              <select 
                className="custom-dropdown"
                value={misuseCategory} 
                onChange={(e) => handleCategoryChange(e.target.value)}
              >
                <option value={0}>STAIN</option>
                <option value={1}>DAMAGE</option>
              </select>
            </div>
          }
        >
        {loadingMisusedStockTypeAnalysis && <LoadingComponent message='Loading data... please wait!' />}
        {misusedStockTypeAnalysis && misusedStockTypeAnalysis?.rows?.length > 0 && !loadingMisusedStockTypeAnalysis && (
          <div className='data-grid'>
            <div className='data-grid-row header-row'>
              <div className='data-grid-col-md'>&nbsp;</div>
              {misusedStockTypeAnalysis.headers.map((h,i) => (
                <div key={i} className='data-grid-col-sm data-grid-hlegend'>{h}</div>
              ))}
              <div className='data-grid-col-sm data-grid-hlegend'>Total</div>
            </div>
            {misusedStockTypeAnalysis.rows.map((row) => (<MisusedTypeGridRow key={row.itemName} row={row} />))}
        </div>
        )}
        </CollapsableContainer>
    </>
  )
}

export default observer(MisusedTypeAnalysis);