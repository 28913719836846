import { observer } from 'mobx-react-lite'
import Widget from '../../common/Widget';
import { useStore } from '../../../stores/store';
import { useEffect } from 'react';
import LoadingComponent from '../../common/LoadingComponent';
import { WidgetProps } from '../../../models/WidgetProps';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';
const DeliveryScheduleWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{loadingDeliverySchedule,deliverySchedule,loadDeliverySchedule},userStore:{currentShop}} = useStore();
    useEffect(()=>{
        if(currentShop)
        loadDeliverySchedule();
    },[currentShop,loadDeliverySchedule])
    
    return (
    <Widget 
        width={width}
        noBorder={false}
        heading={heading}
        helpText={helpText}
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
        >
        {loadingDeliverySchedule && <LoadingComponent message='Loading schedule... please wait!' />}
        {deliverySchedule && (
            <div className="delivery-schedule-wrapper">
                <div className="delivery-schedule-card heading-row">
                    <div>Pickup On</div>
                    <div>Pickup Time</div>
                    <div>Delivery On</div>
                </div>
                {deliverySchedule.map((d,i) => (
                    <div key={i} className="delivery-schedule-card">
                        <div>{d.pickUpDay}</div>
                        <div>{d.pickUpTime}</div>
                        <div>{d.deliveryDay}</div>
                    </div>
                ))}
            </div>
        )}
    </Widget>
  )
}

export default observer(DeliveryScheduleWidget);