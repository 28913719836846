import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores/store';
import CollapsableContainer from '../../common/CollapsableContainer';
import CleanLinenCageComparisionMasterGrid from './CleanLinenCageComparisionMasterGrid';
import CleanLinenCageComparisionBarcodeList from './CleanLinenCageComparisionBarcodeList';
import CleanLinenCageComparisionDetailGrid from './CleanLinenCageComparisionDetailGrid';
import { CleanLinenCageComparisionTableItem, LinenCageBarcodes } from '../../../models/CleanLinenCageComparisionDto';

const CleanLinenCageComparisionTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {hotelPortalStore:{cleanLinenCageComparisionData,loadingCleanLinenCageComparisionData}} = useStore();
    const [selectedRow, setSelectedRow] = useState<CleanLinenCageComparisionTableItem | undefined>();
    const [selectedBarcode, setSelectedBarcode] = useState<LinenCageBarcodes | undefined>()
    useEffect(()=>{
      if(cleanLinenCageComparisionData){
        setSelectedRow(undefined);
        setSelectedBarcode(undefined);
      }
    },[cleanLinenCageComparisionData])
    const handleChangeItem = (item: CleanLinenCageComparisionTableItem | undefined) => {
      if (item !== selectedRow){
        setSelectedRow(item)
        setSelectedBarcode(undefined);
      }
    }
  return (
    <>
    {!loadingCleanLinenCageComparisionData && cleanLinenCageComparisionData && cleanLinenCageComparisionData.data.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={false} helpText={helpText}>
        {cleanLinenCageComparisionData && (
          <>
            <div className='grid-two-col-wrapper clean-linen-cage-wrapper'>
                <CleanLinenCageComparisionMasterGrid data={cleanLinenCageComparisionData.data} selectedItem={selectedRow} setSelectedItem={handleChangeItem} />
                <CleanLinenCageComparisionBarcodeList barcodes={selectedRow?.cageBarcodes || []} selectedBarcode={selectedBarcode} setSelectedBarcode={setSelectedBarcode} />
            </div>
            <CleanLinenCageComparisionDetailGrid data={selectedBarcode?.details || []} />
          </>
        )}
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(CleanLinenCageComparisionTable);