import React, { useEffect, useState } from 'react'
import { WidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store'
import Widget from '../../common/Widget';
import LoadingComponent from '../../common/LoadingComponent';
import { observer } from 'mobx-react-lite';
import { addYears } from 'date-fns';
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from '../../common/DatePickerCustomInput';
import StockCheckSummary from './StockCheckSummary';
import StockCheckMasterTable from './StockCheckMasterTable';
import StockCheckTrendChart from './StockCheckTrendChart';
import StockCheckFrequencyChart from './StockCheckFrequencyChart';
import StockCheckFrequencyTable from './StockCheckFrequencyTable';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';


const StockCheckWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{loadStockCheckFrequency,loadingStockCheckFrequency,stockCheckFrequencyData,loadStockCheckData,unloadStockCheckData},userStore:{currentShop}} = useStore();
    const [fromDate, setFromDate] = useState<Date>(addYears(new Date(),-1));
    const [toDate, setToDate] = useState<Date>(new Date());
    const [selectedDate,setSelectedDate] = useState<Date|null>(null);

    useEffect(()=>{
        if(currentShop)
        if(!stockCheckFrequencyData){
            loadStockCheckFrequency(fromDate,toDate);
            setSelectedDate(null)
        }
    },[stockCheckFrequencyData,loadStockCheckFrequency,fromDate,toDate,currentShop])

    const handleChangeFromDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date)
            setFromDate(date);
    }
    const handleChangeToDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date)
            setToDate(date);
    }
    const handleSelectDate = (date: Date | null, prev: Date | null) => {
        if(date){
            if(selectedDate !== date){
                setSelectedDate(date);
                loadStockCheckData(date,prev);
            }             
        } else {
            setSelectedDate(null);
            unloadStockCheckData();
        }
    }
    
  return (
    <>
        <Widget 
            width={width} 
            noBorder={false} 
            heading={heading}
            helpText={helpText}
            extraHeading={
                <div className='widget-extra-heading'>
                        <label>Stock Check Start</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={fromDate}
                            onChange={handleChangeFromDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <label>Stock Check Finish</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={toDate}
                            onChange={handleChangeToDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <button type='button' className='btn btn-small btn-default' onClick={()=>loadStockCheckFrequency(fromDate,toDate)}>Apply</button>
                    </div>
            }
            headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
            >
            <>
              {loadingStockCheckFrequency && <LoadingComponent message='Loading stock... please wait' />}
                        {menuItems.map((item) => {
                        let stack = [];
                        switch(item.action)
                        {
                            case 'stockcheck_freq':
                                stack.push(<StockCheckFrequencyChart key={item.action} title={item.name} />)
                                break;
                            case 'stockcheck_freq_det':
                                stack.push(<StockCheckFrequencyTable key={item.action} title={item.name} collapsable={item.isDrilldown} selectedDate={selectedDate} setSelectedDate={handleSelectDate} helpText={item.helpText} />)
                                break;
                            case 'stockcheck_sum':
                                stack.push(<StockCheckSummary key={item.action} />);
                                break;
                            case 'stockcheck_det':
                                stack.push(<StockCheckMasterTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                                break;
                            case 'stockcheck_trend_chrt':
                                stack.push(<StockCheckTrendChart key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />)
                                break;
                        }
                        return stack;
                        })}
            </>
        </Widget>
    </>
  )
}

export default observer(StockCheckWidget)