import { observer } from "mobx-react-lite";
import React from "react";
import { StockReportSummary } from "../../../models/StockReportSummary";
import StockSummaryRow from "./StockSummaryRow";
interface Props{
    qtyLabel: string;
    dataTable: StockReportSummary[];
}
export default observer(function StockSummaryReport({qtyLabel,dataTable}:Props){
    return(
        <div className='data-grid'>
            <div className='data-grid-row header-row'>
                <div className='data-grid-col-code'><span>Stock Code</span></div>
                <div className='data-grid-col-name'><span>Stock Name</span></div>
                <div className='data-grid-col-qty'><span>{qtyLabel}</span></div>
                <div className='data-grid-col-code'><span>Details</span></div>
            </div>
            { dataTable && dataTable.length > 0 ? (
                dataTable.map(row => (
                    <StockSummaryRow qtyLabel={qtyLabel} dataRow={row} />
                ))
            ) : (
                <div className='data-grid-row'>
                    No records found.
                </div>
            )}
        </div>
    )
});