import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import LoadingComponent from '../components/common/LoadingComponent';
import ReportDateFilterBox from '../components/common/Reports/ReportDateFilterBox';
import StockSummaryReport from '../components/common/Reports/StockSummaryReport';
import { useStore } from '../stores/store';

export default observer(function CustomerEntranceReportPage(){
    const {commonStore, reportsStore:{stockReport, loadingReport, loadStockThroughCustomerReport}} = useStore();
    const [fromDate, setFromDate] = useState<Date>(new Date());
    const [toDate, setToDate] = useState<Date>(new Date());
    const applyFilter = () =>{
        loadStockThroughCustomerReport(fromDate,toDate);
    }
    useEffect(() => {
        commonStore.setPreviousRoute(window.location.pathname);
        applyFilter();
    }, [])

    return (
        <>
            <h2 className='page-header'>Stock Passing Through Customer Entrance Report</h2>
            {loadingReport ? (
                    <LoadingComponent message='Loading report... please wait!' />
            ) : (
                <>
                    <ReportDateFilterBox fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} applyFilter={applyFilter} />
                    <StockSummaryReport dataTable={stockReport!} qtyLabel='Passing through Customer Entrance' />
                </>
            )}
        </>
    )
})