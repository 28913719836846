import { ErrorMessage, Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ResetPasswordInputValues } from "../../models/User";
import { useStore } from "../../stores/store";
import InputGroup from "./Form/InputGroup";
import * as Yup from 'yup';
import { FaKey } from "react-icons/fa";
import InfoBox from "./Form/InfoBox";
import { Redirect } from "react-router-dom";
interface Props{
    resetKey: string;
}
export default observer(function ResetPasswordBox({resetKey}:Props){
    const {commonStore: {currentLaundry, laundryUID, getErrorMessage}, userStore: {requestSent,responseSuccess,resetPassword,changePassword, logOff}} = useStore();
    const [submitted, setSubmitted] = useState(false);
    const [redirectNow, setRedirectNow] = useState(false);
    const validationSchema = Yup.object({
        resetKey: Yup.string().optional(),
        oldPassword: Yup.string().when('resetKey',{
            is:  (resetKey: string) => !resetKey || resetKey.length === 0,
            then: 
            Yup.string().required('Kindly provide your current password').min(5).max(25),
            otherwise: 
            Yup.string().optional()
        }),
        password1: Yup.string().required('Kindly enter new password').min(5).max(25),
        password2: Yup.string().test('match','Passwords do not match',function(password2){
            return password2 === this.parent.password1;
        })
    }) 
    const initValues = () => ({oldPassword: '', password1:'', password2:'', resetKey: resetKey, error: null})

    const onSubmit = (values: ResetPasswordInputValues, setErrors: (errors: FormikErrors<ResetPasswordInputValues>) => void) =>{
        setSubmitted(true);
        if(resetKey.length > 0)
            return resetPassword(values).catch((err) => setErrors({error:  getErrorMessage(err)}));
        else
            return changePassword(values).catch((err) => setErrors({error: getErrorMessage(err)}))
    }

    useEffect(()=>{
        if(redirectNow){
            logOff();
            <Redirect to={`/${laundryUID}`} />
        }
    },[redirectNow, logOff, laundryUID])

    const onOK = () => {
         setSubmitted(false); 
        setRedirectNow(responseSuccess) 
    }

    return(
        <>
        <div className={resetKey.length > 0 ? "login-wrapper" : "dialog-wrapper"}>
                    {resetKey.length > 0 && (
        <div className="logo">
            <img src= {`${currentLaundry?.appDocUrl}/Logo/${currentLaundry?.logo}`} alt={currentLaundry?.laundryName} />
            <h1 className="logo-title">{currentLaundry?.laundryName}</h1>
        </div>

        )}
        <div className="dialog-box login-box">
            <div className="login-box-title">
                <h2>{resetKey.length > 0 ? "Reset Password" : "Change Password"}</h2>
            </div>
            {requestSent && submitted && responseSuccess && <InfoBox success={responseSuccess} message={"Your password has been reset successfully. Please click Ok to login with your new password."} isPopup={true} handleOK={onOK} />}            
            <Formik
                initialValues={initValues()}
                onSubmit={(values,{setErrors}) => onSubmit(values,setErrors)}
                validationSchema={validationSchema}
            >
                {({handleSubmit, isSubmitting, touched, dirty, errors}) =>  (
                    <Form onSubmit={handleSubmit}>
                        {resetKey.length === 0 && (<InputGroup name='oldPassword' placeholder='Old Password' type='password' icon={<FaKey />} disabled={isSubmitting && dirty && !!touched} />)}
                        <InputGroup name='password1' placeholder='New Password' type='password' icon={<FaKey />} disabled={isSubmitting && dirty && !!touched} />
                        <InputGroup name='password2' placeholder='Re-type New Password' type='password' icon={<FaKey />} disabled={isSubmitting && dirty && !!touched} />
                        <ErrorMessage name='error' render={() => <span className='input-error'>{errors.error}</span>} />
                        <button type="submit" className="btn-fullwidth btn-default mb-20" disabled={isSubmitting && dirty && !!touched}>Reset</button>
                    </Form>
                )}
            </Formik>
        </div>
    </div>
    </>
    )
});