import React from 'react'
import { LinenCageComparisionTableItem } from '../../../models/LinenCageComparisionDto'
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
interface Props{
  cages: LinenCageComparisionTableItem[];
}
const SoiledLinenCageComparisionGrid = ({cages}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row soiled-linen-cage-table heading-row">
              <div>Date</div>
              <div>Scanned @Hotel</div>
              <div>Scanned @Laundry</div>
          </div>
          {cages.map((cage) => (
            <div key={cage.key} className='data-grid-two-col-row soiled-linen-cage-table'>
              <div>{format(new Date(cage.cageDate),'dd/MM/yyyy')}</div>
              <div>{cage.scannedAtHotel}</div>
              <div>{cage.scannedAtLaundry}</div>
            </div>
          ))}
    </div>
  )
}

export default observer(SoiledLinenCageComparisionGrid);