import React from "react";
interface Props{
    success: boolean;
    message: string;
    isPopup?: boolean;
    handleOK?: () => void;
}
export default function InfoBox({success, message, isPopup, handleOK}: Props){
    return (
        <div className={`info-box-wrapper ${isPopup ? 'info-box-popup' : '' }`}>
        <div className={success ? 'info-box-success' : 'info-box-error'}>
            <div className='info-box-message'>{message}</div>
            <button type='button' className='btn btn-default' onClick={handleOK} >OK</button>
        </div>
        </div>
    )
}