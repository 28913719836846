import { observer } from 'mobx-react-lite';
import { ContactDetail } from '../../models/ContactDetails';
interface Props{
    contact: ContactDetail
    sendMessage: (contact: ContactDetail) => void;
}

const ContactDetailsCard = ({contact, sendMessage}:Props) => {
  return (
    <div className='contact-details-card'>
        <h2 className='contact-name'>{contact.contactName}</h2>
        <span className='contact-position'>{contact.position}</span>
        <span className='contact-dept'>{contact.department}</span>
        <span>Landline: {contact.landline}</span>
        <span>Mobile: {contact.mobile}</span> 
        <span>Email: <a href={`mailto:${contact.email}`}>{contact.email}</a></span>
        <button type='button' className='btn btn-small btn-default' onClick={()=>sendMessage(contact)} >Send Message</button>
    </div>
  )
}

export default observer(ContactDetailsCard);