import React from "react";
interface Props{
    defaultDisabled?: boolean; 
    resetFunction?: () => void;
}
export default function WidgetFormFooter({defaultDisabled, resetFunction}:Props) {
    return (
        <div className='widget-form-footer'>
            {resetFunction && <button type="button" className="btn btn-cancel" onClick={resetFunction}>Close</button>}
            <button type="submit" className="btn btn-default" disabled={defaultDisabled}>Send</button>
        </div>
    )
    
}