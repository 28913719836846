import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Colors } from '../../../constants/Colors';
import { barOptions } from '../../../constants/ChartConstants';

const RejectStockAnalysisChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{rejectStockData,loadingRejectStockData}} = useStore();
    const [barData, setBarData] = useState<ChartData<"bar">>();

     useEffect(()=>{
        if(rejectStockData)
            setBarData({
                labels: rejectStockData.chartData.labels,
                datasets:[
                    {
                        label: 'reject stock',
                        data: rejectStockData.chartData.data,
                        backgroundColor: `${Colors.PALE_BLUE}`
                    },
                ]

            })
    },[rejectStockData])
    return (
    <>
        {!loadingRejectStockData && !!rejectStockData && rejectStockData?.chartData?.data?.length > 0 && !!barData && title.length > 0 && <h2 className='collapsable-container-title'>{title}</h2>}
        {!loadingRejectStockData && !!rejectStockData && rejectStockData?.chartData?.data?.length > 0 && !!barData && (
            <Bar options={barOptions} data={barData!} />
        ) 
        }
    </>
  )
}

export default observer(RejectStockAnalysisChart)