import React from 'react'
import { useStore } from '../../../stores/store';
import { observer } from 'mobx-react-lite';
import StockCheckMasterGrid from './StockCheckMasterGrid';
import { TableWidgetProps } from '../../../models/WidgetProps';
import CollapsableContainer from '../../common/CollapsableContainer';
const StockCheckMasterTable = ({title,collapsable,helpText}:TableWidgetProps) => {
 const {hotelPortalStore:{stockCheckData,loadingstockCheckData}} = useStore();
 
return (
    <div>
    {!loadingstockCheckData && stockCheckData && stockCheckData?.master?.length > 0 && (
      <CollapsableContainer title={title} collapsable={collapsable} helpText={helpText}>
        <StockCheckMasterGrid details={stockCheckData.master} />
      </CollapsableContainer>
      )}
    </div>
  )
}

export default observer(StockCheckMasterTable)