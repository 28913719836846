import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import LoadingComponent from '../components/common/LoadingComponent';
import MisusedStockReport from '../components/MisusedStockReport/MisusedStockReport';
import MisusedStockReportFilter from '../components/MisusedStockReport/MisusedStockReportFilter';
import { useStore } from '../stores/store';

const MisusedStockReportPage = () => {
  const {commonStore,hotelPortalStore: { loadMisusedStockSummary, loadingReport}} = useStore();
    useEffect(() => {
        commonStore.setPreviousRoute(window.location.pathname);
        handleClick(0);
    },[])
    const handleClick = (id: number) => {
        loadMisusedStockSummary(id);
    }
    return (
        <>
            <h2 className='page-header'>Misused Stock to Replace</h2>
                    <MisusedStockReportFilter 
                        captions={['ALL', 'DEDICATED STOCK', 'CUSTOMER STOCK', 'POOL STOCK', 'LOAN STOCK']} 
                        onClick={handleClick}
                    />
                    {loadingReport ? (<LoadingComponent message='Loading report... please wait!' />) : (<MisusedStockReport />)}
        </>
    )
}

export default observer(MisusedStockReportPage);