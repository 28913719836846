
import { MisusedStockAnalysisEntity } from '../../models/MisusedStockReportSummary'
import { observer } from 'mobx-react-lite'
interface Props{
    row: MisusedStockAnalysisEntity
}
const MisusedStockDesktopGridRow = ({row}:Props) => {
  return (
    <div className='data-grid-row lh-default align-center'>
        <div className='data-grid-col-md data-grid-vlegend'>{row.itemName}</div>
        <div className='data-grid-col-sm'>
          <div>Qty</div>
          <div>Cost</div>
        </div>

        {row.details.map((d,i) => (
            <div key={i} className='data-grid-col-sm'>
                <div>{d.qty > 0 ? d.qty : ''}</div>
                <div>{d.cost > 0 ? d.cost.toFixed(2) : ''}</div>
            </div>
        ))}
    </div>
  )
}

export default observer(MisusedStockDesktopGridRow)