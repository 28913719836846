import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/HomePage';
import { useStore } from './stores/store';
import { observer } from 'mobx-react-lite';
import HotelPortalPage from './pages/HotelPortalPage';
import ReportsPage from './pages/ReportsPage';
import CustomerStockReportPage from './pages/CustomerStockReportPage';
import LaundryStockReportPage from './pages/LaundryStockReportPage';
import Header from './components/layout/Header';
import RequestStockPage from './pages/RequestStockPage';
import RejectStockPage from './pages/RejectStockPage';
import TechnicalSupportPage from './pages/TechnicalSupportPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import PrivateRoute from './components/common/PrivateRoute';
import ChangePasswordPage from './pages/ChangePasswordPage';
import StockTakeReportPage from './pages/StockTakeReportPage';
import StockInReportPage from './pages/StockInReportPage';
import CageTrackingReportPage from './pages/CageTrackingReportPage';
import CustomerEntranceReportPage from './pages/CustomerEntranceReportPage';
import StaffEntranceReportPage from './pages/StaffEntranceReportPage';
import StockOutReportPage from './pages/StockOutReportPage';
import LoadingComponent from './components/common/LoadingComponent';
import MisusedStockReportPage from './pages/MisusedStockReportPage';
import LaundryManualsPage from './pages/LaundryManualsPage';
import ContactDetailsPage from './pages/ContactDetailsPage';
import MisusedStockAnalysisPage from './pages/MisusedStockAnalysisPage';
import { Chart as ChartJS, CategoryScale, ArcElement,LinearScale,BarElement,Title,Tooltip,Legend,PointElement, LineElement, defaults} from 'chart.js';
import IdleTimeoutDialog from './components/common/IdleTimeoutDialog';
import { useIdleTimer } from 'react-idle-timer';
ChartJS.register(CategoryScale,LinearScale,BarElement,ArcElement,LineElement,PointElement,Title,Tooltip,Legend);
defaults.color = '#000000';

function App() {
  const { commonStore: {token, laundryUID, currentLaundry, loadLaundry, appInitialized,  setAppInitialized, setIsDesktop, setIsMobile}, userStore: {currentUser, user, isLoggedIn,setPrefilledUserEmail,logOff}} = useStore();
  const [userEmail,setUserEmail] = useState<string|null>();
  const [showIdleDialog, setShowIdleDialog] = useState(false);
  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * 120,
    promptBeforeIdle: 1000 * 30,
    onPrompt: () => setShowIdleDialog(true),
    onIdle: () =>  handleLogOff(),
    disabled: !isLoggedIn,
    debounce: 500
  })
  let location=useLocation();
  useEffect(()=>{
    var searchParams = new URLSearchParams(location.search);
    if(searchParams.has("uid"))
      setUserEmail(searchParams.get("uid"))
  },[location.search])
  useEffect(() => {
    if(userEmail)
      setPrefilledUserEmail(userEmail);
  },[userEmail,setPrefilledUserEmail]);

  useEffect(() => {
    if(token)
      if(!user)
          currentUser();
  },  [token,user])
  
  useEffect(() => {
    if(laundryUID && !currentLaundry)
      loadLaundry();
    else
      setAppInitialized();
  }, [laundryUID])

  const updateMedia = () =>{
      if (window.innerWidth > 1280)
        setIsDesktop();
      else
        setIsMobile();
  }
  useEffect(() => {
    updateMedia();
    window.addEventListener("resize",updateMedia);
    return () => window.removeEventListener("resize",updateMedia)
  },[]);

  useEffect(() => {
    if(currentLaundry){
            let colourScheme = JSON.parse(currentLaundry.colorScheme);
                Object.keys(colourScheme).forEach((key) =>{
                if (key.endsWith('image'))
                  document.documentElement.style.setProperty(`--${key}`, `url(${currentLaundry.appDocUrl}/Files/${colourScheme[key].substr(1)})`)  
                else
                  document.documentElement.style.setProperty(`--${key}`, colourScheme[key])  
        }
        );
        }
  }, [currentLaundry])
  const handleStay = () => {
    setShowIdleDialog(false)
    idleTimer.reset()
  }
  const handleLogOff = () => {
    logOff()
    setShowIdleDialog(false)
  }
  if(!appInitialized)
    return <LoadingComponent message='Initialising app... please wait!' />
  return (
    <>
        <Route exact path={'/'} component={NotFoundPage} />
        <Route 
          path={'/(.+)'}
          render={() => (
            <>
              {isLoggedIn && <Header />}
              <div className={isLoggedIn ? "page" : ""}>
              <div className={isLoggedIn ? "container" : ""}>
              <Switch>
                  <PrivateRoute exact path={'/hotelportal'} component={HotelPortalPage} />
                  <PrivateRoute exact path={'/reports'} component={ReportsPage} />
                  <PrivateRoute exact path={'/customerstocks'} component={CustomerStockReportPage} />
                  <PrivateRoute exact path={'/laundrystocks'} component={LaundryStockReportPage} />
                  <PrivateRoute exact path={'/misusedstocks'} component={MisusedStockReportPage} />
                  <PrivateRoute exact path={'/misusedstocksanalysis'} component={MisusedStockAnalysisPage} />
                  <PrivateRoute exact path={'/requeststock'} component={RequestStockPage} />
                  <PrivateRoute exact path={'/rejectstock'} component={RejectStockPage} />
                  <PrivateRoute exact path={'/technical-support'} component={TechnicalSupportPage} />
                  <PrivateRoute exact path={'/change-password'} component={ChangePasswordPage} />
                  <PrivateRoute exact path={'/stocktake'} component={StockTakeReportPage} />
                  <PrivateRoute exact path={'/stockin'} component={StockInReportPage} />
                  <PrivateRoute exact path={'/stockout'} component={StockOutReportPage} />
                  <PrivateRoute exact path={'/cagetracking'} component={CageTrackingReportPage} />
                  <PrivateRoute exact path={'/stockleftcust'} component={CustomerEntranceReportPage} />
                  <PrivateRoute exact path={'/stockleftstaff'} component={StaffEntranceReportPage} />
                  <PrivateRoute exact path={'/manuals'} component={LaundryManualsPage} />
                  <PrivateRoute exact path={'/contacts'} component={ContactDetailsPage} />
                  <Route exact path={'/resetpassword'} component={ResetPasswordPage} />
                  <Route exact path={"/:id"} component={HomePage} />
              </Switch>
              </div>
              </div>
              <IdleTimeoutDialog showDialog={showIdleDialog} handleStay={handleStay} handleLogOff={handleLogOff} />
            </>
          )}
        />
    </>
  );
}

export default observer(App);
