import React from "react";
import { useField } from "formik";

interface Props{
    name: string;
    disabled: boolean;
    min?: number;
    max?: number;
}
export default function InputQtyField({name,disabled,min,max}: Props){
    const [field,meta] = useField(name);

    return (
        <input className={`input-field ${(meta.touched && !!meta.error) ? 'input-invalid' : ''} no-margin`} 
                {...field}
                name={name}
                type='number'
                disabled={disabled}
                min={min??0}
                max={max??999}
            />
    )
}