import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Colors } from '../../../constants/Colors';
import { Bar } from 'react-chartjs-2';
import { stackedBarOptions } from '../../../constants/ChartConstants';
import { observer } from 'mobx-react-lite';

const WearTearReplacedChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{wearTearReplacedData,loadingWearTearReplaced}} = useStore()
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(wearTearReplacedData){
            setBarData({
                labels: wearTearReplacedData.chartData.labels,
                datasets:[
                    {
                        label: 'stock injected',
                        data: wearTearReplacedData.chartData.injectedStock,
                        backgroundColor: `${Colors.PALE_AMBER}`
                    },
                    {
                        label: 'items replaced',
                        data: wearTearReplacedData.chartData.replacedStock,
                        backgroundColor: `${Colors.PALE_BLUE}`
                    },
                ]

            })
        }
    },[wearTearReplacedData])
  return (
    <>
    {!loadingWearTearReplaced && !!wearTearReplacedData && (wearTearReplacedData.chartData.injectedStock.length > 0 || wearTearReplacedData.chartData.replacedStock.length > 0) && !!barData && title.length > 0 && (
        <h2 className='collapsable-container-title'>{title}</h2>)}    
    {!loadingWearTearReplaced && !!wearTearReplacedData && (wearTearReplacedData.chartData.injectedStock.length > 0 || wearTearReplacedData.chartData.replacedStock.length > 0) && !!barData && (
        <Bar options={stackedBarOptions} data={barData!} />)}
    </>
  )
}

export default observer(WearTearReplacedChart)