import { observer } from 'mobx-react-lite'
import React from 'react'
import { LinenCageBarcodes } from '../../../models/CleanLinenCageComparisionDto'
interface Props{
    barcodes: LinenCageBarcodes[];
    selectedBarcode?: LinenCageBarcodes;
    setSelectedBarcode: (barcode: LinenCageBarcodes | undefined) => void;
}
const CleanLinenCageComparisionBarcodeList = ({barcodes,selectedBarcode,setSelectedBarcode}:Props) => {
    if(barcodes.length > 0){
        return (
        <div className="clean-linen-cage-table-container"> 
            <div className='data-grid-row heading-row'>Clean Linen Cage RFID</div>
            {barcodes.map( (barcode) => (
                <div 
                    key={barcode.cageBarcode}
                    onClick={()=>setSelectedBarcode(barcode)}
                    className={`data-grid-row ${selectedBarcode === barcode ? 'selected' : ''}`}
                >
                    {barcode.cageRFID}
                </div>    
            ))}
        </div>)
    } else return <div />
}

export default observer(CleanLinenCageComparisionBarcodeList);