import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { EmailMessage } from "../../models/EmailMessage";
import { useStore } from "../../stores/store";
import InputMessageField from "../common/Form/InputMessageField";
import TextAreaMessageField from "../common/Form/TextAreaMessageField";
import Widget from "../common/Widget";
import * as Yup from 'yup';
import { WidgetProps } from "../../models/WidgetProps";
import DisabledOverlay from "../common/DisabledOverlay";
import { MaxRequestMessage } from "../../constants/Messages";
export default observer(function CustomerServiceForm({width,heading,menuItems,helpText}:WidgetProps){
    const {hotelPortalStore: {messageSent, messageSuccess, sendMessage}, userStore: {currentShop,user}, commonStore:{contactMessageRequestCount}} = useStore()
    const [submitted, setSubmitted] = useState(false);
    const messageValue = () => ({locationUID: '', name: '',subject:'',message: '',accountCode:'',contactId:''});
    const message = () => (messageSuccess ? 'Message sent successfully' : 'An error occured while sending message');

    const validationSchema = Yup.object({
        name: Yup.string().required().min(3).max(50),
        subject: Yup.string().required().min(5).max(25),
        message: Yup.string().required().min(5).max(500)
    });
    let disabled = !!user && contactMessageRequestCount >= user.maxRequestsAllowed;

    const handleSubmit = (values: EmailMessage) =>{
        sendMessage({...values, accountCode: currentShop!.accountCode})
        setSubmitted(true);
    }
    let widgetWidth: string;
    switch(width){
        case 1:
            widgetWidth='width-25';
            break;
        case 2:
            widgetWidth='width-50';
            break;
        case 3:
            widgetWidth='width-75';
            break;
        case 4:
            widgetWidth='width-100';
            break;
    }
    return(
        <Formik
                initialValues={messageValue()}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={handleSubmit} 
            >{({handleSubmit, resetForm, isSubmitting, touched, dirty}) => (
                <Form className={`widget-form-wrapper ${widgetWidth}`} onSubmit={handleSubmit}>
                    <Widget 
                        width={4} 
                        noBorder={false} 
                        heading={heading} 
                        helpText={helpText}
                        hasFooter={true} 
                        showInfoBox={submitted && messageSent} 
                        message={message()} 
                        success={messageSuccess}
                        defaultDisabled={(!!user && contactMessageRequestCount >= user.maxRequestsAllowed) || (isSubmitting && dirty && !!touched)}
                        resetFunction={() =>{ resetForm(); setSubmitted(false);}}
                        >
                        {disabled && !(submitted && messageSent) && (<DisabledOverlay message={MaxRequestMessage} showClose={false} />)}
                        <div className='widget-form'>
                            <InputMessageField name='name' fieldLabel='Name' placeholder='Your Name' disabled={isSubmitting && dirty && !!touched} />
                            <InputMessageField name='subject' fieldLabel='Subject' placeholder='Subject' disabled={isSubmitting && dirty && !!touched} />
                            <TextAreaMessageField name='message' fieldLabel='Message' placeholder='Your message...' fieldRows={8} disabled={isSubmitting && dirty && !!touched} />
                        </div>
                    </Widget>
                </Form>

            )}
                
            </Formik>
    )
})