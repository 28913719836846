import { observer } from 'mobx-react-lite'
import React from 'react'
import { CleanLinenCageComparisionTableItem } from '../../../models/CleanLinenCageComparisionDto'
import { format } from 'date-fns';
interface Props{
    data: CleanLinenCageComparisionTableItem[];
    selectedItem?: CleanLinenCageComparisionTableItem;
    setSelectedItem: (item: CleanLinenCageComparisionTableItem | undefined) => void;
}
const CleanLinenCageComparisionMasterGrid = ({data,selectedItem,setSelectedItem}:Props) => {
  return (
    <div className='clean-linen-cage-table-container'>
        <div className="data-grid-row clean-linen-cage-master-table heading-row">
              <div>Date</div>
              <div>Number of Cages Delivered</div>
              <div>Number of Cages scanned in Hotel</div>
          </div>
          {data.map((cage) => (
            <div 
                key={cage.key} 
                className={`data-grid-row clean-linen-cage-master-table ${selectedItem?.key === cage.key ? 'data-grid-row-selected' : ''}`}
                onClick={()=>setSelectedItem(cage)}
            >
              <div>{format(new Date(cage.cageDate),'dd/MM/yyyy')}</div>
              <div>{cage.scannedAtLaundry}</div>
              <div>{cage.scannedAtHotel}</div>
            </div>
          ))}
    </div>
  )
}

export default observer(CleanLinenCageComparisionMasterGrid);