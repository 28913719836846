import React from 'react'
import { MisusedStockAnalysisEntity } from '../../models/MisusedStockReportSummary'
import { observer } from 'mobx-react-lite';
import MisusedStockDesktopGridRow from './MisusedStockDesktopGridRow';
interface Props{
  headers: string[];
  dataTable: MisusedStockAnalysisEntity[];
}
const MisusedStockAnalysisDesktop = ({headers,dataTable}:Props) => {
  return (
    <div className='data-grid'>
        <div className='data-grid-row header-row'>
          <div className='data-grid-col-md'>&nbsp;</div>
          <div className='data-grid-col-sm'>&nbsp;</div>
          {headers.map((h,i) => (
            <div key={i} className='data-grid-col-sm data-grid-hlegend'>{h}</div>
          ))}
          <div className='data-grid-col-sm data-grid-hlegend'>Total</div>
        </div>
        {dataTable.map((row,index) => (<MisusedStockDesktopGridRow key={index} row={row} />))}
    </div>
  )
}

export default observer(MisusedStockAnalysisDesktop)