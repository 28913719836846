import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Colors } from '../../../constants/Colors';
import { Bar, Pie } from 'react-chartjs-2';
import { pieOptions, stackedBarOptions } from '../../../constants/ChartConstants';

const StockLaundryLocationChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{stockLaundryLocation,loadingStockLaundryLocation}} = useStore()
    const [pieData, setPieData] = useState<ChartData<"pie">>();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(stockLaundryLocation){
            setPieData({
                labels: ['In Processing','@Rewash','@Special Clean'],
                datasets: [
                    {
                        label: '% at Location',
                        data: [stockLaundryLocation.chartData.inProcess.reduce((a,b)=>a+b,0),
                            stockLaundryLocation.chartData.reWash.reduce((a,b)=>a+b,0),
                            stockLaundryLocation.chartData.specialClean.reduce((a,b)=>a+b,0)
                        ],
                        backgroundColor: [
                            `${Colors.PALE_BLUE}`,
                            `${Colors.PALE_GREEN}`,
                            `${Colors.PALE_RED}`
                        ],
                        borderColor: [
                            `${Colors.PALE_BLUE}`,
                            `${Colors.PALE_GREEN}`,
                            `${Colors.PALE_RED}`
                        ],
                        borderWidth: 1
                    }
                ]
            })
            setBarData({
                labels: stockLaundryLocation.chartData.labels,
                datasets:[
                    {
                        label: 'In Processing',
                        data: stockLaundryLocation.chartData.inProcess,
                        backgroundColor: `${Colors.PALE_BLUE}`
                    },
                    {
                        label: '@Rewash',
                        data: stockLaundryLocation.chartData.reWash,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                        label: '@Special Clean',
                        data: stockLaundryLocation.chartData.specialClean,
                        backgroundColor: `${Colors.PALE_RED}`
                    },
                ]

            })
        }
    },[stockLaundryLocation])
  return (
        <>
            {!loadingStockLaundryLocation && !!stockLaundryLocation && !!barData && !!pieData && title.length > 0 && (
                <h2 className='collapsable-container-title'>{title}</h2>)}        
            {!loadingStockLaundryLocation && !!stockLaundryLocation && !!pieData && !!barData && (
                <div className='graph-container'>
                    <div className="width-75">
                        <Bar options={stackedBarOptions} data={barData!} />
                    </div>
                    <div className="width-25 ml-1">
                        <Pie options={pieOptions} data={pieData!} />
                    </div>
                </div>)}
        </>
  )
}

export default observer(StockLaundryLocationChart)