import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import { StockAllocationDetail } from "../../../models/StockReportSummary";

interface Props{
    dataRow: StockAllocationDetail;
}
export default observer(function StockAllocationDetailRow({dataRow}:Props){
    return (
        <div className='data-grid-row'>
            <div className='data-grid-col-code'><span>{dataRow.stockCode}</span></div>
            <div className='data-grid-col-name'><span>{dataRow.stockName}</span></div>
            <div className='data-grid-col-code'><span>{dataRow.rfid}</span></div>
            <div className='data-grid-col-code'><span>{format(new Date(dataRow.stockDate),'dd/MM/yyyy HH:mm:ss')}</span></div>
        </div>
    );
})
