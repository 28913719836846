import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import Widget from '../../common/Widget';
import { useStore } from '../../../stores/store';
import LoadingComponent from '../../common/LoadingComponent';
import { format } from 'date-fns';
import { WidgetProps } from '../../../models/WidgetProps';
import PreviousDeliveryChart from './PreviousDeliveryChart';
import PreviousDeliveryTable from './PreviousDeliveryTable';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';

const PreviousDeliveryWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{ lastDeliveries, loadLastDeliveries, loadingLastDeliveries},userStore:{currentShop}} = useStore();
    useEffect(()=>{
      if(currentShop)
        if(!lastDeliveries)
          loadLastDeliveries();
    },[lastDeliveries, loadLastDeliveries, currentShop])
  return (
    <Widget 
    width={width} 
    noBorder={false} 
    heading={heading}
    helpText={helpText}
    extraHeading={
    <div className='widget-extra-heading'>
        <span>Delivery No: {(lastDeliveries && lastDeliveries?.orders?.length > 0) ? lastDeliveries.deliveryNo > 0 ? lastDeliveries.deliveryNo : 'N/A' : 'N/A'}</span>
        <span>Delivery Date: {(lastDeliveries && lastDeliveries?.orders?.length > 0) ? format(new Date(lastDeliveries?.deliveryDate),"dd/MM/yyyy") : ('N/A')}</span>
    </div>}
    headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
    >
            { loadingLastDeliveries && (<LoadingComponent message='Loading data... please wait!' />)}
            { menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'previous_delivery_chrt':
                        stack.push(<PreviousDeliveryChart key={item.action} title={item.name} />);
                        break;
                    case 'previous_delivery_tab':
                        stack.push(<PreviousDeliveryTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                        break;
                }
                return stack;
            })}
    </Widget>
  )
}

export default observer(PreviousDeliveryWidget);