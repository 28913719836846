import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DeliveryOrder } from '../../../models/DeliveryOrder';
import {Colors} from '../../../constants/Colors';
import { barOptions } from '../../../constants/ChartConstants';

const PreviousDeliveryChart = ({title}:{title: string}) => {
    const {hotelPortalStore:{ lastDeliveries, loadingLastDeliveries}} = useStore();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(lastDeliveries?.orders){
            setBarData({
                labels: Array.from(new Set(lastDeliveries.orders.map((s: DeliveryOrder) => s.name))),
                datasets:[
                    {
                        label: 'Items delivered',
                        data: Array.from(new Set(lastDeliveries.orders.map((s: DeliveryOrder) => s.quantity))),
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                ]

            })
        }
    },[lastDeliveries?.orders])
  return (
        <>
            {!loadingLastDeliveries && !!lastDeliveries && lastDeliveries?.orders?.length > 0 && !!barData && (
               <h2 className='collapsable-container-title'>{title}</h2>)}
            {!loadingLastDeliveries && !!lastDeliveries && lastDeliveries?.orders?.length > 0 && !!barData && (<Bar options={barOptions} data={barData!} />) }
        </>
  )
}

export default observer(PreviousDeliveryChart);