import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store'
import StockCirculationGrid from './StockCirculationGrid';
import CollapsableContainer from '../../common/CollapsableContainer';
import { TableWidgetProps } from '../../../models/WidgetProps';
import { StockCirutlationTableItem } from '../../../models/StockCirculationData';
import { observer } from 'mobx-react-lite';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';

const StockCirculationTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {commonStore:{isDesktop,isMobile},hotelPortalStore:{stockCirculationData,loadingStockCirculationData}} = useStore();
    const [evenStock, setEvenStock] = useState<StockCirutlationTableItem[]>([]);
    const [oddStock, setOddStock]  = useState<StockCirutlationTableItem[]>([]);
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();

    useEffect(() => {
        if(stockCirculationData){
            var arr = [...stockCirculationData.data];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenStock(even);
            setOddStock(odd);
            setExportProps({
              data: stockCirculationData.data,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Last Seen < 30 days', key: 'lessThan30'},
                  {label: 'Last Seen between 30 and 60 days', key: 'between30and60'},
                  {label: 'Last Seen between 60 and 90 days', key: 'between60and90'},
                  {label: 'Last Seen > 90 days', key: 'greaterThan90'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[stockCirculationData])
  return (
      <>
    {!loadingStockCirculationData && stockCirculationData && stockCirculationData.data.length > 0 && evenStock && oddStock && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
        {stockCirculationData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <StockCirculationGrid stock={evenStock} />
                <StockCirculationGrid stock={oddStock} />
            </div>
        )}
        {stockCirculationData && isMobile && (
            <StockCirculationGrid stock={stockCirculationData.data} />
        )}        
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(StockCirculationTable)