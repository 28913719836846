import React, { useEffect, useState } from 'react'
import { WidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import Widget from '../../common/Widget';
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from '../../common/DatePickerCustomInput';
import LoadingComponent from '../../common/LoadingComponent';
import { observer } from 'mobx-react-lite';
import SoiledLinenComparisionChart from './SoiledLinenComparisionChart';
import SoiledLinenComparisionTable from './SoiledLinenComparisionTable';
import { addDays } from 'date-fns';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';

const SoiledLinenComparisionWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{soiledLinenComparisionData,loadSoiledLinenComparisionData,loadingSoiledLinenComparisionData},userStore:{currentShop}} = useStore();
    const [forDate, setForDate] = useState<Date>(addDays(new Date(),-1));
    useEffect(()=>{
        if(currentShop)
        if(!soiledLinenComparisionData)
            loadSoiledLinenComparisionData(forDate);
    },[soiledLinenComparisionData,loadSoiledLinenComparisionData,currentShop]);
    
    const handleChangeForDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setForDate(new Date(date));
            loadSoiledLinenComparisionData(date);
        }
    }
  return (
    <Widget 
        width={width} 
        noBorder={false} 
        heading={heading}
        helpText={helpText}
        extraHeading={
            <div className='widget-extra-heading'>
                <label>Select Date</label>
                <DatePicker 
                    name="forDate"
                    dateFormat="dd/MM/yyyy"
                    selected={forDate}
                    onChange={handleChangeForDate}
                    showMonthDropdown
                    showYearDropdown 
                    dropdownMode="select"
                    todayButton="Today"
                    customInput={React.createElement(DatePickerCustomInput)}
                    />
            </div>
        }
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
        >
        <>
        {loadingSoiledLinenComparisionData && <LoadingComponent message='Loading data... please wait!' />}
        { menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'soilout_rep_chrt':
                        stack.push(<SoiledLinenComparisionChart key={item.action} title={item.name} />);
                        break;
                    case 'soilout_tab':
                        stack.push(<SoiledLinenComparisionTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                        break;
                }
                return stack;
        })}
        </>
        </Widget>
  )
}

export default observer(SoiledLinenComparisionWidget)