
import { useState } from 'react';
interface IProps{
    captions: string[];
    onClick: (id: number) => void;
}
const MisusedStockReportFilter : React.FC<IProps> = ({captions,onClick}) => {
    const [selectedButton, setSelectedButton] = useState(0)
    const handleButtonClick = (id: number) => {
        setSelectedButton(id);
        onClick(id);
    }
  return (
    <div className='report-button-group'>
        {captions.map((caption, index) => (
            <button key={index} className={`btn btn-default ${index === selectedButton ? 'selected' : ''}`} onClick={()=>handleButtonClick(index)} >{caption}</button>
        ))}
    </div>
  )
}

export default MisusedStockReportFilter