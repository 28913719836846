import { observer } from "mobx-react-lite";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import InfoBox from "./Form/InfoBox";
import WidgetFormFooter from "./WidgetFormFooter";
import HelpTextIcon from "./HelpTextIcon";
import HelpTextDialog from "./HelpTextDialog";
import { Tooltip, TooltipRefProps } from "react-tooltip";


interface Props{
    width: number;
    noBorder: boolean;
    heading?: string;
    extraHeading?: ReactNode;
    headerItems?: ReactNode;
    children?: ReactNode;
    hasFooter?: boolean;
    showInfoBox?: boolean;
    message?: string;
    success?: boolean;
    defaultDisabled?: boolean;
    resetFunction?: () => void;
    helpText?: string;
}
export default observer(function Widget( { width,noBorder,heading,extraHeading,children, hasFooter, showInfoBox, message, success, defaultDisabled, resetFunction, helpText, headerItems } : Props){
    const [showHelpDialog, setShowHelpDialog] = useState(false);
    const toolTipRef = useRef<TooltipRefProps>();
    let widgetWidth;
    switch(width){
        case 1:
            widgetWidth='width-25';
            break;
        case 2:
            widgetWidth='width-50';
            break;
        case 3:
            widgetWidth='width-75';
            break;
        case 4:
            widgetWidth='width-100';
            break;
    }
        if(helpText)
            while(helpText.includes('//'))
                helpText = helpText.replace('//','<br />')
    return (
        <>
            <div className={`widget ${widgetWidth} ${noBorder?'widget-no-border':''}`}>
                {showInfoBox && (<InfoBox message={message || ''} success={success || false} handleOK={resetFunction} />)}
                {heading && (
                <div className="widget-header">
                    <h2>{heading}{helpText && helpText.length > 0 && (
                        <HelpTextIcon toolTipId="widgetHelpTextTip" toolTipRef={toolTipRef} helpText={helpText} showDialog={setShowHelpDialog} />
                    )}</h2>{!!extraHeading && extraHeading}{!!headerItems && headerItems}
                </div>
                )}
                <div className="widget-body">
                {children && children}
                </div>
                {hasFooter && (<WidgetFormFooter defaultDisabled={defaultDisabled} resetFunction={resetFunction} />)}
            </div>
             {showHelpDialog && helpText && <HelpTextDialog message={helpText} handleClose={()=>setShowHelpDialog(false)} />}
             <Tooltip id='widgetHelpTextTip' style={{backgroundColor: '#d4e2f5', color: 'black'}} />
        </>
    );
})