import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store'
import CollapsableContainer from '../../common/CollapsableContainer';
import { TableWidgetProps } from '../../../models/WidgetProps';
import { RejectStockAnalysisEntity } from '../../../models/RejectStockAnalysisData';
import RejectStockAnalysisGrid from './RejectStockAnalysisGrid';
import { observer } from 'mobx-react-lite';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';


const RejectStockAnalysisTable = ({title,collapsable,helpText}:TableWidgetProps) => {
  const {commonStore:{isDesktop,isMobile},hotelPortalStore:{rejectStockData, loadingRejectStockData}} = useStore();
  const [evenReject, setEvenReject] = useState<RejectStockAnalysisEntity[]>([]);
  const [oddReject, setOddReject]  = useState<RejectStockAnalysisEntity[]>([]);
  const [rejectTotal, setRejectTotal] = useState<RejectStockAnalysisEntity|undefined>()
  const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>(); 
 
  useEffect(() => {
        if(rejectStockData){
          let totalUsage = 0; let totalRejected = 0;
            rejectStockData.tableData.forEach(data =>{
                totalUsage+=data.usageQty;
                totalRejected+=data.rejectedQty;
            })
            var arr = [...rejectStockData.tableData];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setRejectTotal({itemCode:'Total',itemName:'TOTAL',usageQty:totalUsage,rejectedQty:totalRejected});
            setEvenReject(even);
            setOddReject(odd);
            setExportProps({
              data: rejectStockData.tableData,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Usage', key: 'usageQty'},
                  {label: 'Rejected', key: 'rejectedQty'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[rejectStockData])
  return (
    <>
      {!loadingRejectStockData && rejectStockData && rejectStockData.tableData.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
          {rejectStockData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <RejectStockAnalysisGrid details={evenReject} totals={rejectTotal} />
                <RejectStockAnalysisGrid details={oddReject} />
            </div>
        )}
        {!loadingRejectStockData && rejectStockData && rejectStockData?.tableData?.length > 0 && isMobile && (
            <RejectStockAnalysisGrid details={rejectStockData.tableData} totals={rejectTotal} />
        )}   
        </CollapsableContainer>
      )}
    </>
  )
}

export default observer(RejectStockAnalysisTable);