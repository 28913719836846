import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";
import React, {useEffect} from "react";
import LaundryStockRow from "../components/LaundryStockReport/LaundryStockRow";
import LoadingComponent from "../components/common/LoadingComponent";
export default observer(function LaundryStockReportPage(){
    const {commonStore,hotelPortalStore: { loadLaundryStockSummary, loadingReport, stockSummaryReport}} = useStore();
    useEffect(() => {
        commonStore.setPreviousRoute(window.location.pathname);
        loadLaundryStockSummary();
    }, [])

    return(
        <>
        <h2 className='page-header'>Laundry Stock Status</h2>
        {loadingReport ? (
            <LoadingComponent message='Loading report... please wait!' />
        ) : (
        <div className='data-grid'>
            <div className='data-grid-row header-row'>
                <div className='data-grid-col-sm'>Stock Code</div>
                <div className='data-grid-col-lg'>Stock Name</div>
                <div className='data-grid-col-md'>Received in Laundry</div>
                <div className='data-grid-col-sm'>Dispatched</div>
                <div className='data-grid-col-md'>Stock with Laundry</div>
                <div className='data-grid-col-sm'>Details</div>
            </div>
            {
                stockSummaryReport && stockSummaryReport.map((s,i) => (
                    <LaundryStockRow key={i} row={s} />
                ))
            }
        </div>

        )}
        </>
    )
})