import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import { pieOptions, stackedBarOptions } from '../../../constants/ChartConstants';
import { Colors } from '../../../constants/Colors';

const StockCirculationChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{stockCirculationData,loadingStockCirculationData}} = useStore();
    const [pieData, setPieData] = useState<ChartData<"pie">>();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(stockCirculationData){
            setPieData({
                labels: stockCirculationData.summary.labels,
                datasets: [
                    {
                        label: 'last seen',
                        data: stockCirculationData.summary.data,
                        backgroundColor: [
                            `${Colors.PALE_GREEN}`,
                            `${Colors.PALE_AMBER}`,
                            `${Colors.PALE_ORANGE}`,
                            `${Colors.PALE_RED}`,
                        ],
                        borderColor: [
                            `${Colors.PALE_GREEN}`,
                            `${Colors.PALE_AMBER}`,
                            `${Colors.PALE_ORANGE}`,
                            `${Colors.PALE_RED}`,
                        ],
                        borderWidth: 1
                    }
                ]
            })
            setBarData({
                labels: stockCirculationData.details.labels,
                datasets:[
                    {
                        label: 'last seen in the past 30 days',
                        data: stockCirculationData.details.lessThan30,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                        label: 'last seen between 30 and 60 days',
                        data: stockCirculationData.details.between30and60,
                        backgroundColor: `${Colors.PALE_AMBER}`
                    },
                    {
                        label: 'last seen between 60 and 90 days',
                        data: stockCirculationData.details.between60and90,
                        backgroundColor: `${Colors.PALE_ORANGE}`
                    },
                    {
                        label: 'last seen more than 90 days ago',
                        data: stockCirculationData.details.greaterThan90,
                        backgroundColor: `${Colors.PALE_RED}`
                    },
                ]

            })
        }
    },[stockCirculationData])
  return (
    
        <>
            {!loadingStockCirculationData && !!stockCirculationData 
                && (stockCirculationData.details.lessThan30.length > 0 || stockCirculationData.details.between30and60.length > 0 ||
                    stockCirculationData.details.between60and90.length > 0 || stockCirculationData.details.greaterThan90.length > 0)  && !!barData && !!pieData && title.length > 0 && (
                    <h2 className='collapsable-container-title'>{title}</h2>)}        
            {!loadingStockCirculationData && !!stockCirculationData 
                && (stockCirculationData.details.lessThan30.length > 0 || stockCirculationData.details.between30and60.length > 0 ||
                    stockCirculationData.details.between60and90.length > 0 || stockCirculationData.details.greaterThan90.length > 0) && !!barData && !!pieData  && (
              <div className='graph-container'>
                        <div className="width-75">
                            <Bar options={stackedBarOptions} data={barData!} />
                        </div>
                        <div className="width-25 ml-1">
                            <Pie options={pieOptions} data={pieData!} />
                        </div>
              </div>
            ) 
            }
        </>
    
  )
}

export default observer(StockCirculationChart);