import { observer } from "mobx-react-lite";
import React from "react";
import DatePicker from 'react-datepicker';
interface Props{
    fromDate: Date;
    toDate: Date;
    setFromDate: (value: Date) => void;
    setToDate: (value: Date) => void;
    applyFilter: () => void;
    onPrint?: () => void;
    printDisabled?: boolean
}
export default observer(function ReportDateFilterBox({fromDate, toDate, setFromDate, setToDate, applyFilter, onPrint, printDisabled}:Props){
    const handleFromDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date)
            setFromDate(new Date(date));
    }
    const handleToDate = (date: Date | null, event: React.SyntheticEvent<any, Event>) => {
        if(date)
            setToDate(new Date(date));
    }
    return(
        <div className='report-filter-box'>
            <div className='report-filter'>
                <div className='report-filter-col'>
                <label>Date From</label>
                <DatePicker 
                    name="fromDate"
                    dateFormat="dd/MM/yyyy"
                    selected={fromDate}
                    onChange={handleFromDate}
                    showMonthDropdown
                    showYearDropdown 
                    dropdownMode="select"
                    todayButton="Today"
                    />
                </div>
                <div className='report-filter-col'>
                <label>Date To</label>
                <DatePicker 
                    name="toDate"
                    dateFormat="dd/MM/yyyy"
                    selected={toDate}
                    onChange={handleToDate}
                    showMonthDropdown
                    showYearDropdown 
                    dropdownMode="select"
                    todayButton="Today"
                    />
                </div>
            </div>
            <button className='btn btn-default' onClick={ (event) => { event.preventDefault(); applyFilter(); }} >Apply Filter</button>
            {onPrint && (
                <button disabled={printDisabled} className='btn btn-default' onClick={ (event) => { event.preventDefault(); onPrint(); }} >Print</button>
            )}
        </div>
    )
})