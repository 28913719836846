import { observer } from "mobx-react-lite";
import React from "react";
import { LinenStockReportSummary } from "../../../models/LinenStockReportSummary";
import LinenSummaryReportRow from "./LinenSummaryReportRow";

interface Props{
    qtyLaundryLabel: string;
    qtyHotelLabel: string;
    dataTable: LinenStockReportSummary[];
}
export default observer(function LinenSummaryReport({qtyLaundryLabel,qtyHotelLabel,dataTable}:Props){
    return(
        <div className='data-grid'>
            <div className='data-grid-row header-row'>
                <div className='data-grid-col-code'><span>Stock Code</span></div>
                <div className='data-grid-col-name'><span>Stock Name</span></div>
                <div className='data-grid-col-qty'><span>{qtyLaundryLabel}</span></div>
                <div className='data-grid-col-qty'><span>{qtyHotelLabel}</span></div>
                <div className='data-grid-col-code'><span>Details</span></div>
            </div>
            { dataTable && dataTable.length > 0 ? (
                dataTable.map(row => (
                    <LinenSummaryReportRow qtyLaundryLabel={qtyLaundryLabel} qtyHotelLabel={qtyHotelLabel} dataRow={row} />
                ))
            ) : (
                <div className='data-grid-row'>
                    No records found.
                </div>
            )}
        </div>
    )
});