import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import ForgotPasswordBox from "../components/common/ForgotPasswordBox";
import LoadingComponent from "../components/common/LoadingComponent";
import LoginBox from "../components/common/LoginBox";
import { useStore } from "../stores/store";
export default observer(function HomePage(){
    const {commonStore, userStore} = useStore();
    const {laundryUID, currentLaundry, checkLaundryUID, setAppLoaded, invalidLaundryCode, validLaundryCode, setInvalidLaundryCode, appLoaded, previousRoute, userModules} = commonStore;
    const {isLoggedIn, showForgotPassword, currentShop} = userStore;
    const {id} = useParams<{id: string}>();
    useEffect(() => {
        if(id){
            if(!laundryUID || laundryUID !== id)
                checkLaundryUID(id);
            else{
                setAppLoaded();
            }
                
        } else {
            setAppLoaded();
        }
    }, [id])
    useEffect(() => {
    if(!validLaundryCode){
      setInvalidLaundryCode();
      setAppLoaded();
    }
  },[validLaundryCode])
  useEffect(() =>{
    if(currentLaundry)
      setAppLoaded();
  },[currentLaundry])

    if(!appLoaded) return <LoadingComponent message='Loading app... please wait!' />
    if(invalidLaundryCode) return <Redirect to="/" />;

    if(isLoggedIn){
        if(previousRoute)
            return <Redirect to={previousRoute} />;
        else
                return <Redirect to="/hotelportal" />
    }
    
    return (
        <>
            { showForgotPassword ? (
                <ForgotPasswordBox />   
            ) : (
                <LoginBox />   
            )}
        </>
    )
})