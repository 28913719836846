import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import { ItemLaundryLocationRow } from '../../../models/ItemLocation';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
import CollapsableContainer from '../../common/CollapsableContainer';
import StockLaundryLocationGrid from './StockLaundryLocationGrid';

const StockLaundryLocationTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {commonStore:{isDesktop,isMobile},hotelPortalStore:{stockLaundryLocation,loadingStockLaundryLocation}} = useStore();
    const [evenLoc, setEvenLoc] = useState<ItemLaundryLocationRow[]>([]);
    const [oddLoc, setOddLoc]  = useState<ItemLaundryLocationRow[]>([]);
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();
    useEffect(() => {
        if(stockLaundryLocation){
            var arr = [...stockLaundryLocation.data];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenLoc(even);
            setOddLoc(odd);
            setExportProps({
              data: stockLaundryLocation.data,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'In Processing', key: 'inProcessing'},
                  {label: '@Special Clean', key: 'specialClean'},
                  {label: '@Rewash', key: 'reWash'}
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[stockLaundryLocation])
  return (
    <>
      {!loadingStockLaundryLocation && stockLaundryLocation && stockLaundryLocation.data.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
          {stockLaundryLocation &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <StockLaundryLocationGrid details={evenLoc} />
                <StockLaundryLocationGrid details={oddLoc} />
            </div>
        )}
        {stockLaundryLocation && isMobile && (
            <StockLaundryLocationGrid details={stockLaundryLocation.data} />
        )}   
        </CollapsableContainer>
      )}
    </>
  )
}

export default observer(StockLaundryLocationTable)