
import { MisusedStockTypeRow } from '../../../models/MisusedStockTypeAnalysis'
import { observer } from 'mobx-react-lite'
interface Props{
    row: MisusedStockTypeRow
}
const MisusedTypeGridRow = ({row}:Props) => {
  return (
    <div className='data-grid-row lh-default align-center'>
        <div className='data-grid-col-md data-grid-vlegend'>{row.itemName}</div>
        {row.details.map((d) => (
            <div key={d.typeName} className='data-grid-col-sm'>
                <div>{d.qty}</div>
            </div>
        ))}
    </div>
  )
}

export default observer(MisusedTypeGridRow)