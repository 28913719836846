import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store'
import CollapsableContainer from '../../common/CollapsableContainer';
import { TableWidgetProps } from '../../../models/WidgetProps';
import StockLocationGrid from './StockLocationGrid';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { ItemLocationRow } from '../../../models/ItemLocation';
const StockLocationTable = ({title,collapsable,helpText}:TableWidgetProps) => {
  const {commonStore:{isDesktop,isMobile},hotelPortalStore:{stockLocation,loadingStockLocation}} = useStore();
  const [evenLoc, setEvenLoc] = useState<ItemLocationRow[]>([]);
  const [oddLoc, setOddLoc]  = useState<ItemLocationRow[]>([]);
  const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();
  useEffect(() => {
        if(stockLocation){
            var arr = [...stockLocation.tableData];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenLoc(even);
            setOddLoc(odd);
            setExportProps({
              data: stockLocation.tableData,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: '@Hotel', key: 'hotel'},
                  {label: '@Laundry', key: 'laundry'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[stockLocation])
  
    return (
    <>
      {!loadingStockLocation && stockLocation && stockLocation.tableData.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
          {stockLocation &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <StockLocationGrid details={evenLoc} />
                <StockLocationGrid details={oddLoc} />
            </div>
        )}
        {stockLocation && isMobile && (
            <StockLocationGrid details={stockLocation.tableData} />
        )}   
        </CollapsableContainer>
      )}
    </>
  )
}

export default observer(StockLocationTable)