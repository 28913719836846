import React, { useEffect } from 'react'
import { useStore } from '../../../stores/store'
import Widget from '../../common/Widget';
import LoadingComponent from '../../common/LoadingComponent';
import { observer } from 'mobx-react-lite';
import { WidgetProps } from '../../../models/WidgetProps';
import StockCirculationChart from './StockCirculationChart';
import StockCirculationTable from './StockCirculationTable';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';
import StockCirculationNotSeenTable from './StockCirculationNotSeenTable';

const StockCirculationWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{stockCirculationData,loadStockCirculationData,loadingStockCirculationData,stockCirculationNotSeenData,loadStockCirculationNotSeenData,loadingStockCirculationNotSeenData},userStore:{currentShop}} = useStore();
    useEffect(()=>{
        if(currentShop)
        if(!stockCirculationData)
            loadStockCirculationData();
    },[stockCirculationData,loadStockCirculationData,currentShop])
    useEffect(()=>{
        if(currentShop)
        if(!stockCirculationNotSeenData)
            loadStockCirculationNotSeenData();
    },[stockCirculationNotSeenData,loadStockCirculationNotSeenData,currentShop])

    return (
        <Widget 
        width={width}
        noBorder={false}
        heading={heading}
        helpText={helpText}
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
        >
        {loadingStockCirculationData && <LoadingComponent message='Loading stock... please wait!' />}
        {menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'stk_circ_chrt_det':
                        stack.push(<StockCirculationChart key={item.action} title={item.name} />);
                        break;
                    case 'stk_circ_tab':
                        stack.push(<StockCirculationTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                        break;
                    case 'stk_circ_90_det':
                        stack.push(<StockCirculationNotSeenTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />)
                        break;
                }
                return stack;
            })}
    </Widget>
  )
}

export default observer(StockCirculationWidget);