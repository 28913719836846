import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Colors } from '../../../constants/Colors';
import { Bar } from 'react-chartjs-2';
import { stackedBarOptions } from '../../../constants/ChartConstants';
import { observer } from 'mobx-react-lite';

const StockCheckFrequencyChart = ({title}:{title:string}) => {
    const {hotelPortalStore:{loadingStockCheckFrequency,stockCheckFrequencyData}} = useStore();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(stockCheckFrequencyData){
            setBarData({
                labels: stockCheckFrequencyData.labels,
                datasets:[
                    {
                        label: 'On the shelf for past 30 days',
                        data: stockCheckFrequencyData.lessThan30,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                        label: 'On the shelf between 30 and 60 days',
                        data: stockCheckFrequencyData.between30and60,
                        backgroundColor: `${Colors.PALE_AMBER}`
                    },
                    {
                        label: 'On the shelf between 60 and 90 days',
                        data: stockCheckFrequencyData.between60and90,
                        backgroundColor: `${Colors.PALE_ORANGE}`
                    },
                    {
                        label: 'On the shelf for more than 90 days ago',
                        data: stockCheckFrequencyData.greaterThan90,
                        backgroundColor: `${Colors.PALE_RED}`
                    },
                ]

            })
        }
    },[stockCheckFrequencyData])
  return (
    
        <>
            {!loadingStockCheckFrequency && !!stockCheckFrequencyData && stockCheckFrequencyData.tableData.length > 0 && !!barData && title.length > 0 && (
                <h2 className='collapsable-container-title'>{title}</h2>)}
            {!loadingStockCheckFrequency && !!stockCheckFrequencyData && stockCheckFrequencyData.tableData.length > 0 && !!barData && (
                <Bar options={stackedBarOptions} data={barData!} />
            ) 
            }
        </>
    
  )
}

export default observer(StockCheckFrequencyChart);