import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite'
import * as Yup from 'yup'
import { ContactDetail } from '../../models/ContactDetails';
import { useStore } from '../../stores/store';
import { EmailMessage } from '../../models/EmailMessage';
import Widget from '../common/Widget';
import InputMessageField from '../common/Form/InputMessageField';
import TextAreaMessageField from '../common/Form/TextAreaMessageField';
import Popup from '../common/Popup';
import DisabledOverlay from '../common/DisabledOverlay';
import { MaxRequestMessage } from '../../constants/Messages';
interface Props{
    contact: ContactDetail;
    showDialog: boolean;
    disabled: boolean;
    resetDialog: () => void;
}
const ContactSendMessage = ({contact,showDialog,disabled,resetDialog}:Props) => {
    const {hotelPortalStore:{sendMessage},userStore: {currentShop}} = useStore();
    const messageValue = () => ({locationUID:'', name: '',subject:'',message: '',accountCode:'',contactId:''});
    const validationSchema = Yup.object({
        name: Yup.string().required().min(3).max(50),
        subject: Yup.string().required().min(5).max(25),
        message: Yup.string().required().min(5).max(500)
    });
    const handleSubmit = (values: EmailMessage) =>{
        sendMessage({...values, accountCode: currentShop!.accountCode,contactId: contact.contactId});
        resetDialog();
    }
  return (
    <Popup showDialog={showDialog}>
        <div className='minwidth-50'>
            <Formik
            initialValues={messageValue()}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit} 
                >{({handleSubmit, resetForm, isSubmitting, touched, dirty}) => (
                    <Form className='widget-form-wrapper width-100' onSubmit={handleSubmit}>
                        <Widget 
                            width={4} 
                            noBorder={false} 
                            heading={`Send Message to ${contact.contactName}`} 
                            hasFooter={true} 
                            defaultDisabled={disabled || (isSubmitting && dirty && !!touched)}
                            resetFunction={resetDialog}
                            >
                            {disabled && (<DisabledOverlay message={MaxRequestMessage} showClose={true}  />)}
                            <div className='widget-form'>
                                <InputMessageField name='name' fieldLabel='Name' placeholder='Your Name' disabled={isSubmitting && dirty && !!touched} />
                                <InputMessageField name='subject' fieldLabel='Subject' placeholder='Subject' disabled={isSubmitting && dirty && !!touched} />
                                <TextAreaMessageField name='message' fieldLabel='Message' placeholder='Your message...' fieldRows={8} disabled={isSubmitting && dirty && !!touched} />
                            </div>
                        </Widget>
                    </Form>
                )}
            </Formik>
        </div>
    </Popup>
  )
}

export default observer(ContactSendMessage);