import { observer } from 'mobx-react-lite'
import React from 'react'
import { StockReconcilationEntity } from '../../../models/StockReconcilation';
interface Props{
    stocks: StockReconcilationEntity[];
}
const SoiledLinenComparisionGrid = ({stocks}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row item-header-with-3-col heading-row">
              <div>Item Name</div>
              <div>Stock Sent by Hote</div>
              <div>Scanned @Laundry</div>
              <div>% Scanned @Hotel</div>
          </div>
          {stocks.map((stock) => (
            <div key={stock.itemCode} className='data-grid-two-col-row item-header-with-3-col'>
              <div>{stock.itemName}</div>
              <div>{stock.stockDelivered}</div>
              <div>{stock.stockScanned}</div>
              <div>{stock.scannedPercent.toFixed(2)}</div>
            </div>
          ))}
    </div>
  ) 
}

export default observer(SoiledLinenComparisionGrid)