import React from 'react'
import { DeliveryOrder } from '../../models/DeliveryOrder'
interface Props{
    orders: DeliveryOrder[];
}
const DeliveryGrid = ({orders}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row item-header-with-1-col heading-row">
            <div>Item Name</div>
            <div>Quantity</div>
        </div>
        {orders.map(order =>(
            <div key={order.code} className="data-grid-two-col-row item-header-with-1-col">
                <div>{order.name}</div>
                <div>{order.quantity}</div>
            </div>
        ))}
    </div>)
}

export default DeliveryGrid