import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useState } from "react";
interface Props{
    captions: string[];
    onClick: (id: number, reconciled: boolean) => void;
}

export default observer(function CustomerStockReportFilterbox({captions,onClick}:Props){
    const [selectedButton, setSelectedButton] = useState(0)
    const [reconciled, setReconciled] = useState(false);
    const handleChecked = (event: ChangeEvent<HTMLInputElement>) => {
        setReconciled(event.target.checked);
        onClick(selectedButton,event.target.checked);
    }
    const handleButtonClick = (id: number) => {
        setSelectedButton(id);
        onClick(id,reconciled);
    }
    return(
        <div className='report-button-group'>
                {captions.map((caption, index) => (
                    <button key={index} className={`btn btn-default ${index === selectedButton ? 'selected' : ''}`} onClick={()=>handleButtonClick(index)} >{caption}</button>
                ))}
                <input id='reconciled' className='report-filter-check' type='checkbox' onChange={handleChecked} checked={reconciled} />
                <label className='report-filter-label' htmlFor='reconciled'>Do not show reconciled</label>
        </div>
    )
})