import { observer } from "mobx-react-lite";
import React from "react";
import { StockReportEntity } from "../../../models/StockReportSummary";
import StockDetailRow from "./StockDetailRow";

interface Props{
    qtyLabel: string;
    dataTable: StockReportEntity[];
}
export default observer(function StockDetailsReport({qtyLabel,dataTable}:Props){
    return(
        <div className='data-grid'>
            <div className='data-grid-row header-row'>
                <div className='data-grid-col-code'><span>Stock Code</span></div>
                <div className='data-grid-col-name'><span>Stock Name</span></div>
                <div className='data-grid-col-code'><span>Date</span></div>
                <div className='data-grid-col-qty'><span>{qtyLabel}</span></div>
                <div className='data-grid-col-code'><span>Details</span></div>
            </div>
            { dataTable && dataTable.map(row => (
                    <StockDetailRow dataRow={row} />
                ))
            }
        </div>
    )
});