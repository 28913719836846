import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import StockCheckGrid from './StockCheckGrid';
import { observer } from 'mobx-react-lite';
import { CSVLink } from 'react-csv';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
import { FaFileCsv } from "react-icons/fa6";
import LoadingComponent from '../../common/LoadingComponent';
const StockCheckSummary = () => {
  const {hotelPortalStore:{stockCheckData,loadingstockCheckData}} = useStore();
  const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();
  useEffect(()=>{
    if(stockCheckData)
      setExportProps({
              data: stockCheckData.summary,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Scanned', key: 'scanned'},
                  {label: 'On the shelf for less than 30 days', key: 'lessThan30'},
                  {label: 'On the shelf between 30 and 60 days', key: 'between30and60'},
                  {label: 'On the shelf between 60 and 90 days', key: 'between60and90'},
                  {label: 'On the shelf for more than 90 days', key: 'greaterThan90'},
                ],
              filename: `$Stock Check Summary_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
  },[stockCheckData])
  return (
    <>
      {loadingstockCheckData && <LoadingComponent message='Loading data...' />}
      {!loadingstockCheckData && stockCheckData && stockCheckData?.summary?.length > 0 && (
      <div className='collapsable-container'>
              <StockCheckGrid details={stockCheckData.summary} lastSeenHeading='On the shelf for (days)' />
              {exportProps && <CSVLink style={{float: 'right',marginRight: '0.75rem'}} {...exportProps}><FaFileCsv title='Export to CSV' style={{marginTop: '0.5rem'}} size={22} /></CSVLink>}
      </div>)}
    </>
  )
}

export default observer(StockCheckSummary)