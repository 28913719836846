import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { MisusedStockReportSummary } from '../../models/MisusedStockReportSummary';
interface IProps{
    row: MisusedStockReportSummary
}
const MisusedStockRow : React.FC<IProps> = ({row}) => {
    const [showDetails,setShowDetails] = useState(false);
  return (
    <>
        <div className='data-grid-row'>
            <div className='data-grid-col-sm'>&nbsp;{row.stockCode}</div>
            <div className='data-grid-col-lg'>{row.stockName}</div>
            <div className='data-grid-col-sm'>{row.qty}</div>
            <div className='data-grid-col-md'>{row.stockType}</div>
            <div className='data-grid-col-sm'><span className='data-toggle' onClick={() => setShowDetails(!showDetails)} >Details</span></div>
        </div>
        {showDetails && (
            <div className="data-grid-row">
            <div className='data-grid'>
                <div className='data-grid-row header-row'>
                    <div className='data-grid-col-sm'>Stock Code</div>
                    <div className='data-grid-col-lg'>Stock Name</div>
                    <div className='data-grid-col-md'>Item Barcode</div>
                    <div className='data-grid-col-md'>Details</div>
                    <div className='data-grid-col-sm'>Date</div>
                </div>
                {row.details.map((drow,i) => (
                            <div key={i} className='data-grid-row'>
                                <div className='data-grid-col-sm'>{drow.stockCode}</div>
                                <div className='data-grid-col-lg'>{drow.stockName}</div>
                                <div className='data-grid-col-md'>{drow.itemBarcode}</div>
                                <div className='data-grid-col-md'>{drow.stockDetails}</div>
                                <div className='data-grid-col-sm'>{format(new Date(drow.stockDate),"dd/MM/yyyy")}</div>
                            </div>
                ))}
            </div>
            </div>
            )}
    </>
  )
}

export default observer(MisusedStockRow);