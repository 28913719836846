import { labelCallback, labelCallbackOnlyPercent } from "../utils/pieChartLabelCallback";

export const barOptions = {
  plugins: {
    title: {
      display: false,
    },
  },
  maxBarThickness: 50,
  responsive: true,
  aspectRatio: 3,
};

export const stackedBarOptions = {
  plugins: {
    title: {
      display: false,
    },
  },
  responsive: true,
  maxBarThickness: 50,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export const pieOptions = {
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        tooltip: {
          callbacks: {
            label: labelCallback,
          }
        }
    },
    responsive: true,
    maintainAspectRatio: false,
 };

 export const pieOptionOnlyPercent = {
      plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false
        },
        tooltip: {
          callbacks: {
            label: labelCallbackOnlyPercent,
          }
        }
    },
    responsive: true,
    maintainAspectRatio: false,
 };
 
