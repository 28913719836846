import React from "react";
import { useField } from "formik";
import DatePicker,{ReactDatePickerProps} from 'react-datepicker';

export default function InputDateField(props: Partial<ReactDatePickerProps>){
    const [field,meta,helpers] = useField(props.name!);
    return (
        <DatePicker 
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={value => helpers.setValue(value)}        
        />
    )
}