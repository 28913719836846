import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import { LinenCageComparisionTableItem } from '../../../models/LinenCageComparisionDto';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
import CollapsableContainer from '../../common/CollapsableContainer';
import SoiledLinenCageComparisionGrid from './SoiledLinenCageComparisionGrid';
import { observer } from 'mobx-react-lite';

const SoiledLinenCageComparisionTable = ({title,collapsable,helpText}:TableWidgetProps) => {
   const {commonStore:{isDesktop,isMobile},hotelPortalStore:{soiledLinenCageComparisionData,loadingSoiledLinenCageComparisionData}} = useStore();
    const [evenStock, setEvenStock] = useState<LinenCageComparisionTableItem[]>([]);
    const [oddStock, setOddStock]  = useState<LinenCageComparisionTableItem[]>([]);
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();

     useEffect(() => {
        if(soiledLinenCageComparisionData){
            var arr = [...soiledLinenCageComparisionData.data];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenStock(even);
            setOddStock(odd);
            setExportProps({
              data: soiledLinenCageComparisionData.data,
              headers: [
                  {label: 'Date', key: 'cageDate'},
                  {label: 'Scanned @Hotel', key: 'scannedAtHotel'},
                  {label: 'Scanned @Laundry', key: 'scannedAtLaundry'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[soiledLinenCageComparisionData])
    
    return (
    <>
    {!loadingSoiledLinenCageComparisionData && soiledLinenCageComparisionData && soiledLinenCageComparisionData.data.length > 0 && evenStock && oddStock && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
        {soiledLinenCageComparisionData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <SoiledLinenCageComparisionGrid cages={evenStock} />
                <SoiledLinenCageComparisionGrid cages={oddStock} />
            </div>
        )}
        {soiledLinenCageComparisionData && isMobile && (
            <SoiledLinenCageComparisionGrid cages={soiledLinenCageComparisionData.data} />
        )}        
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(SoiledLinenCageComparisionTable);