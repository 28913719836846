import { ErrorMessage, Form, Formik, FormikErrors } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { FaKey, FaUser } from 'react-icons/fa';
import { useStore } from '../../stores/store';
import InputGroup from './Form/InputGroup';
import * as Yup from 'yup';
import { LoginFormValues } from '../../models/User';
import { MD5 } from 'crypto-js'
import logo from '../../images/LAU00111006202113005655900001_Logo.jpg';
export default observer(function LoginBox(){
    const {commonStore, userStore} = useStore()
    const { currentLaundry } = commonStore;
    const {login, setShowForgotPassword, prefilledUserEmail} = userStore;
    const initValues = () => ({email:prefilledUserEmail, password:'', error: null});
    const validationSchema = Yup.object({
        email: Yup.string().email().required().min(10).max(50),
        password: Yup.string().required().min(5).max(25)
    }) 
    useEffect(()=>{

    })
    const onSubmit = (values: LoginFormValues, setErrors: (errors: FormikErrors<LoginFormValues>) => void) =>{
        return login({
            emailId: values.email,
            passwordHash: MD5(values.password).toString().toUpperCase(),
            laundryCode: currentLaundry?.laundryCode || '',
            webAppKey: currentLaundry?.webAppKey || ''
        }).catch((err) => setErrors({error: commonStore.getErrorMessage(err)}));
    }
    return(
        <div className="login-wrapper">
        <div className="logo">
            <img src= {`${currentLaundry?.appDocUrl}/Logo/${currentLaundry?.logo}`} alt={currentLaundry?.laundryName} />
            {/* <img src= {logo} alt={currentLaundry?.laundryName} /> */}
            <h1 className="logo-title">{currentLaundry?.showLaundryName &&  (currentLaundry?.laundryName || 'My Laundry')}</h1>
        </div>
        <div className="dialog-box login-box">
            <div className="login-box-title">
                <h2>Sign In</h2>
            </div>
            <Formik
                initialValues={initValues()}
                onSubmit={(values,{setErrors}) => onSubmit(values,setErrors)}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {({handleSubmit, isSubmitting, dirty, touched, errors}) =>  (
                    <>
                        <Form onSubmit={handleSubmit}>
                            <InputGroup name='email' placeholder='Email Id' type='email' icon={<FaUser />} disabled={isSubmitting && dirty && !!touched}/>
                            <InputGroup name='password' placeholder='Password' type='password' icon={<FaKey />} disabled={isSubmitting && dirty && !!touched}/>
                            <ErrorMessage name='error' render={() => <span className='input-error'>{errors.error}</span>} />
                            <button type="submit" className="btn-fullwidth btn-default mb-20" disabled={isSubmitting && dirty && !!touched}>Sign In</button>
                        </Form>
                        <a href='#' 
                        onClick={(e) => {
                            e.preventDefault();
                            setShowForgotPassword();
                        }}>Forgot Password</a>
                    </>
                )}
            </Formik>
        </div>
    </div>
    )
});