import { observer } from 'mobx-react-lite';
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useStore } from '../../stores/store';
import LoadingComponent from '../common/LoadingComponent';

export default observer(function Navbar() {
    const {commonStore:{userModules,docLoaded,docManuals,contactDetails,laundryUID},userStore:{setShowChangeAccount,user,canChangeAccount,currentShop,logOff}} = useStore();
    
    if(userModules.length === 0 || !docLoaded)
        return <LoadingComponent message='Loading menus...please wait!' />
    return (
        <>
        <input type="checkbox" id="navbar-toggler" className="navbar-toggler" />
        <nav>
            <ul className="navbar-menu-main">
                {currentShop?.isLicenseValid && userModules.map((um,i) => (<li key={`${um.action}_${i}`}><NavLink to={um.action}>{um.name}</NavLink></li>))}
                {docLoaded && docManuals.length > 0 && (
                    <li key={'laundry_manuals'}><NavLink to="/manuals">Laundry Manuals</NavLink></li>)}
                {contactDetails.length > 0 && (
                    <li key={'contact_details'}><NavLink to="/contacts">Contact Details</NavLink></li>)}
                <li className="nav-dropdown">
                    {user && <span>{user.username} ({currentShop?.shopNo}-{currentShop?.shopName})</span>}
                    <div className="navbar-menu-sub">
                        <ul>
                            {canChangeAccount && (
                                <li key='change_ac'><a href='#' onClick={()=>setShowChangeAccount()}>Change Account</a></li>
                            )}
                            <li><NavLink to="/change-password">Change Password</NavLink></li>
                            <li><NavLink to="/technical-support">Technical Support</NavLink></li>
                            <li>
                                <NavLink to={`/${laundryUID}`} onClick={logOff} >Sign Out</NavLink>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>
        <label htmlFor="navbar-toggler" className="navbar-hamburger">
            <span></span>
        </label>
    </>
    )
})
