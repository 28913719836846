import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import StockForm from "../components/common/Form/StockForm";
import LoadingComponent from "../components/common/LoadingComponent";
import { DeliveryOrder } from "../models/DeliveryOrder";
import { useStore } from "../stores/store";

export default observer(function RequestStockPage(){
    const {commonStore: {setPreviousRoute,addStockRequestCount},userStore:{user}, hotelPortalStore: { loadRequestStockList, loadingStockList, sendStockRequest }} = useStore();
    useEffect(() => {
        setPreviousRoute(window.location.pathname);
        loadRequestStockList();        
    }, [])
    const handleSubmit = async (values: {stocks: DeliveryOrder[]}) => {
        await sendStockRequest(values.stocks);
    }
    return (
        <>
                <h2 className='page-header'>Request Additional Stock</h2>
                { loadingStockList ? 
                <LoadingComponent message='Loading stock list... please wait!' />
                :
                <StockForm handleSubmit={handleSubmit} printReport={false} disabled={!!user && addStockRequestCount >= user.maxRequestsAllowed} />
            }
            </>
        )
})