import { format } from "date-fns";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ApiResponseStatus } from "../models/ApiResponse";
import { LinenStockReportSummary } from "../models/LinenStockReportSummary";
import { StockReportSummary } from "../models/StockReportSummary";
import { store } from "./store";

export default class ReportsStore{
    loadingReport = false;
    stockReport: StockReportSummary[] | undefined;
    linenReport: LinenStockReportSummary[] | undefined;

    constructor(){
        makeAutoObservable(this);
    }

    setLoadingReport = (value: boolean = true) => {
        this.loadingReport = value;
    }

    clearStockReport = () => {
        this.stockReport = undefined;
    }
    clearLinenReport = () => {
        this.linenReport = undefined;
    }

    resetAll(){
        runInAction(()=>{
            this.loadingReport = false;
            this.stockReport = undefined;
            this.linenReport = undefined;
        });
    }

    loadStockTakeReport = async (fromDate: Date, toDate: Date) =>{
        this.setLoadingReport();
        try{
            const response = await agent.Reports.stockTake(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.stockReport = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch(error){
            store.commonStore.addErrorLog("ReportsStore.loadStockTakeReport",error);
            this.clearStockReport();
        } finally {
            this.setLoadingReport(false);
        }
    }

    loadCleanLinenInReport = async (fromDate: Date, toDate: Date) =>{
        this.setLoadingReport();
        try{
            const response = await agent.Reports.linenIn(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.linenReport = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch(error){
            store.commonStore.addErrorLog("ReportsStore.loadCleanLinenInReport",error);
            this.clearStockReport();
        } finally {
            this.setLoadingReport(false);
        }
    }

    loadSoilLinenOutReport = async (fromDate: Date, toDate: Date) =>{
        this.setLoadingReport();
        try{
            const response = await agent.Reports.linenOut(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.linenReport = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch(error){
            store.commonStore.addErrorLog("ReportsStore.loadSoilLinenOutReport",error);
            this.clearStockReport();
        } finally {
            this.setLoadingReport(false);
        }
    }

    loadStockThroughCustomerReport = async (fromDate: Date, toDate: Date) =>{
        this.setLoadingReport();
        try{
            const response = await agent.Reports.stockPassCustomer(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.stockReport = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch(error){
            store.commonStore.addErrorLog("ReportsStore.loadStockThroughCustomerReport",error);
            this.clearStockReport();
        } finally {
            this.setLoadingReport(false);
        }
    }

    loadStockThroughStaffReport = async (fromDate: Date, toDate: Date) =>{
        this.setLoadingReport();
        try{
            const response = await agent.Reports.stockPassStaff(store.userStore.currentShop!.shopNo,format(new Date(fromDate),"yyyy/MM/dd"),format(new Date(toDate),"yyyy/MM/dd"));
            runInAction(() => (this.stockReport = (response.status === ApiResponseStatus.responseOK) ? response.result : undefined));
        } catch(error){
            store.commonStore.addErrorLog("ReportsStore.loadStockThroughStaffReport",error);
            this.clearStockReport();
        } finally {
            this.setLoadingReport(false);
        }
    }


}