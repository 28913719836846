import format from "date-fns/format";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { StockSummary } from "../../models/StockStatus";
interface Props{
    row: StockSummary;
}
export default observer(function LaundryStockRow({ row }:Props){
    const [showDetails,setShowDetails] = useState(false);
    return (
        <>
        <div className='data-grid-row'>
            <div className='data-grid-col-sm'>{row.code}</div>
            <div className='data-grid-col-lg'>{row.name}</div>
            <div className='data-grid-col-md'>{row.stockOut}</div>
            <div className='data-grid-col-sm'>{row.dispatched}</div>
            <div className='data-grid-col-md'>{row.stockHeld}</div>
            <div className='data-grid-col-sm'><span className='data-toggle' onClick={() => setShowDetails(!showDetails)} >Details</span></div>
        </div>
        {showDetails && (
            <div className="data-grid-row">
            <div className='data-grid'>
                <div className='data-grid-row header-row'>
                    <div className='data-grid-col-sm'>Stock Code</div>
                    <div className='data-grid-col-lg'>Stock Name</div>
                    <div className='data-grid-col-md'>Work in Date</div>
                    <div className='data-grid-col-md'>Received in Laundry</div>
                    <div className='data-grid-col-sm'>Dispatched</div>
                    <div className='data-grid-col-md'>Stock with Laundry</div>
                    <div className='data-grid-col-sm'>Running Total</div>
                </div>
                {row.details.map((drow,i) => (
                            <div key={i} className='data-grid-row'>
                                <div className='data-grid-col-sm'>{drow.code}</div>
                                <div className='data-grid-col-lg'>{drow.name}</div>
                                <div className='data-grid-col-md'>{format(new Date(drow.receivedOn),"dd/MM/yyyy")}</div>
                                <div className='data-grid-col-md'>{drow.stockOut}</div>
                                <div className='data-grid-col-sm'>{drow.dispatched}</div>
                                <div className='data-grid-col-md'>{drow.stockHeld}</div>
                                <div className='data-grid-col-sm'>{drow.runningTotal}</div>
                            </div>
                ))}
            </div>
            </div>
            )}
        </>
    )
})