import { observer } from 'mobx-react-lite'
import React from 'react'
import { StockToReplaceTableRow } from '../../../models/StockToReplaceDto'
interface Props{
  details: StockToReplaceTableRow[]
}
const StockToReplaceGrid = ({details}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className='data-grid-two-col-row item-header-with-4-col heading-row'>
              <div>Item Name</div>
              <div className='text-right pr-25'>Wear-Tear</div>
              <div className='text-right pr-25'>Misused</div>
              <div className='text-right pr-25'>Lost</div>
              <div className='text-right pr-25'>Totals</div>
          </div>
          {details.map((detail) => (
            <div key={detail.itemName} className='data-grid-two-col-row item-header-with-4-col'>
              <div>{detail.itemName}</div>
              <div className='text-right pr-25'>{detail.wearTear}</div>
              <div className='text-right pr-25'>{detail.misused}</div>
              <div className='text-right pr-25'>{detail.greaterThan90}</div>
              <div className='text-right pr-25'>{detail.wearTear+detail.misused+detail.greaterThan90}</div>
            </div>
          ))}
    </div>
  )
}

export default observer(StockToReplaceGrid)