import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react'
import Widget from '../../common/Widget';
import { useStore } from '../../../stores/store';
import LoadingComponent from '../../common/LoadingComponent';
import { WidgetProps } from '../../../models/WidgetProps';
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from '../../common/DatePickerCustomInput';
import StockSoilChart from './StockSoilChart';
import StockSoilTable from './StockSoilTable';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';


  
function StockSoilWidget({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) {
    const {hotelPortalStore:{stockSoiledGraphData,loadStockSoiledGraphData,loadingStockSoiledGraphData},userStore:{currentShop}} = useStore()
    const [forDate, setForDate] = useState<Date>(new Date());
    useEffect(()=>{
        if(currentShop)
        if(!stockSoiledGraphData)
            loadStockSoiledGraphData(forDate);
    },[stockSoiledGraphData,forDate,loadStockSoiledGraphData,currentShop])

    const handleChangeForDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setForDate(new Date(date));
            loadStockSoiledGraphData(date);
        }
    }
  return (
    <Widget 
        width={width} 
        noBorder={false} 
        heading={heading}
        helpText={helpText}
        extraHeading={
            <div className='widget-extra-heading'>
                <label>Select Date</label>
                <DatePicker 
                    name="forDate"
                    dateFormat="dd/MM/yyyy"
                    selected={forDate}
                    onChange={handleChangeForDate}
                    showMonthDropdown
                    showYearDropdown 
                    dropdownMode="select"
                    todayButton="Today"
                    customInput={React.createElement(DatePickerCustomInput)}
                    />
            </div>
        }
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
        >
        <>
            { loadingStockSoiledGraphData && <LoadingComponent message='Loading data... please wait!' />} 
            { menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'soil_in_chrt_det':
                        stack.push(<StockSoilChart key={item.action} title={item.name} />);
                        break;
                    case 'soil_in_tab':
                        stack.push(<StockSoilTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                        break;
                }
                return stack;
            })}
        </>
    </Widget>
  )
}

export default observer(StockSoilWidget);