import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores/store';
import { StockReconcilationEntity } from '../../../models/StockReconcilation';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
import CollapsableContainer from '../../common/CollapsableContainer';
import CleanLinenComparisionGrid from './CleanLinenComparisionGrid';

const CleanLinenComparisionTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {commonStore:{isDesktop,isMobile},hotelPortalStore:{cleanLinenComparisionData,loadingCleanLinenComparisionData}} = useStore();
    const [evenStock, setEvenStock] = useState<StockReconcilationEntity[]>([]);
    const [oddStock, setOddStock]  = useState<StockReconcilationEntity[]>([]);
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();

    useEffect(() => {
        if(cleanLinenComparisionData){
            var arr = [...cleanLinenComparisionData.tableData];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenStock(even);
            setOddStock(odd);
            setExportProps({
              data: cleanLinenComparisionData.tableData,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Stock Delivered', key: 'stockDelivered'},
                  {label: 'Scanned @Hotel', key: 'stockScanned'},
                  {label: '% Scanned @Hotel', key: 'scannedPercent'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[cleanLinenComparisionData])
    
  return (
    <>
    {!loadingCleanLinenComparisionData && cleanLinenComparisionData && cleanLinenComparisionData.tableData.length > 0 && evenStock && oddStock && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
        {cleanLinenComparisionData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <CleanLinenComparisionGrid stocks={evenStock} />
                <CleanLinenComparisionGrid stocks={oddStock} />
            </div>
        )}
        {cleanLinenComparisionData && isMobile && (
            <CleanLinenComparisionGrid stocks={cleanLinenComparisionData.tableData} />
        )}        
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(CleanLinenComparisionTable);