import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import { StockToReplaceTableRow } from '../../../models/StockToReplaceDto';
import CollapsableContainer from '../../common/CollapsableContainer';
import StockToReplaceGrid from './StockToReplaceGrid';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
interface Props extends TableWidgetProps{
  includeNotSeen: boolean;
}
const StockToReplaceTable = ({title,collapsable,helpText,includeNotSeen}:Props) => {
 const {commonStore:{isDesktop,isMobile},hotelPortalStore:{stockToReplaceData,loadingStockToReplace}} = useStore();
  const [evenSoil, setEvenSoil] = useState<StockToReplaceTableRow[]>([]);
  const [oddSoil, setOddSoil]  = useState<StockToReplaceTableRow[]>([]);
  const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();
  useEffect(() => {
        if(stockToReplaceData){
            var arr = [...stockToReplaceData.tableData];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenSoil(even);
            setOddSoil(odd);
            if(includeNotSeen){
              setExportProps({
                data: stockToReplaceData.tableData,
                headers: [
                    {label: 'Item Name', key: 'itemName'},
                    {label: 'Wear-Tear', key: 'wearTear'},
                    {label: 'Misused', key: 'misused'},
                    {label: 'Last Seen > days', key: 'greaterThan90'},
                  ],
                filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
              });
            } else {
              setExportProps({
                data: stockToReplaceData.tableData,
                headers: [
                    {label: 'Item Name', key: 'itemName'},
                    {label: 'Wear-Tear', key: 'wearTear'},
                    {label: 'Misused', key: 'misused'},
                  ],
                filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
              });
            }
        }
    },[stockToReplaceData])
  return (
    <>
      {!loadingStockToReplace && stockToReplaceData && stockToReplaceData.tableData.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
          {stockToReplaceData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <StockToReplaceGrid details={evenSoil} includeNotSeen={includeNotSeen} />
                <StockToReplaceGrid details={oddSoil} includeNotSeen={includeNotSeen} />
            </div>
        )}
        {stockToReplaceData && isMobile && (
            <StockToReplaceGrid details={stockToReplaceData.tableData} includeNotSeen={includeNotSeen} />
        )}   
        </CollapsableContainer>
      )}
    </>
  )
}

export default observer(StockToReplaceTable)