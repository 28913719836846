import React, { useEffect } from 'react'
import { WidgetProps } from '../../../models/WidgetProps'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores/store'
import Widget from '../../common/Widget'
import LoadingComponent from '../../common/LoadingComponent'
import WearTearReplacedChart from './WearTearReplacedChart'
import WearTearReplacedTable from './WearTearReplacedTable'
import WidgetHeaderItems from '../../common/WidgetHeaderItems'

const WearTearReplacedWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{loadWearTearReplaced,loadingWearTearReplaced,wearTearReplacedData},userStore:{currentShop}} = useStore()
    useEffect(()=>{
        if(currentShop)
        if(!wearTearReplacedData)
            loadWearTearReplaced();
    },[wearTearReplacedData,loadWearTearReplaced,currentShop])

  return (
    <Widget 
        width={width} 
        noBorder={false} 
        heading={heading} 
        helpText={helpText}
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
    >
        <>
            {loadingWearTearReplaced && <LoadingComponent message='Loading data... please wait!' />} 
            {menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'weartear_rep_chrt':
                        stack.push(<WearTearReplacedChart key={item.action} title={item.name} />)
                        break;
                    case 'weartear_rep_tab':
                        stack.push(<WearTearReplacedTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />)
                        break;
                }
                return stack;
            })}
        </>
    </Widget>
  )
}

export default observer(WearTearReplacedWidget);