import { ResetToken } from "../models/User";

export function expandResetToken(token: string):ResetToken{
    
    return {
        uniqueRecId: token.substr(0,31).trimEnd(),
        laundryUID: token.substr(31,31).trimEnd(),
        expiryDate: `${token.substr(62,4)}/${token.substr(66, 2).trimEnd()}/${token.substr(68, 2).trimEnd()} ${token.substr(70, 2).trimEnd()}:${token.substr(72, 2).trimEnd()}:${token.substr(74, 2).trimEnd()}`
    };
   }

export function percent(data:number[],value: number){
  let total = 0;
  data.forEach(d => total+=d);
  return ((value/total)*100).toFixed(1);
}