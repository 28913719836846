import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import CollapsableContainer from '../../common/CollapsableContainer';
import StockReplacedGrid from './StockReplacedGrid';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
import { StockToReplaceTableRow } from '../../../models/StockToReplaceDto';

const StockReplacedTable = ({title,collapsable,helpText}:TableWidgetProps) => {
  const {commonStore:{isDesktop,isMobile},hotelPortalStore:{stockReplacedData,loadingStockReplaced}} = useStore();
  const [evenSoil, setEvenSoil] = useState<StockToReplaceTableRow[]>([]);
  const [oddSoil, setOddSoil]  = useState<StockToReplaceTableRow[]>([]);
  const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();
  useEffect(() => {
        if(stockReplacedData){
            var arr = [...stockReplacedData.tableData];
            var odd = [];
            var even = []
            for (var i = 0; i < arr.length; i += 2) {
                even.push(arr[i]);
                arr[i+1] && odd.push(arr[i + 1]);
            }
            setEvenSoil(even);
            setOddSoil(odd);
            setExportProps({
              data: stockReplacedData.tableData,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Wear-Tear', key: 'wearTear'},
                  {label: 'Misused', key: 'misused'},
                  {label: 'Lost', key: 'greaterThan90'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[stockReplacedData])
  return (
    <>
      {!loadingStockReplaced && stockReplacedData && stockReplacedData.tableData.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
          {stockReplacedData &&  isDesktop && (
            <div className='grid-two-col-wrapper'>
                <StockReplacedGrid details={evenSoil} />
                <StockReplacedGrid details={oddSoil}  />
            </div>
        )}
        {stockReplacedData && isMobile && (
            <StockReplacedGrid details={stockReplacedData.tableData} />
        )}   
        </CollapsableContainer>
      )}
    </>
  )
}

export default observer(StockReplacedTable)