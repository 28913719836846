import React from 'react'
import { useStore } from '../../stores/store';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
interface IProps{
    manual: string;
    selectedManual: (manual: string) => void;
}
const LaundryManualList : React.FC<IProps> = ({manual,selectedManual}) => {
    const {commonStore:{docManuals,docLoaded}} = useStore()
  return (
    <div className='pdf-list'>
      <ul className='list-box'>
        {docLoaded ? (
            docManuals.map((doc,index) => (
                <li key={index} className={manual === doc.filename ? 'selected' : ''}>
                    <NavLink to={''} 
                        onClick={ (event) => {event.preventDefault(); selectedManual(doc.filename);}}>{doc.title}
                    </NavLink>
                </li>
            ))            
        ) : (
            <li>Loading...</li>    
        )} 
        </ul>
    </div>
    )
}

export default observer(LaundryManualList);