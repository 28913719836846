import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store'
import { ChartData } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { observer } from 'mobx-react-lite';
import { pieOptions, stackedBarOptions } from '../../../constants/ChartConstants';
import { Colors } from '../../../constants/Colors';

const StockLocationChart = ({title}:{title: string}) => {
    const {hotelPortalStore:{stockLocation,loadingStockLocation}} = useStore()
    const [pieData, setPieData] = useState<ChartData<"pie">>();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(stockLocation){
            setPieData({
                labels: ['Hotel','Laundry'],
                datasets: [
                    {
                        label: '% at Location',
                        data: [stockLocation.summary.hotel,stockLocation.summary.laundry],
                        backgroundColor: [
                            `${Colors.PALE_GREEN}`,
                            `${Colors.PALE_BLUE}`,
                        ],
                        borderColor: [
                            `${Colors.PALE_GREEN}`,
                            `${Colors.PALE_BLUE}`,
                        ],
                        borderWidth: 1
                    }
                ]
            })
            setBarData({
                labels: stockLocation.details.labels,
                datasets:[
                    {
                        label: 'in Hotel',
                        data: stockLocation.details.hotel,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                        label: 'in Laundry',
                        data: stockLocation.details.laundry,
                        backgroundColor: `${Colors.PALE_BLUE}`
                    },
                ]

            })
        }
    },[stockLocation])
    return (
        <>
            {!loadingStockLocation && !!stockLocation && stockLocation.tableData.length > 0 && !!barData && !!pieData && title.length > 0 && (
                <h2 className='collapsable-container-title'>{title}</h2>)}        
            {!loadingStockLocation && !!stockLocation && stockLocation.tableData.length > 0 && !!pieData && !!barData && (
            <div className='graph-container'>
            <div className="width-75">
                <Bar options={stackedBarOptions} data={barData!} />
            </div>
            <div className="width-25 ml-1">
                <Pie options={pieOptions} data={pieData!} />
            </div>
            </div>)}
        </>
    )
}

export default observer(StockLocationChart)