import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { WidgetProps } from '../../../models/WidgetProps';
import { useStore } from '../../../stores/store';
import Widget from '../../common/Widget';
import LoadingComponent from '../../common/LoadingComponent';
import StockToReplaceChart from './StockToReplaceChart';
import StockToReplaceTable from './StockToReplaceTable';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';

const StockToReplaceWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
  const {hotelPortalStore:{stockToReplaceData,loadStockToReplace,loadingStockToReplace},userStore:{currentShop}} = useStore();
  const [includeNotSeen,setIncludeNotSeen] = useState(false);
  useEffect(()=>{
    if(currentShop)
    if(!stockToReplaceData)
      loadStockToReplace(includeNotSeen)
  },[stockToReplaceData,loadStockToReplace,includeNotSeen, currentShop])
  
  const handleOnIncludeNotSeen = (event: React.ChangeEvent<HTMLInputElement> ) => {
    const checked = event.target.checked;
    if(includeNotSeen!==checked){
      setIncludeNotSeen(checked);
      loadStockToReplace(checked); 
    }
  }
  return (
     <Widget 
        width={width} 
        noBorder={false} 
        heading={heading}
        helpText={helpText}
        extraHeading={
            <div className='widget-extra-heading'>
                <input type='checkbox' defaultChecked={includeNotSeen} onChange={handleOnIncludeNotSeen} />
                <label className='pr-25'>Include Stock not seen for more than 90 days</label>
            </div>
        }
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
        >
        <>
            { loadingStockToReplace && <LoadingComponent message='Loading data... please wait!' />} 
            { menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'ownstk_to_rep_chrt':
                        stack.push(<StockToReplaceChart key={item.action} title={item.name} inlcudeNotSeen={includeNotSeen} />);
                        break;
                    case 'ownstk_to_rep_tab':
                        stack.push(<StockToReplaceTable key={item.action} title={item.name} collapsable={item.isDrilldown} includeNotSeen={includeNotSeen} helpText={item.helpText} />);
                        break;
                }
                return stack;
            })}
        </>
    </Widget>
  )
}

export default observer(StockToReplaceWidget);