import React, { useEffect, useState } from 'react'
import { WidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import Widget from '../../common/Widget';
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from '../../common/DatePickerCustomInput';
import { observer } from 'mobx-react-lite'
import LoadingComponent from '../../common/LoadingComponent';
import RejectStockAnalysisChart from './RejectStockAnalysisChart';
import RejectStockAnalysisTable from './RejectStockAnalysisTable';
import { addYears } from 'date-fns';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';

const RejectStockAnalysisWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{loadRejectStockAnalysis,loadingRejectStockData,rejectStockData},userStore:{currentShop}} = useStore()
    const [fromDate, setFromDate] = useState<Date>(addYears(new Date(),-1));
    const [toDate, setToDate] = useState<Date>(new Date());

    useEffect(()=>{
        if(currentShop)
        if(!rejectStockData)
            loadRejectStockAnalysis(fromDate,toDate);
    },[rejectStockData,loadRejectStockAnalysis,fromDate,toDate, currentShop]);
    
    const handleChangeFromDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setFromDate(new Date(date));
        }
    }
    const handleChangeToDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setToDate(new Date(date));
        }
    }



  return (
    <Widget 
        width={width}
        noBorder={false}
        heading={heading}
        helpText={helpText}
        extraHeading={
                    <div className='widget-extra-heading'>
                        <label>Select Date Range</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={fromDate}
                            onChange={handleChangeFromDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={toDate}
                            onChange={handleChangeToDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <button type='button' className='btn btn-small btn-default' onClick={()=>loadRejectStockAnalysis(fromDate,toDate)}>Apply</button>
                    </div>
        }
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
        >
          {loadingRejectStockData ? <LoadingComponent message='Loading stock... please wait' /> : (
            <>
              {menuItems.map((item) => {
                      let stack = [];
                      switch(item.action)
                      {
                          case 'reject_chrt':
                              stack.push(<RejectStockAnalysisChart key={item.action} title={item.name} />);
                              break;
                          case 'reject_tab':
                              stack.push(<RejectStockAnalysisTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                              break;
                      }
                      return stack;
                  })}
            </>
          )}
    </Widget>)
}

export default observer(RejectStockAnalysisWidget);