import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DeliveryOrder } from '../../../models/DeliveryOrder';
import { observer } from 'mobx-react-lite';
import { barOptions } from '../../../constants/ChartConstants';
import {Colors} from '../../../constants/Colors';

const NextDeliveryChart = ({title}:{title: string}) => {
    const {hotelPortalStore:{ nextDeliveries, loadingNextDeliveries}} = useStore();
    const [barData, setBarData] = useState<ChartData<"bar">>();
     useEffect(()=>{
        if(nextDeliveries?.orders){
            setBarData({
                labels: Array.from(new Set(nextDeliveries.orders.map((s: DeliveryOrder) => s.name))),
                datasets:[
                    {
                        label: 'Items to deliver',
                        data: Array.from(new Set(nextDeliveries.orders.map((s: DeliveryOrder) => s.quantity))),
                        backgroundColor: `${Colors.PALE_AMBER}`
                    },
                ]

            })
        }
    },[nextDeliveries?.orders])
  return (
    <>
        {!loadingNextDeliveries && !!nextDeliveries && nextDeliveries?.orders?.length > 0 && !!barData && title.length > 0 && (
          <h2 className='collapsable-container-title'>{title}</h2>)}
        {!loadingNextDeliveries && !!nextDeliveries && nextDeliveries?.orders?.length > 0 && !!barData &&(<Bar options={barOptions} data={barData!} />)}
    </>
  )
}

export default observer(NextDeliveryChart)