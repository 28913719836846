import React, { useEffect, useState } from 'react'
import { WidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store'
import { addMonths } from 'date-fns'
import Widget from '../../common/Widget'
import LoadingComponent from '../../common/LoadingComponent'
import MisusedStockChart from './MisusedStockChart'
import MisusedStockTable from './MisusedStockTable'
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from '../../common/DatePickerCustomInput';
import { observer } from 'mobx-react-lite'
import MisusedTypeAnalysis from './MisusedTypeAnalysis'
import MisusedStockTrendsChart from './MisusedStockTrendsChart'
import WidgetHeaderItems from '../../common/WidgetHeaderItems'

const MisusedStockManagementWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
  const {hotelPortalStore:{loadMisusedStockData,loadingMisusedStockData,misusedStockData},userStore:{currentShop}} = useStore()
    const [fromDate, setFromDate] = useState<Date>(addMonths(new Date(),-1));
    const [toDate, setToDate] = useState<Date>(new Date());
    useEffect(()=>{
        if(currentShop)
        if(!misusedStockData)
            loadMisusedStockData(fromDate,toDate);
    },[misusedStockData,loadMisusedStockData,fromDate,toDate,currentShop])

    const handleChangeFromDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setFromDate(new Date(date));
        }
    }
    const handleChangeToDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setToDate(new Date(date));
        }
    }
    return (
        <Widget 
        width={width}
        noBorder={false}
        heading={heading}
        helpText={helpText}
        extraHeading={
                    <div className='widget-extra-heading'>
                        <label>Select Date Range</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={fromDate}
                            onChange={handleChangeFromDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={toDate}
                            onChange={handleChangeToDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <button type='button' className='btn btn-small btn-default' onClick={()=>loadMisusedStockData(fromDate,toDate)}>Apply</button>
                    </div>
        }
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
        >
          {loadingMisusedStockData ? <LoadingComponent message='Loading stock... please wait' /> : (
            <>
              {menuItems.map((item) => {
                      let stack = [];
                      switch(item.action)
                      {
                          case 'misused_chrt':
                              stack.push(<MisusedStockChart key={item.action} title={item.name} />);
                              break;
                          case 'misused_tab':
                              stack.push(<MisusedStockTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                              break;
                          case 'misused_type_tab':
                              stack.push(<MisusedTypeAnalysis key={item.action} title={item.name} collapsable={item.isDrilldown} fromDate={fromDate} toDate={toDate} helpText={item.helpText} />);
                              break;
                          case 'misused_trends_chrt':
                                stack.push(<MisusedStockTrendsChart key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />)
                      }
                      return stack;
                  })}
            </>
          )}
    </Widget>)
}
export default observer(MisusedStockManagementWidget)