import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../stores/store";
import CustomerStockRow from "./CustomerStockRow";
export default observer(function CustomerStockReport(){
    const {hotelPortalStore: { stockSummaryReport}} = useStore();
    return(
        <div className='data-grid'>
            <div className='data-grid-row header-row'>
                <div className='data-grid-col-sm'>Stock Code</div>
                <div className='data-grid-col-lg'>Stock Name</div>
                {/* <div className='data-grid-col-md'>Stock in Circulation</div> */}
                <div className='data-grid-col-md'>Stock with Customer</div>
                <div className='data-grid-col-sm'>Details</div>
            </div>
            {
                stockSummaryReport && stockSummaryReport.map((s,i) => (
                    <CustomerStockRow key={i} row={s} />
                ))
            }
        </div>
    )
})