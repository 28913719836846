import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { StockReportSummary } from "../../../models/StockReportSummary";
import StockDetailsReport from "./StockDetailsReport";

interface Props{
    qtyLabel: string;
    dataRow: StockReportSummary;
}
export default observer(function StockSummaryRow({qtyLabel, dataRow}:Props){
    const [showDetails,setShowDetails] = useState(false);
    return (
        <>
        <div className='data-grid-row'>
            <div className='data-grid-col-code'><span>{dataRow.stockCode}</span></div>
            <div className='data-grid-col-name'><span>{dataRow.stockName}</span></div>
            <div className='data-grid-col-qty'><span>{dataRow.stockQty}</span></div>
            <div className='data-grid-col-code'><span className='data-toggle' onClick={() => setShowDetails(!showDetails)} >Details</span></div>
        </div>
        {showDetails && (<div className="data-grid-drilldown"><StockDetailsReport qtyLabel={qtyLabel} dataTable={dataRow.details} /></div>)}
        </>
    );
})
