import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store'
import CollapsableContainer from '../../common/CollapsableContainer';
import { TableWidgetProps } from '../../../models/WidgetProps';
import MisusedStockGrid from './MisusedStockGrid';
import { observer } from 'mobx-react-lite';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';

const MisusedStockTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {hotelPortalStore:{misusedStockData}} = useStore();
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();  
    useEffect(()=>{
      if(misusedStockData)
        setExportProps({
              data: misusedStockData.data,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Usage', key: 'workIn'},
                  {label: 'Stain', key: 'stained'},
                  {label: 'Damage', key: 'damage'},
                  {label: 'Special Clean', key: 'specialClean'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
    },[misusedStockData])
  return (
      <>
    {misusedStockData && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
        {misusedStockData && misusedStockData?.data?.length > 0 && (
            <MisusedStockGrid stock={misusedStockData.data} />
        )}        
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(MisusedStockTable)