import React, { useEffect, useState } from 'react'
import { TableWidgetProps } from '../../../models/WidgetProps'
import { useStore } from '../../../stores/store';
import { StockCirculationNotSeenTableItem } from '../../../models/StockCirculationData';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { format } from 'date-fns';
import CollapsableContainer from '../../common/CollapsableContainer';
import StockCirculationNotSeenGrid from './StockCirculationNotSeenGrid';
import { observer } from 'mobx-react-lite';

const StockCirculationNotSeenTable = ({title,collapsable,helpText}:TableWidgetProps) => {
    const {hotelPortalStore:{stockCirculationNotSeenData,loadingStockCirculationNotSeenData}} = useStore();
    const [exportProps, setExportProps] = useState<CommonPropTypes|undefined>();
  
    useEffect(() => {
        if(stockCirculationNotSeenData){
            setExportProps({
              data: stockCirculationNotSeenData.data,
              headers: [
                  {label: 'Item Name', key: 'itemName'},
                  {label: 'Last Seen between 91 and 120 days', key: 'b91N120'},
                  {label: 'Last Seen between 121 and 150 days', key: 'b121N150'},
                  {label: 'Last Seen between 151 and 180 days', key: 'b151N180'},
                  {label: 'Last Seen between 181 and 210 days', key: 'b181N210'},
                  {label: 'Last Seen between 211 and 240 days', key: 'b211N240'},
                  {label: 'Last Seen between 241 and 270 days', key: 'b241N270'},
                  {label: 'Last Seen between 271 and 300 days', key: 'b271N300'},
                  {label: 'Last Seen between 301 and 330 days', key: 'b301N330'},
                  {label: 'Last Seen between 331 and 360 days', key: 'b331N360'},
                  {label: 'Last Seen > 360 days', key: 'g360'},
                ],
              filename: `${title}_${format(new Date(),"ddMMyyyyHHmmss")}.csv`
            });
        }
    },[stockCirculationNotSeenData])
  
    return (
    <>
    {!loadingStockCirculationNotSeenData && stockCirculationNotSeenData && stockCirculationNotSeenData.data.length > 0 && (
        <CollapsableContainer title={title} collapsable={collapsable} showExport={true} exportOptions={exportProps} helpText={helpText}>
        {stockCirculationNotSeenData && (
            <StockCirculationNotSeenGrid stock={stockCirculationNotSeenData.data} />
        )}        
        </CollapsableContainer>
    )}
    </>
  )
}

export default observer(StockCirculationNotSeenTable)