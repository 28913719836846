import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { WidgetProps } from '../../../models/WidgetProps';
import { useStore } from '../../../stores/store';
import Widget from '../../common/Widget';
import LoadingComponent from '../../common/LoadingComponent';
import StockReplacedChart from './StockReplacedChart';
import StockReplacedTable from './StockReplacedTable';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';
import  DatePicker from 'react-datepicker';
import DatePickerCustomInput from '../../common/DatePickerCustomInput';

const StockReplacedWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
  const {hotelPortalStore:{stockReplacedData,loadStockReplaced,loadingStockReplaced},userStore:{currentShop}} = useStore();
  const [fromDate, setFromDate] = useState<Date>(new Date(2000,0,1));
  const [toDate, setToDate] = useState<Date>(new Date());

  useEffect(()=>{
    if(currentShop)
    if(!stockReplacedData)
      loadStockReplaced(fromDate,toDate);
  },[stockReplacedData,loadStockReplaced,fromDate,toDate,currentShop])

  const handleChangeFromDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
        if(date){
            setFromDate(new Date(date));
        }
    }
  const handleChangeToDate = (date: Date | null, event: React.SyntheticEvent<any,Event>) => {
      if(date){
          setToDate(new Date(date));
      }
  }

  return (
     <Widget 
        width={width} 
        noBorder={false} 
        heading={heading}
        helpText={helpText}
        headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
        extraHeading={
                    <div className='widget-extra-heading'>
                        <label>Select Date Range</label>
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={fromDate}
                            onChange={handleChangeFromDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <DatePicker 
                            name="forDate"
                            dateFormat="dd/MM/yyyy"
                            selected={toDate}
                            onChange={handleChangeToDate}
                            showMonthDropdown
                            showYearDropdown 
                            dropdownMode="select"
                            todayButton="Today"
                            customInput={React.createElement(DatePickerCustomInput)}
                            />
                        <button type='button' className='btn btn-small btn-default' onClick={()=>loadStockReplaced(fromDate,toDate)}>Apply</button>
                    </div>
        }
        >
        <>
            { loadingStockReplaced && <LoadingComponent message='Loading data... please wait!' />} 
            { menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'repdstock_chrt':
                        stack.push(<StockReplacedChart key={item.action} title={item.name} />);
                        break;
                    case 'repdstock_tab':
                        stack.push(<StockReplacedTable key={item.action} title={item.name} collapsable={item.isDrilldown} helpText={item.helpText} />);
                        break;
                }
                return stack;
            })}
        </>
    </Widget>
  )
}

export default observer(StockReplacedWidget);