import React from 'react'
interface Props {
  title: string;
  message: string;
}
const Message = ({title,message}:Props) => {
  return (
    <div className="message-box">
        <div className="message-box-title">
          <h2 data-alter-title>{title}</h2>
        </div>
        <div className="message-box-body">
          <p>{message}</p>
        </div>
    </div>
  )
}

export default Message