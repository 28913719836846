import { observer } from "mobx-react-lite";
import React from "react";
import { StockAllocationDetail } from "../../../models/StockReportSummary";
import StockAllocationDetailRow from "./StockAllocationDetailRow";

interface Props{
    dataTable: StockAllocationDetail[];
}
export default observer(function StockAllocationDetailsReport({dataTable}:Props){
    return(
        <div className='data-grid'>
            <div className='data-grid-row header-row'>
                <div className='data-grid-col-code'><span>Stock Code</span></div>
                <div className='data-grid-col-name'><span>Stock Name</span></div>
                <div className='data-grid-col-code'><span>RFID Code</span></div>
                <div className='data-grid-col-code'><span>Date/Time</span></div>
            </div>
            { dataTable && dataTable.map(row => (
                    <StockAllocationDetailRow dataRow={row} />
                ))
            }
        </div>
    )
});