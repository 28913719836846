import { RejectStockAnalysisEntity } from '../../../models/RejectStockAnalysisData';
import { observer } from 'mobx-react-lite';
interface Props{
    details: RejectStockAnalysisEntity[];
    totals?: RejectStockAnalysisEntity;
}
const RejectStockAnalysisGrid = ({details,totals}:Props) => {
 return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row item-header-with-3-col heading-row">
              <div>Item Name</div>
              <div className='text-right pr-25'>Usage</div>
              <div className='text-right pr-25'>Rejected</div>
              <div className='text-right pr-25'>Rejected %</div>
          </div>
          {details.map((detail) => (
            <div key={detail.itemCode} className='data-grid-two-col-row item-header-with-3-col'>
              <div>{detail.itemName}</div>
              <div className='text-right pr-25'>{detail.usageQty}</div>
              <div className='text-right pr-25'>{detail.rejectedQty}</div>
              <div className='text-right pr-25'>{detail.usageQty > 0 ? ((detail.rejectedQty/detail.usageQty)*100.0).toFixed(1) : 0 }</div>
            </div>
          ))}
          {totals && (
            <div key='totals' className='data-grid-two-col-row item-header-with-3-col'>
              <div><b>{totals.itemName}</b></div>
              <div className='text-right pr-25'><b>{totals.usageQty}</b></div>
              <div className='text-right pr-25'><b>{totals.rejectedQty}</b></div>
              <div className='text-right pr-25'><b>{totals.usageQty > 0 ? ((totals.rejectedQty/totals.usageQty)*100.0).toFixed(1) : 0 }</b></div>
            </div>
          )}
    </div>
  )
}

export default observer(RejectStockAnalysisGrid);