import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { MisusedStockTableItem } from '../../../models/MisusedStock';

interface Props{
    stock: MisusedStockTableItem[];
}
const MisusedStockGrid = ({stock}:Props) => {
    const [totalUsage,setTotalUsage] = useState(0);
    const [totalStain, setTotalStain] = useState(0);
    const [totalDamage, setTotalDamage] = useState(0);
    const [totalSplClean, setTotalSplClean] = useState(0);
    useEffect(()=>{
        stock.forEach(data =>{
            setTotalUsage(prev => prev + data.workIn);
            setTotalStain(prev => prev + data.stained);
            setTotalDamage(prev => prev + data.damage);
            setTotalSplClean(prev => prev + data.specialClean);
        })
        // stock.forEach(data => total+=(data.workIn > 0 ? Math.round(((data.stained+data.damage+data.specialClean)/data.workIn)*100.0*1e1)/1e1 : 0));
    },[stock])
  return (
    <div className="data-grid-two-col">
        <div className="data-grid-two-col-row misused-stock-grid-row heading-row">
            <div>Item Name</div>
            <div className='text-right pr-25'>Usage</div>
            <div className='text-right pr-25'>Stain</div>
            <div className='text-right pr-25'>Stain %</div>
            <div className='text-right pr-25'>Damage</div>
            <div className='text-right pr-25'>Damage %</div>
            <div className='text-right pr-25'>Spl. Clean</div>
            <div className='text-right pr-25'>Spl. Clean %</div>
            <div className='text-right pr-25'>Total %</div>
        </div>
        {stock.map(data =>(
            data.stained+data.damage+data.specialClean > 0 &&
            <div key={data.itemCode} className="data-grid-two-col-row misused-stock-grid-row">
                <div>{data.itemName}</div>
                <div className='bg-pale-green text-right pr-25'>{data.workIn}</div>
                <div className='bg-pale-amber text-right pr-25'>{data.stained}</div>
                <div className='bg-pale-amber text-right pr-25'>{data.workIn > 0 ? ((data.stained/data.workIn)*100.0).toFixed(1) : 0}</div>
                <div className='bg-pale-orange text-right pr-25'>{data.damage}</div>
                <div className='bg-pale-orange text-right pr-25'>{data.workIn > 0 ? ((data.damage/data.workIn)*100.0).toFixed(1) : 0}</div>
                <div className='bg-pale-red text-right pr-25'>{data.specialClean}</div>
                <div className='bg-pale-red text-right pr-25'>{data.workIn > 0 ? ((data.specialClean/data.workIn)*100.0).toFixed(1) : 0}</div>
                <div className=' text-right pr-25'>{data.workIn > 0 ? (((data.stained+data.damage+data.specialClean)/data.workIn)*100.0).toFixed(1) : 0}</div>
            </div>
        ))}
            <div className="data-grid-two-col-row misused-stock-grid-row">
                <div><b>TOTAL</b></div>
                <div className='text-right pr-25'>{totalUsage}</div>
                <div className='text-right pr-25'>{totalStain}</div>
                <div className='text-right pr-25'>{((totalStain/totalUsage)*100.0).toFixed(1)}</div>
                <div className='text-right pr-25'>{totalDamage}</div>
                <div className='text-right pr-25'>{((totalDamage/totalUsage)*100.0).toFixed(1)}</div>
                <div className='text-right pr-25'>{totalSplClean}</div>
                <div className='text-right pr-25'>{((totalSplClean/totalUsage)*100.0).toFixed(1)}</div>
                <div className='text-right pr-25'>{(((totalStain+totalDamage+totalSplClean)/totalUsage)*100.0).toFixed(1)}</div>
            </div>

    </div>)
}

export default observer(MisusedStockGrid);