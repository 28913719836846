import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import Widget from '../../common/Widget';
import { useStore } from '../../../stores/store';
import LoadingComponent from '../../common/LoadingComponent';
import { format } from 'date-fns';
import { WidgetProps } from '../../../models/WidgetProps';
import NextDeliveryChart from './NextDeliveryChart';
import NextDeliveryTable from './NextDeliveryTable';
import WidgetHeaderItems from '../../common/WidgetHeaderItems';
const NextDeliveryWidget = ({width,heading,menuItems,helpText,moduleHeader}:WidgetProps) => {
    const {hotelPortalStore:{ nextDeliveries, loadNextDeliveries, loadingNextDeliveries},userStore:{currentShop}} = useStore();
    useEffect(()=>{
      if(currentShop)
        if(!nextDeliveries)
          loadNextDeliveries();
    },[nextDeliveries, loadNextDeliveries, currentShop])
  return (
    <Widget 
    width={width} 
    noBorder={false} 
    heading={`${nextDeliveries?.deliveryTitle && nextDeliveries.deliveryTitle} ${heading}`}
    helpText={helpText}
    extraHeading={
    <div className='widget-extra-heading'>
        <span>Delivery No: {(nextDeliveries && nextDeliveries?.orders?.length > 0) ? nextDeliveries.deliveryNo > 0 ? nextDeliveries.deliveryNo : 'N/A' : 'N/A'}</span>
        <span>Delivery Date: {(nextDeliveries && nextDeliveries?.orders?.length > 0) ? format(new Date(nextDeliveries?.deliveryDate),"dd/MM/yyyy") : ('N/A')}</span>
    </div>}
    headerItems={!!moduleHeader && <WidgetHeaderItems headerItems={moduleHeader} />}
    >
            { loadingNextDeliveries && (<LoadingComponent message='Loading data... please wait!' />)}
            { menuItems.map((item) => {
                let stack = [];
                switch(item.action)
                {
                    case 'next_delivery_chrt':
                        stack.push(<NextDeliveryChart key={item.action} title={`${nextDeliveries?.deliveryTitle} ${item.name}`} />);
                        break;
                    case 'next_delivery_tab':
                        stack.push(<NextDeliveryTable key={item.action} title={`${nextDeliveries?.deliveryTitle} ${item.name}`} collapsable={item.isDrilldown} helpText={item.helpText} />);
                        break;
                }
                return stack;
            })}
    </Widget>
  )
}

export default observer(NextDeliveryWidget);