import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { stackedBarOptions } from '../../../constants/ChartConstants';
import { Colors } from '../../../constants/Colors';
import { Bar } from 'react-chartjs-2';
interface Props{
  title:string
  inlcudeNotSeen: boolean;
}
const StockToReplaceChart = ({title,inlcudeNotSeen}:Props) => {
  const {hotelPortalStore:{stockToReplaceData, loadingStockToReplace}} = useStore();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(stockToReplaceData)
        {
          if(inlcudeNotSeen)
            setBarData({
                labels: stockToReplaceData.chartData.labels,
                datasets:[
                    {
                        label: 'Wear-Tear',
                        data: stockToReplaceData.chartData.wearTear,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                      label: 'Misused',
                      data: stockToReplaceData.chartData.misused,
                      backgroundColor: `${Colors.PALE_AMBER}`
                    },
                    {
                      label: 'Last Seen >90',
                      data: stockToReplaceData.chartData.greaterThan90,
                      backgroundColor: `${Colors.PALE_RED}`
                    }
                ]

            })
          else
            setBarData({
                labels: stockToReplaceData.chartData.labels,
                datasets:[
                    {
                        label: 'Wear-Tear',
                        data: stockToReplaceData.chartData.wearTear,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                      label: 'Misused',
                      data: stockToReplaceData.chartData.misused,
                      backgroundColor: `${Colors.PALE_AMBER}`
                    },
                ]

            })
        }
    },[stockToReplaceData,inlcudeNotSeen])
  return (
        <>
            {!loadingStockToReplace && !!stockToReplaceData && stockToReplaceData.chartData.labels.length > 0 && !!barData && title.length > 0 && (
              <h2 className='collapsable-container-title'>{title}</h2>)}        
            {!loadingStockToReplace && !!stockToReplaceData && stockToReplaceData.chartData.labels.length > 0 && !!barData && (
              <Bar options={stackedBarOptions} data={barData!} />)}
        </>
  )
}

export default observer(StockToReplaceChart)