import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../stores/store';
import { ChartData } from 'chart.js';
import { stackedBarOptions } from '../../../constants/ChartConstants';
import { Colors } from '../../../constants/Colors';
import { Bar } from 'react-chartjs-2';

const StockReplacedChart = ({title}:{title: string}) => {
  const {hotelPortalStore:{stockReplacedData, loadingStockReplaced}} = useStore();
    const [barData, setBarData] = useState<ChartData<"bar">>();
    useEffect(()=>{
        if(stockReplacedData)
        {
            setBarData({
                labels: stockReplacedData.chartData.labels,
                datasets:[
                    {
                        label: 'Wear-Tear',
                        data: stockReplacedData.chartData.wearTear,
                        backgroundColor: `${Colors.PALE_GREEN}`
                    },
                    {
                      label: 'Misused',
                      data: stockReplacedData.chartData.misused,
                      backgroundColor: `${Colors.PALE_AMBER}`
                    },
                    {
                      label: 'Lost',
                      data: stockReplacedData.chartData.greaterThan90,
                      backgroundColor: `${Colors.PALE_RED}`
                    }
                ]

            })
        }
    },[stockReplacedData])
  return (
        <>
            {!loadingStockReplaced && !!stockReplacedData && stockReplacedData.chartData.labels.length > 0 && !!barData && title.length > 0 && (
              <h2 className='collapsable-container-title'>{title}</h2>)}        
            {!loadingStockReplaced && !!stockReplacedData && stockReplacedData.chartData.labels.length > 0 && !!barData && (
                <Bar options={stackedBarOptions} data={barData!} />)}
        </>
  )
}

export default observer(StockReplacedChart)