import { observer } from 'mobx-react-lite'
import React from 'react'
import { StockToReplaceTableRow } from '../../../models/StockToReplaceDto'
interface Props{
  includeNotSeen: boolean,
  details: StockToReplaceTableRow[]
}
const StockToReplaceGrid = ({includeNotSeen,details}:Props) => {
  return (
    <div className="data-grid-two-col">
        <div className={`data-grid-two-col-row ${includeNotSeen ? 'item-header-with-4-col':'item-header-with-3-col'}  heading-row`}>
              <div>Item Name</div>
              <div className='text-right pr-25'>Wear-Tear</div>
              <div className='text-right pr-25'>Misused</div>
              {includeNotSeen && (<div className='text-right pr-25'>Last Seen &gt;90 days</div>)}
              <div className='text-right pr-25'>Totals</div>
          </div>
          {details.map((detail) => (
            <div key={detail.itemName} className={`data-grid-two-col-row ${includeNotSeen ? 'item-header-with-4-col':'item-header-with-3-col'}`}>
              <div>{detail.itemName}</div>
              <div className='text-right pr-25'>{detail.wearTear}</div>
              <div className='text-right pr-25'>{detail.misused}</div>
              {includeNotSeen && (<div className='text-right pr-25'>{detail.greaterThan90}</div>)}
              <div className='text-right pr-25'>{detail.wearTear+detail.misused+(includeNotSeen ? detail.greaterThan90 : 0)}</div>
            </div>
          ))}
    </div>
  )
}

export default observer(StockToReplaceGrid)